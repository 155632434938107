import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube'
import styles from '../../css/styling.module.css';
import styles3 from '../../css/newStyling.module.css'
import { getActivityVideo2, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaLocationArrow } from "react-icons/fa"
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';
const Question_With_LongText2 = (props) => {
    const [comment, setComment] = useState('')
    const [readComment, setReadComment] = useState('')
    // const [longTxt, setLongTxt] = useState(false)
    const handleInputChange = (e) => {
        //console.log('came in field')
        setComment(e.target.value);
    };



    //   const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Handle the submission logic (e.g., send the comment to a server)
    //     console.log('Submitted comment:', comment);
    //     // You can add more logic here, such as sending the data to a server.
    //     // Clear the input field
    //     setComment('');
    //   };




    // // On Value change
    // const handleSubmit = (e) => {
    //     //setLongTxt(true)
    //     e.preventDefault()

    //     let value = comment
    //     setReadComment(comment)
    //     console.log('props in long text 2 ->', props.allContent.content[props.categoryIndex].questions[props.questionIndex])
    //     props.allContent.content[props.categoryIndex].questions[props.questionIndex].answer = value;
    //     props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;
    //     props.setContent({ ...props.allContent });

    //     setComment('')
    // }

    const onChangeText = (e) => {
   
        // props.allContent.pages[props.pageIndex].questions[props.questionIndex].answer = props.textLimit && props.textLimit.max && (!Number.isNaN(parseInt(props.textLimit.max)) ? e.target.value.substring(0, parseInt(props.textLimit.max)) : e.target.value)
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].answer = e.target.value
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;
        // props.allContent.pages[props.pageIndex].questions[props.questionIndex].answer = e.target.value
        props.setContent({ ...props.allContent });
       
//setAnswerValue(value)

    }

    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };
    const params = useParams();
    const userId = params.UserId
    const dispatch = useDispatch();
   
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);


    const catId = props.categoryId
    const questionIndex = props.questionIndex


    useEffect(() => {

        if(props.questionFile && props.questionFile !==''){
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {
                    
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('question video not available') }
                    setIsLoading(false);

                })

           
        }
    }

}, []);


    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }
    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}

                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '30px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }


                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">

                                                {isLoading ? (
                                                    <b>Loading Video...</b>

                                                ) : qVideo.data ? (
                                                    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                        <ReactPlayer
                                                            url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                            controls
                                                            width="100%"
                                                        />
                                                    </div>
                                                ) : (
                                                    <b>Loading Video...</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <div className="mb-3 mt-3">
                                        {/* Image Display */}
                                        {qVideo.data &&

                                            // Image Display
                                            <div>
                                                <div className="position-relative">
                                                    <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                        </>


                    }


                    <div className="mx-3 mt-3 mb-4">

                        {/* Text Limit */}
                        {props.textLimit && !Number.isNaN(parseInt(props.textLimit.max)) && (
                            <small className="text-danger">Max Character Limit: {props.textLimit && props.textLimit.max}</small>
                        )}

                        {/* Text  Area */}
                        <textarea id={props.id} className="form-control" placeholder="Write your answer here" value={props.answer} onChange={onChangeText} />
                        {/* {readComment!='' ? <div>
    <p>{readComment}</p>
</div>:
''}
<form  onSubmit={handleSubmit} className={`${styles3.comment_form}`}>
<input
    type="text"
    id={props.id}
    placeholder="Write an answer"
    value={comment}
    onChange={handleInputChange}
    className={`${styles3.comment_input}`}
/>
<button type="submit" className={`${styles3.submit_button}`}>
    <span><FaLocationArrow size={23} /></span>
</button>
</form> */}


                    </div>

                    {/* Required Answer error */}

                    {/* {props.required && !props.isAnswered && ( */}

                    {((props.required && !props.isAnswered)) && (
                        <div id={`error-msg-${props.id}`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )

}

export default Question_With_LongText2;