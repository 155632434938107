import React from 'react';
import { useState, useEffect } from 'react';
import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from "react-perfect-scrollbar";
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom'
import SecureLS from 'secure-ls';
import { respondentDashboardDetails } from '../../../actions/dashboard_activities';
import medal from '../../../assets/images/medal.png'

function LatestTasks() {

    const ls = new SecureLS()
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [todayTasks, setTodayTasks] = useState({})


    const [projectData, setProjectData] = useState([])


    const userId = ls.get('accessUser').user._id



    useEffect(() => {
        dispatch(respondentDashboardDetails(userId))
            .then(response => {
              
                setTodayTasks(response.payload.content.todaysTasks) 
            })
            .catch(err =>
                console.log('err')
            )

    }, [])


    const joinTask = (id, activityType) => {
       

        switch (activityType) {

            case 'Quick Survey':
                navigate(`quick-survey/${id}`)
                break

            case 'General Forum':
            navigate(`group-forum-detail/${id}`)
                break;
        }
    }
















    const todaysDate = new Date().toLocaleDateString() // for quick survey 

    return (
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <LoadingOverlay tag="div" active={''}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                position: 'fixed',
                                top: '20%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        <React.Fragment>

                            <div className="container-fluid pt-3">








                                <div className="row mt-3">
                                    {/* removed resize detecor layout function here */}
                                    <div className='col-12 pt-3'>
                                        {/* Projects Section */}
                                        <div className={styles.card_border}>

                                            <div className={styles.card_border_header}>
                                                Latest Tasks
                                            </div>

                                            <div className={styles.card_border_body + " pt-2 pb-3 px-0"}>
                                                <div className="scroll-area-lg" style={{ height: 'auto', minHeight:'100px' }}>
                                                    <PerfectScrollbar>
                                                        {/* individual project block */}

                                                        {todayTasks && todayTasks.length > 0 ? todayTasks.map(x => {
                                                            if (x != null) {
                                                                return (
                                                                    <div className={`container py-2`} key={x.activityId}>
                                                                        <div class="row">
                                                                            <div class="">
                                                                                {/* <img className={styles3.project_tag_banner} src='https://www.simplilearn.com/ice9/free_resources_article_thumb/What_Is_a_Project.jpg' alt={x.title}></img> */}
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <p className={`${styles3.project_tag_title} py-3`} onClick={() => joinTask(x.activityId, x.activityType)}>{x.title}</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p className='py-3'>{x.rewardPoints}<span><img src={medal} style={{ width: '20px' }}></img></span></p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <button className={`${styles3.apply_now_button} my-1`}>
                                                                                    <p className={`${styles3.apply_now_text} pt-2`} onClick={() => joinTask(x.activityId, x.activityType)}>Click Here</p>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null; // Skip null values
                                                            }
                                                        }) : <h6 className='container py-5 px-5 text-center' style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Latest Tasks Found</h6>}



                                                    </PerfectScrollbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>










                        </React.Fragment>


                    </LoadingOverlay>

                )}

            />

        </TransitionGroup >

    )

}

export default LatestTasks;