import React, { useState, useRef } from 'react'
import styles from '../../css/styling.module.css';
import { Droppable, Draggable } from "react-beautiful-dnd";
import DndIcon from '../../images/dnd_icon.png'
import PhotoICon from '../../images/photo_icon.png'
import CrossICon from '../../images/close_icon.png'
import ImageModal from '../modal/ImageModal';
import AttachementIcon from '../../images/attachement_icon.png'
import YouTube from 'react-youtube'
import Swal from 'sweetalert'
import  {CKEditor}  from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Label} from 'reactstrap';


const Create_Question_With_Grid = (props) => {
    const question = props.questionText
    const [rowFileName, setRowFileName] = useState([])
    const [colFileName, setColFileName] = useState([])
    const [isModalOpen, setModal] = useState([])
    const [isShowQuestionFileModal, setQuestionFileModal] = useState(false)
    const [typingTimer, setTypingTimer] = useState(null);
     const [youtube, setYoutube] = useState([]);
        const [isChecked, setIsChecked] = useState(false);

    // For changing text in question
    const onQuestionTextChange = (value2) => {
        const value = value2.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>');
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionText = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }

    // For changing texts in a column
    const onColumnTextChange = (value, colIndex) => {
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].columns[colIndex].text = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    

    // For changing texts in a row
    const onRowTextChange = (value, rowIndex) => {
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].rows[rowIndex].text = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }

    // For saving row image
    const onRowImageSelect = (file, rowIndex) => {

        if (file && file[0]) {

            let files = rowFileName
            files.push({
                index: rowIndex,
                name: file[0].name
            })
            setRowFileName(files)

            // Set All Content
            let pages = [...props.allContent.pages]

            pages[props.pageIndex].questions[props.questionIndex].rows[rowIndex].image = file[0];

            props.setContent({
                ...props.allContent,
                pages: [...pages]
            })

        }
    }

    // For saving col image
    const onColImageSelect = (file, colIndex) => {

        if (file && file[0]) {

            let files = colFileName
            files.push({
                index: colIndex,
                name: file[0].name
            })
            setColFileName(files)


            // Set All Content
            let pages = [...props.allContent.pages]

            pages[props.pageIndex].questions[props.questionIndex].columns[colIndex].image = file[0];

            props.setContent({
                ...props.allContent,
                pages: [...pages]
            })


        }

    }

    // For selecting question as required
    const onChangeRequired = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].required = !pages[props.pageIndex].questions[props.questionIndex].required;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For adding additionalComment 
    const onSelectAddComment = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].isComment = !pages[props.pageIndex].questions[props.questionIndex].isComment;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For setting question in random Order
    const onSelectRandomOrder = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].isRandomOrder = !pages[props.pageIndex].questions[props.questionIndex].isRandomOrder;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For selecting column as unique
    const onChangeUniqueCol = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].uniqueSelected = !pages[props.pageIndex].questions[props.questionIndex].uniqueSelected;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

        const rowLength = pages[props.pageIndex].questions[props.questionIndex].rows.length
        const colLength = pages[props.pageIndex].questions[props.questionIndex].columns.length
        const uniqueSelected = pages[props.pageIndex].questions[props.questionIndex].uniqueSelected

        if (rowLength > colLength && uniqueSelected === true) {

            const addRows = rowLength - colLength

            for (let i = 0; i < addRows; i++) {
                addNewColumn()
            }

        }
    }

    // For adding a new col // add point here
    const addNewColumn = () => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].columns.push({
            text: "",
            image: "",
           
        })

        props.setContent({ ...props.allContent, pages });
    }

    // For adding new row
    const addNewRow = () => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].rows.push({
            text: "",
            image: "",
        })

        props.setContent({ ...props.allContent, pages });

        if (pages[props.pageIndex].questions[props.questionIndex].uniqueSelected === true) {
            addNewColumn()
        }
    }

    // For deleting a row
    const delRow = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].rows.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting a col
    const delCol = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].columns.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting a question
    const delQuestion = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting an Image on row
    const removeRowImage = (index) => {
        let files = rowFileName
        files.splice(index, 1)
        setRowFileName(files)

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].rows[index].image = "";

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For deleting an Image on col
    const removeColImage = (index) => {
        let files = colFileName
        files.splice(index, 1)
        setColFileName(files)

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].columns[index].image = "";

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // Show Image inModal
    const showModal = (type, index, value) => {
        setModal([{
            index: index, type: type, value: value
        }])
    }

    // // For changing question image
    const onQuestionImageSelect = (file) => {
        const selectedFile = file[0]; // Assuming only a single file is allowed
        const maxSize = 25 * 1024 * 1024;
        if (selectedFile && selectedFile.size <= maxSize) {

        if (file && file[0]) {

            // File Extension Check
            let fileExtension = file[0].name.split('.').pop();

            if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {

                let pages = [...props.allContent.pages]

                pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                props.setContent({
                    ...props.allContent,
                    pages: [...pages]
                })

            } else if (fileExtension === "mp4") {

                // Set All Content
                let pages = [...props.allContent.pages]

                pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                props.setContent({
                    ...props.allContent,
                    pages: [...pages]
                })
            }

        }

    }else{
        Swal({
            title: "Error",
            text: "Video size must not exceed 25MB",
            icon: 'error'
        })
    } }

    // For deleting an Question File
    const removeQuestionFile = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionFile = "";

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

// For embedding youtube video as a question
const onSelectEmbedVideo = (value) => {
    //console.log('video id->', value)
    let pages = [...props.allContent.pages]

    pages[props.pageIndex].questions[props.questionIndex].questionVideo = value;

    props.setContent({
        ...props.allContent,
        pages: [...pages]
    })
}
const opts = {
    width: '640',
    height: '360',
    playerVars: {
      autoplay: 0
    },
};


const handleEditorChange=(event, editor)=>{
    const newContent = editor.getData();
   // console.log('newContent->', newContent)
    clearTimeout(typingTimer);
    const newTypingTimer = setTimeout(() => {
        onQuestionTextChange(newContent);
      }, 2000);
      setTypingTimer(newTypingTimer)
    
}

const addYoutube = () => {

    setYoutube([...youtube, { id: youtube.length + 1, value: '' }]);

}
const clearYouTube = () => {
    setYoutube([])
}

const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
        addYoutube();
    }
    if (!checked) {
        clearYouTube();
    }
};



    return (
        <div className="my-0">

            <div className={`${styles.card_border} ${styles.question_card}`} style={!props.isValidQuestion ? { borderColor: '#d92550' } : {}}>

                <div className={styles.card_border_body + " pt-4 pb-1"}>


                    <div style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        <div className="d-flex">
                            <label className="mr-auto">Question # {props.questionNo}</label><br />
                            <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" onClick={() => delQuestion(props.questionIndex)} type="button">
                                <img src={CrossICon} alt="" width="15px" />
                            </button>
                            <span {...props.dragHandleProps} style={{ margin: '-1.5rem -3.3rem 0 0' }} className={styles.dnd_question}>
                                <img src={DndIcon} alt="" />
                            </span>
                        </div>

                       {/* Embedded YOutube video */}
                       <div>
                        <input className='mx-4'
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <Label>Add a Youtube Video</Label>
                    </div>
                    {youtube.map((dropDown, index) => (
                        <div key={index}>
                            <input type="text" placeholder="Write YouTube video ID here" value={props.questionVideo} className="form-control"
                                onChange={(e) => onSelectEmbedVideo(e.target.value)}
                            />

                            <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>


                        </div>
                    ))}

                        {/* Question text */}
                        {/* <input type="text" placeholder="Write Question here" value={props.questionText} className="form-control"
                            onChange={(e) => onQuestionTextChange(e.target.value)}
                        /> */}
                          <CKEditor
                    editor={ ClassicEditor }
                    data={question}
                    
                    onChange={handleEditorChange}
                    
                   
                />

                    </div>


                    {/* Grid */}
                    <div className="row mt-4 mx-1">

                        {/* Question Image */}
                        <div className="m-0 p-0 col-md-6 col-sm-12 col-12">
                            <label>Add File</label>
                            <div className={`input-group mb-3 hide-file-text`}>

                                <input type="file" className="py-1 pl-1 form-control" multiple={false} accept={".jpg, .jpeg, .png, .mp4"}
                                    style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                    onChange={(e) => onQuestionImageSelect(e.target.files)}
                                />

                                {
                                    props.questionFile ?
                                        (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ?
                                            <>
                                                <div className="position-relative" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} style={{ cursor: 'pointer' }}>
                                                {<p>Video Selected</p>}
                                                    {/* <video width={200} controls className="img-fluid- border" style={{ width: "40px", height: "40px", cursor: 'pointer' }}>
                                                        <source src={props.questionFile} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video> */}
                                                </div>
                                                <ImageModal
                                                    isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                    image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                    changeImage={onQuestionImageSelect}
                                                    type="video"
                                                    // index={index}
                                                    delImage={removeQuestionFile}
                                                />
                                            </>
                                            :
                                            <>
                                                <img src={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)} alt="" width="40px" height="40px" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} />


                                                <ImageModal
                                                    isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                    image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                    changeImage={onQuestionImageSelect}
                                                    // index={index}
                                                    delImage={removeQuestionFile}
                                                />



                                            </>

                                        :
                                        <label className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                            <img src={AttachementIcon} alt="" width="20px" />
                                        </label>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-0" />

                        <div className="col-md-6 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-12">
                                    <label>Rows</label>
                                </div>

                                <Droppable droppableId={`droppablerow${props.questionIndex}`} type={`row ${props.questionIndex}`}>

                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className="col-12 options"
                                        >

                                            {props.rows.map((row, index) => {
                                                return <Draggable
                                                    key={`${props.questionIndex} row ${index}`}
                                                    draggableId={`${props.questionIndex} row ${index}`}
                                                    index={index}

                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >


                                                            <div className={`input-group input-group-feedback mb-3 ${styles.option_input}`}>

                                                                {/* Text */}

                                                                <input type="text" placeholder="Add row text here" value={row.text} className="form-control"
                                                                    style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                                    onChange={(e) => onRowTextChange(e.target.value, index)}
                                                                />

                                                                {/* Image */}

                                                                <div className="input-group-append">
                                                                    {
                                                                        row.image ?
                                                                            <>
                                                                                <img src={typeof row.image === 'string' ? row.image : URL.createObjectURL(row.image)} alt="" width="40px" height="40px" onClick={() => showModal('row', index, true)} />

                                                                                {
                                                                                    isModalOpen.map(modal => {
                                                                                        if (index === modal.index && modal.type === 'row') {
                                                                                            return <ImageModal
                                                                                                isModalOpen={modal.value} setModal={showModal}
                                                                                                image={typeof row.image === 'string' ? row.image : URL.createObjectURL(row.image)}
                                                                                                changeImage={onRowImageSelect}
                                                                                                index={index}
                                                                                                delImage={removeRowImage}

                                                                                            />
                                                                                        }
                                                                                    })
                                                                                }

                                                                            </>
                                                                            :
                                                                            <>
                                                                                <label htmlFor={`row-file-upload${index}`} className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                                                                    <img src={PhotoICon} alt="" width="20px" />
                                                                                </label>
                                                                                <input type="file" multiple={false} accept={".jpg, .jpeg, .png"} className="form-control" style={{ display: 'none' }} id={`row-file-upload${index}`}
                                                                                    onChange={(e) => onRowImageSelect(e.target.files, index)}
                                                                                />
                                                                            </>
                                                                    }

                                                                </div>

                                                                {/* delete icon */}

                                                                <div className={`input-group-append`}>
                                                                    <button className="btn btn-light" style={{ height: "2.5rem" }} onClick={() => delRow(index)} type="button">
                                                                        <img src={CrossICon} alt="" width="15px" />
                                                                    </button>
                                                                </div>

                                                                {/* drag n drop */}

                                                                <div className={`input-group-append ${styles.dnd_options}`}>
                                                                    <span {...provided.dragHandleProps} >
                                                                        <img src={DndIcon} alt="" width="40px" />
                                                                    </span>
                                                                </div>
                                                            </div>




                                                        </div>
                                                    )}
                                                </Draggable>

                                            })}
                                            <div className="row">
                                                <div className="col-12">
                                                    <button className="btn btn-light rounded-circle float-right" onClick={() => addNewRow()} type="button">+</button>
                                                </div>
                                            </div>



                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-12">
                                    <label>Columns</label>
                                </div>

                                <Droppable droppableId={`droppablecol${props.questionIndex}`} type={`col ${props.questionIndex}`}>

                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className="col-12 options"
                                        >

                                            {props.columns.map((col, index) => {
                                                return <Draggable
                                                    key={`${props.questionIndex} col ${index}`}
                                                    draggableId={`${props.questionIndex} col ${index}`}
                                                    index={index}

                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >


                                                            <div className={`input-group input-group-feedback mb-3 options ${styles.option_input}`}>

                                                                {/* text this one*/}

                                                                <input type="text" placeholder="Add column text here" value={col.text} className="form-control"
                                                                    style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                                    onChange={(e) => onColumnTextChange(e.target.value, index)}
                                                                />
                                                                

                                                                {
                                                                    col.image ?
                                                                        <>
                                                                            <img src={typeof col.image === 'string' ? col.image : URL.createObjectURL(col.image)} alt="" width="40px" height="40px" onClick={() => showModal('col', index, true)} />

                                                                            {
                                                                                isModalOpen.map(modal => {
                                                                                    if (index === modal.index && modal.type === 'col') {
                                                                                        return <ImageModal
                                                                                            isModalOpen={modal.value} setModal={showModal}
                                                                                            image={typeof col.image === 'string' ? col.image : URL.createObjectURL(col.image)}
                                                                                            changeImage={onColImageSelect}
                                                                                            index={index}
                                                                                            delImage={removeColImage}

                                                                                        />
                                                                                    }
                                                                                })
                                                                            }

                                                                        </>
                                                                        :
                                                                        <>
                                                                            <label htmlFor={`col-file-upload${index}`} className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                                                                <img src={PhotoICon} alt="" width="20px" />
                                                                            </label>
                                                                            <input type="file" multiple={false} accept={".jpg, .jpeg, .png"} className="form-control" style={{ display: 'none' }} id={`col-file-upload${index}`}
                                                                                onChange={(e) => onColImageSelect(e.target.files, index)}
                                                                            />
                                                                        </>
                                                                }

                                                                {/* delete icon */}

                                                                <div className={`input-group-append`}>
                                                                    <button className="btn btn-light" style={{ height: "2.5rem" }} onClick={() => delCol(index)} type="button">
                                                                        <img src={CrossICon} alt="" width="15px" />
                                                                    </button>
                                                                </div>

                                                                {/* drag n drop */}

                                                                <div className={`input-group-append ${styles.dnd_options}`}>
                                                                    <span {...provided.dragHandleProps} >
                                                                        <img src={DndIcon} alt="" width="40px" />
                                                                    </span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    )}
                                                </Draggable>

                                            })}
                                            <div className="row">
                                                <div className="col-12">
                                                    <button className="btn btn-light rounded-circle float-right" onClick={() => addNewColumn()} type="button">+</button>
                                                </div>
                                            </div>

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>

                        </div>

                    </div>



                    <div className="d-flex">
                        {/* Required */}
                        <div className="custom-checkbox custom-control pb-4 col-md-3 col-6">
                            <input type="checkbox" id={`required ${props.questionIndex}`} className="custom-control-input" checked={props.required} onChange={() => onChangeRequired()} />
                            <label className="custom-control-label" htmlFor={`required ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Required</span>
                            </label>
                        </div>

                        {/* Random Order Selection */}
                        <div className="custom-checkbox custom-control pb-4 mr-3">
                            <input type="checkbox" id={`randomOrder ${props.questionIndex}`} className="custom-control-input" checked={props.isRandomOrder} onChange={(e) => onSelectRandomOrder()} />
                            <label className="custom-control-label" htmlFor={`randomOrder ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Randomized Order</span>
                            </label>
                        </div>

                        {/* Additional Comment Selection */}
                        <div className="custom-checkbox custom-control pb-4 mr-3">
                            <input type="checkbox" id={`additionalComment ${props.questionIndex}`} className="custom-control-input" checked={props.additionalComment} onChange={(e) => onSelectAddComment()} />
                            <label className="custom-control-label" htmlFor={`additionalComment ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Additional Comment Required</span>
                            </label>
                        </div>

                        {/* Unique Selected */}
                        <div className="custom-checkbox custom-control pb-4 mr-3">
                            <input type="checkbox" id={`uniqueSelected ${props.questionIndex}`} className="custom-control-input" checked={props.uniqueSelected} onChange={(e) => onChangeUniqueCol()} />
                            <label className="custom-control-label" htmlFor={`uniqueSelected ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Select Unique Column Responses</span>
                            </label>
                        </div>

                    </div>

                    {/* Validation error */}
                    <div className="col-12">
                        {!props.isValidQuestion && (
                            <small className="text-danger">
                                Please complete this question.
                            </small>
                        )}
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Create_Question_With_Grid


