import React, { useEffect, useState } from 'react';

import { TransitionGroup } from 'react-transition-group';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'

import Group_Forum_Details_View from './Group_Forum_Details_View';
import Group_Forum_Write_Reply from './Group_Forum_Write_Reply';
import Group_Forum_Replies from './Group_Forum_Replies';

import { useDispatch } from 'react-redux';
import { getActivity, getActivityImg } from '../../../actions/activities_actions';
import { useParams } from 'react-router-dom';
import Back from '../../../assets/components/back/Back';
import { getAllPostReply } from '../../../actions/activities_reply_actions';

const Group_Forum_Details = () => {

    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [forumDetails, setForumDetails] = useState({})
    const[bannerImg, setBannerImg] = useState({})
    const params = useParams()
const [ForumReplies, setForumReplies] = useState([])
    useEffect(() => {
        getActivityDetails()
        // getForumReply()
    }, [])



    const getActivityDetails = () => {

        // dispatch(getActivityImg(params.interviewid))
        // .then((response) =>{
        //     try{
        //     console.log('banner img in gf respondant side->',response.payload.content.fileData)
        //     setBannerImg(response.payload.content.fileData)}catch(err){console.log('img not available')}
            
        // })
        dispatch(getActivity(params.forumid))
            .then((response) => {
               
              
                setForumDetails(response.payload.content)
                
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }


    const getForumReply = (page = 1, limit = 5) => {
        dispatch(getAllPostReply({ activityId: params.forumid, page: page, limit: limit }))
            .then((response) => {
                 setForumReplies(response.payload.content.posts)
            })
            .catch(err => {
                console.log('err')
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(()=>{
        getForumReply()
    },[])


    return (
        
        <React.Fragment>

            <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                <div className="container-fluid pt-3">

                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                // position: 'fixed',
                                // top: '2%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        {/* <Back /> */}

                        <span className="mt-5"></span>
                        {/* Forum Detail View */}
                        <Group_Forum_Details_View
                            title={forumDetails.name}
                            question={forumDetails.query}
                            description={forumDetails.description}
                            profileImage={require('../../../assets/images/male-user-avatar.svg')}
                            noMembers={forumDetails.audience ? forumDetails.audience.members.length : "All"}
                            noReplies={forumDetails.repliesCount}
                            postedTime={forumDetails.created_at}
                            admin={forumDetails.author ? forumDetails.author.userName :'Administrator'}
                        />


                        {/* <div className="mx-3 pt-4">
                            
                            <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="mb-0">All Replies</h5>
                        </div> */}

                        {/* All Replies */}
                        {
                            // forumDetails.displayResponses === 'Yes' &&
                            <div className="my-4">
                                <Group_Forum_Replies 
                                ForumReplies ={ForumReplies}
                                
                                />
                            </div>
                        }

                    </LoadingOverlay>

                </div>

            </TransitionGroup>
            {
                new Date(forumDetails.startDate) >= Date.now() ?
                    <div className='col-12 text-center text-muted'>Discussion will start on {new Date(forumDetails.startDate).toLocaleDateString()}</div>
                    :
                    <div className={'app-footer'}>
                        <div className={'app-footer__inner'}>

                            <div className="container-fluid mx-5">

                                {/* Write a reply */}
                                <Group_Forum_Write_Reply
                                    getReplies={getForumReply}
                                    placeholder={`write a comment `}
                                   
                                   
                                />

                            </div>

                        </div>
                    </div>
            }


        </React.Fragment>
    )

}

export default Group_Forum_Details;