
import React, { useEffect, useState } from 'react';
import ResizeDetector from 'react-resize-detector';
import YouTube from 'react-youtube';
import styles from '../../css/styling.module.css';
import ArrowLeft from '../../images/backward.png'
import ArrowRight from '../../images/forward.png'
import { getActivityVideo2, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';

const Question_With_Grid2 = (props) => {

    // On Radio Value change
    // const onRadioChange = (value) => {

    //     console.log('value of radio in grid ->', value)

    //     let isSelected = false;
    //     // props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = [];
    //     if (!props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected) {
    //         props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = []
    //     }

    //     props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected.some((data, i) => {
    //         if (props.uniqueSelected) {

    //             if (data.column === value.column || data.row === value.row) {

    //                 props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected[i] = value;
    //                 isSelected = true;


    //             }

    //         } else {

    //             if (data.row === value.row) {
    //                 props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected[i] = value;
    //                 isSelected = true;
    //             }

    //         }

    //     });
    //     // } else {

    //     //     props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = [value];
    //     //     isSelected = true;

    //     // }


    //     if (!isSelected) {
    //         props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected.push(value);
    //     }

    //     // Insert only Unique objects into selected array
    //     props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = [...new Set(props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected.map(JSON.stringify))].map(JSON.parse);

    //     props.allContent.pages[props.pageIndex].questions[props.questionIndex].rows.map((row, rowIndex) => {
    //         let allContent = props.allContent
    //         let questionId = props.allContent.pages[props.pageIndex].questions[props.questionIndex]._id

    //         //    const rows={
    //         //     questionType: 'grid',
    //         //     questionIndex: props.questionIndex,
    //         //     questionId: questionId,
    //         //     formContent: allContent,
    //         //     rowIndex: rowIndex,
    //         //     rowText: row.text
    //         //    }
    //         // dispatch(sendActivityReplyAnswers(params.surveyid, rows))
    //         // .then((response =>{
    //         //   console.log('response from backend in single answer ->', response)
    //         // }))

    //         props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected.map((selected, selectedIndex) => {
    //             if (selected.row === rowIndex + 1) {
    //                 // console.log(`questions[${props.questionIndex}][gridResponse][${rowIndex}][column]`, props.allContent.pages[props.pageIndex].questions[props.questionIndex].columns[selected.column - 1].text)
    //                 let allContent = props.allContent
    //                 let questionId = props.allContent.pages[props.pageIndex].questions[props.questionIndex]._id
    //                 const columns = {
    //                     questionType: 'grid',
    //                     formContent: allContent,
    //                     questionIndex: props.questionIndex,
    //                     questionId: questionId,
    //                     rowIndex: rowIndex,
    //                     rowText: row.text,
    //                     colText: props.allContent.pages[props.pageIndex].questions[props.questionIndex].columns[selected.column - 1].text,
    //                     activityId: allContent.activityId,
    //                     activityType: allContent.questionnaireType,
    //                     pageNo: props.pageIndex,
    //                     pageName: props.allContent.pages[props.pageIndex].pageName
    //                 }
    //                 if (userId) {
    //                     dispatch(sendSharableSurveyReplyAnswers(params.ActivityId, columns, userId))
    //                         .then((response => {
    //                             console.log('response from backend in single answer sharable surveys ->', response)
    //                         }))
    //                 }
    //                 else {
    //                     dispatch(sendActivityReplyAnswers(params.surveyid, columns))
    //                         .then((response => {
    //                             console.log('response from backend in single answer ->', response)
    //                         }))
    //                 }
    //             }
    //         })

    //     })


    //     props.setContent({ ...props.allContent });


    // }

    const onRadioChange = (value) => {
        let isSelected = false;
        // props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = [];
        if (!props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected) {
            props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected = []
        }

        props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.some((data, i) => {
            if (props.uniqueSelected) {

                if (data.column === value.column || data.row === value.row) {

                    props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected[i] = value;
                    isSelected = true;

                }

            } else {

                if (data.row === value.row) {
                    props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected[i] = value;
                    isSelected = true;
                }

            }

        });
        // } else {

        //     props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = [value];
        //     isSelected = true;

        // }


        if (!isSelected) {
            props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.push(value);
        }

        // Insert only Unique objects into selected array
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected = [...new Set(props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected.map(JSON.stringify))].map(JSON.parse);


        props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;
        //console.log('grid queston index ->',props.questionNo)
        props.setContent({ ...props.allContent });

    }

    // On Comment
    const onComment = (e) => {

        props.allContent.pages[props.pageIndex].questions[props.questionIndex].comment = e.target.value;
        props.setContent({ ...props.allContent });

    }
    const [isShowLeft, setLeftArrow] = useState(false)
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollLeft === e.target.clientWidth;
       // console.log(bottom, e.target.scrollHeight, e.target.scrollLeft, e.target.clientWidth)

        if (e.target.scrollLeft === 0) {
            setLeftArrow(false)
        } else setLeftArrow(true)
    }

    const isShowArrows = (index) => {
        var div = document.getElementById('container_div_id' + index);
      //  console.log(div)

        if (div)
            var hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
        // var hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
        if (hasHorizontalScrollbar)
            return true
        else
            return false

    }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };

    const params = useParams();
    const userId = params.UserId
    const dispatch = useDispatch();
   
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);



    const catId = props.categoryId
    const questionIndex = props.questionIndex


    useEffect(() => {
        if(props.questionFile && props.questionFile !==''){
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {
                    
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('question video not available') }
                    setIsLoading(false);

                })

           
        }
    }
}, []);


    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (

                <div className="my-3">
                    <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                        <div className={styles.card_border_body + " pt-4 pb-1"}>
                            <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                                {/* Required check */}
                                {props.required &&
                                    <span className="text-danger mr-1">*</span>
                                }

                                {/* Question text */}
                                {/* {props.questionText} */}
                                {props.questionText &&
                                    <span style={{ color: '#000', fontSize: '30px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                                }

                                {/* Question video */}
                                {props.questionVideo && (
                                    <div>

                                        <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                                    </div>
                                )}

                            </p>

                            {/* Question Video */}
                            {
                                props.questionFile &&
                                <>
                                    {
                                        (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                            <div className="mx-3 mt-2 mb-4">

                                                {/* Question Video Display */}
                                                <div className="pt-3">
                                                    <div className="position-relative">
                                                        {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                            <source src={props.questionFile} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video> */}
                                                        {isLoading ? (
                                                            <b>Loading Video...</b>
                                                        ) : qVideo.data ? (
                                                            <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                            <ReactPlayer
                                                                url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                                controls
                                                                width ='100%'
                                                            />
                                                            </div>
                                                        ) : (
                                                            <b>Loading Video....</b>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                            /* Question Image */
                                            <div className="mb-3 mt-3">

                                                {qVideo.data &&

                                                    // Image Display
                                                    <div>
                                                        <div className="position-relative">
                                                            <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                    }
                                </>


                            }

                            {/* this is a grid type question. it has rows which has questions and columns which have options with radio input for checkmarks in adjacent to rows which has questions in it just like any grid type questionaire will be. Now i need this as it is in large screens but on smaller screens and in mobile view i need to show questions in rows as individual questions taking full width with their adjacent options this time under questions rendering as options taking full width as well and second option will come under first one and so on. Write a code for it. So the grid type questions will change to radio type questions in mobile view. In this way it will be more visually appealing and respondent can clearly see each option. */}


                            {/* Grid */}
                            <div className="mx-3 mt-3 mb-4" style={{ position: 'relative' }}>

                                {/* <div  className="table-responsive mt-2"  >
                                    <table className="table table-bordered m-0" style={{tableLayout:'fixed'}}>
                                        <thead 
                                            style={{ position: 'sticky', left: 0, zIndex: 5}}
                                        >
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, top: 0, zIndex: 4, backgroundColor:'#EEF8FF',  borderTop:'none',borderLeft:'none', borderRight:'4px solid #ddd', width:'70px' }}></th>

                                                {props.columns.map((column, index) => {
                                                    //console.log(column)
                                                    return <th key={index} style={{ position: 'sticky', top: 0, zIndex: 3, width:'70px' }}>

                                                        
                                                        {column.text && column.text}

                                                       
                                                        {column.image && (
                                                            <div className="mt-2">
                                                                <img style={{ borderRadius: "10px" }} src={column.image} width="90px" height="90px" />
                                                            </div>
                                                        )}

                                                    </th>
                                                })}
                                            </tr>
                                        </thead>
                                        
                                        <tbody>

                                            {props.rows.map((row, rowNo) => {
                                                return <tr key={rowNo}>

                                                    <td className="text-left" style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: "#EEF8FF", color: "#0069AB",  borderBottom:'none',  borderRight:'4px solid #ddd', width:'70px' }}>

                                                        
                                                        {row.text && row.text}

                                                      
                                                        {row.image && (
                                                            <div className="p-3">
                                                                <img style={{ borderRadius: "10px" }} src={row.image} width="100px" height="100px" />

                                                            </div>
                                                        )}

                                                    </td>

                                                    {props.columns.map((column, columnNo) => {
                                                        return <td key={columnNo} style={{width:'70px'}}>
                                                            <div className="custom-radio custom-control" >
                                                                <input type="radio" id={props.id + columnNo + rowNo} name={props.name} className="custom-control-input" onChange={e => onRadioChange({ row: rowNo + 1, column: columnNo + 1 })} checked={props.selected && props.selected.some(data => data.row === (rowNo + 1) && data.column === (columnNo + 1))} />
                                                                <label className="custom-control-label" htmlFor={props.id + columnNo + rowNo}></label>
                                                            </div>
                                                        </td>
                                                    })}
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div> */}


                                <div className="table-responsive mt-2">
                                    <table className="table table-bordered m-0" style={{ tableLayout: 'fixed' }}>
                                        {window.innerWidth > 768 &&
                                            <thead
                                                style={{ position: 'sticky', left: 0, zIndex: 5 }}
                                            >
                                                <tr>
                                                    <th style={{ position: 'sticky', left: 0, top: 0, zIndex: 4, backgroundColor: '#EEF8FF', borderTop: 'none', borderLeft: 'none', borderRight: '4px solid #ddd', width: '70px' }}></th>

                                                    {props.columns.map((column, index) => (
                                                        <th key={index} style={{ position: 'sticky', top: 0, zIndex: 3, width: '70px' }}>
                                                            {/* Column Text */}
                                                            {column.text && column.text}
                                                            {/* Column Image */}
                                                            {column.image && (
                                                                <div className="mt-2">
                                                                    <img style={{ borderRadius: "10px" }} src={column.image} width="90px" height="90px" alt="column" />
                                                                </div>
                                                            )}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                        }
                                        <tbody>
                                            {props.rows.map((row, rowNo) => (
                                                <React.Fragment key={rowNo}>


                                                    {
                                                        window.innerWidth > 767 ?

                                                            <tr className="large-screens">
                                                                <td className="text-left" style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: "#EEF8FF", color: "#0069AB", borderBottom: 'none', borderRight: '4px solid #ddd', width: '70px' }}>
                                                                    {/* row Text */}
                                                                    {row.text && row.text}
                                                                    {/* row Image */}
                                                                    {row.image && (
                                                                        <div className="p-3">
                                                                            <img style={{ borderRadius: "10px" }} src={row.image} width="100px" height="100px" alt="row" />
                                                                        </div>
                                                                    )}
                                                                </td>

                                                                {props.columns.map((column, columnNo) => (
                                                                    <td key={columnNo} style={{ width: '70px' }}>
                                                                        <div className="custom-radio custom-control" >
                                                                            <input type="radio" id={props.id + columnNo + rowNo} name={props.name} className="custom-control-input" onChange={(e) => onRadioChange({ row: rowNo + 1, column: columnNo + 1 })} checked={props.selected && props.selected.some(data => data.row === (rowNo + 1) && data.column === (columnNo + 1))} />
                                                                            <label className="custom-control-label" htmlFor={props.id + columnNo + rowNo}></label>
                                                                        </div>
                                                                    </td>
                                                                ))}
                                                            </tr>

                                                            :
                                                            <tr className="small-screens">
                                                                <td colSpan={props.columns.length + 1}>
                                                                    {/* Display individual questions with options in mobile view */}
                                                                    <div>
                                                                        <div style={{ backgroundColor: "#EEF8FF", color: "#0069AB", fontSize: '20px' }}>{row.text}</div>

                                                                        {props.columns.map((column, columnNo) => (
                                                                            <div key={columnNo} className="custom-radio custom-control" style={{ marginTop: '10px', display:'flex' }}>
                                                                                
                                                                                    <input type="radio" id={props.id + columnNo + rowNo} name={props.name} className="custom-control-input" onChange={(e) => onRadioChange({ row: rowNo + 1, column: columnNo + 1 })} checked={props.selected && props.selected.some(data => data.row === (rowNo + 1) && data.column === (columnNo + 1))} />
                                                                               
                                                                                
                                                                                    <label style={{paddingLeft:'10px', color:'#000',fontWeight:'500', letterSpacing:'0.5px'}}className="custom-control-label" htmlFor={props.id + columnNo + rowNo}>{column.text}</label>
                                                                                </div>
                                                                          


                                                                        ))}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                {/* Comment */}
                                {props.isComment && (
                                    <div className="form-group mt-4 mb-3">
                                        <label>Comment</label>
                                        <input className="form-control" type="text" value={props.comment} onChange={onComment} placeholder="Write here" />
                                    </div>
                                )}

                            </div>

                            {/* Required Answer error */}
                            {props.required && !props.isAnswered && (
                                <div id={`error-msg-${props.id}`}>
                                    <div className="my-2">
                                        <div className="alert alert-danger mb-0">
                                            Please answer this question.
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div >
            )}
        />
    )

}

export default Question_With_Grid2;