import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import avatar from '../../../../assets/images/profile_placeholder.png'
import { useParams, useNavigate } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import TemplateIcon from '../../../../assets/images/template_header_icon.png'
import styles from '../../../../assets/css/rstyling.module.css'
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getSmsTemplateDetails } from '../../../../actions/sms_template_actions';
import ArrowLeft from '../../../../assets/images/arrow_left.png'

const ViewSms = () => {

    const smsTemplateState = useSelector(state => state.SmsTemplates)
    const [isLoading, setLoading] = useState(true)
    const [smsTemplateDetail, setSmsTempDetails] = useState({})

    const params = useParams()
    const history = useNavigate()
    //console.log(smsTemplateDetail)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSmsTemplateDetails(params.templateid))
            .then(response => {
                setSmsTempDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Something went wrong",
                    icon: 'error',
                });
                history('/admin/templates/sms')

            })
    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Page Header */}
                <div className="admin_header">
                    <PageHeader heading={`SMS Title: ${smsTemplateDetail.title}`} img={TemplateIcon} />
                </div>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>
                <div className="container-fluid mt-3">
                    <div className="widget-content p-0">
                        {/* Sender */}
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                                <img width={38} className="rounded-circle" src={avatar} alt="" />
                            </div>
                            <div className="widget-content-left">
                                <div className="widget-heading text-dark" style={{ fontSize: '1rem' }}>
                                    {smsTemplateDetail.author && smsTemplateDetail.author.userName}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Sms Content */}
                    <div className="mt-4">
                        <div className={styles.text_bold} style={{ fontSize: '1rem', fontWeight: 600 }}>SMS Template</div>
                        <div className="mt-3" >
                            {smsTemplateDetail.smsContent && smsTemplateDetail.smsContent.replaceAll('%20', ' ')}
                        </div>
                    </div>
                </div>
            </LoadingOverlay>

        </TransitionGroup>

    )
}

export default ViewSms
