import React, { useEffect, useState } from 'react';

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import styles from '../../../assets/css/newStyling.module.css'
import styles3 from '../../../assets/css/newStyling.module.css'
import Project_View from '../Projects/Project_View';
import { useDispatch } from 'react-redux';
import { getRespondentForum } from '../../../actions/activities_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'
import { useNavigate } from 'react-router-dom'
import medal from '../../../assets/images/medal.png'
import PerfectScrollbar from "react-perfect-scrollbar";
import noActivtityImage from '../../../assets/images/no-project-img.png'

// ************************ Main file of General Forums ************************************


const Group_Forums = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //const activityState = useSelector(state => state.Activity)
    const [forums, setForums] = useState({})
    const [isLoading, setLoading] = useState(true)
    const history = useNavigate()
    const [todayTasks, setTodayTasks] = useState({})
    const [OneForumCheck, setOneForumCheck] = useState(false)
    //  Forum View Layout controller
    // const layoutController = (width) => {

    //     width = parseInt(width);

    //     if (width <= 1400)
    //         return {
    //             class: "col-12"
    //         }

    //     else
    //         return {
    //             class: "col-6"
    //         }

    // }

    //  Projects View Layout controller
    const layoutController = (width) => {

        width = parseInt(width);

        if (width <= 1550 && width >= 1201)
            return {
                class: "col-6"
            }

        else if (width <= 1200)
            return {
                class: "col-12"
            }

        else
            return {
                class: "col-4"
            }

    }

    const joinTask = (id) => {


       

          

            
                navigate(`group-forum-detail/${id}`)
                
        
    }


    const getForums = (page = 1, limit = 1) => {
        dispatch(getRespondentForum({ page: page, limit: limit }))
            .then((response) => {
                //console.log('respondent forums ->', response.payload.content)
                setForums(response.payload.content.allForums)
if(response.payload.content.allForums.length ===1){
    setOneForumCheck(true)
    navigate(`group-forum-detail/${response.payload.content.allForums[0]._id}`)
}
                setLoading(false)
            })
            .catch(err => {
                //console.log(err)
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })

    }
    useEffect(() => {
        getForums(1, 1)
    }, [])


    // const previous = () => {
    //     getForums(activityState.currentPage - 1, 1)
    // }

    // const next = () => {
    //     getForums(activityState.currentPage + 1, 1)
    // }

    return (
        // <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
        //     transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

        //     <ResizeDetector
        //         handleWidth
        //         render={({ width }) => (
        //             <div className="container-fluid pt-5">


        //                 <LoadingOverlay tag="div" active={isLoading}
        //                     styles={{
        //                         overlay: (base) => ({
        //                             ...base,
        //                             background: "#fff",
        //                             opacity: 0.5,
        //                             // position: 'fixed',
        //                             // top: '2%'
        //                         }),
        //                     }}
        //                     spinner={
        //                         <Loader color="#6c757d" active type="ball-pulse" />
        //                     }>

        //                     {/* Project Specific Topics */}
        //                     <div className="clearfix">
        //                         <div className="float-left">
        //                             {/* Project Specific Topics */}
        //                             <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="mb-0">Project Specific Topics</h5>
        //                         </div>
        //                         <div className="float-right">
        //                             {/* View all */}
        //                             <h5 style={{ color: "#666", fontWeight: 600, fontSize: "0.95rem" }} className="mb-0 pointer">View all</h5>
        //                         </div>
        //                     </div>

        //                     {/* Project Specific View */}
        //                     <div className="row my-3">

        //                         {
        //                             forums.projectSpecificForums && forums.projectSpecificForums.length > 0 ?
        //                                 forums.projectSpecificForums.map(forum => {
        //                                     return <Project_View
        //                                         class={layoutController(width + 370).class}
        //                                         title={forum.name}
        //                                         details={forum.description}
        //                                         deadline={forum.endDate && new Date(forum.endDate).toLocaleDateString()}
        //                                         completePercentage={forum.completePercentage ? forum.completePercentage : 0}
        //                                         images={forum.bannerImage}
        //                                         link={() => history('/respondent/group-forum-detail/' + forum._id)}
        //                                     />

        //                                 })
        //                                 :
        //                                 <div className="col-12 text-center">No Forums found</div>
        //                         }

        //                     </div>

        //                     {/* General Topics */}
        //                     <div className="clearfix mt-5">
        //                         <div className="float-left">
        //                             {/* General Topics */}
        //                             <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="mb-0">General Topics</h5>
        //                         </div>
        //                         <div className="float-right">
        //                             {/* View all */}
        //                             <h5 style={{ color: "#666", fontWeight: 600, fontSize: "0.95rem" }} className="mb-0">View all</h5>
        //                         </div>
        //                     </div>

        //                     {/* General Topics View */}
        //                     <div className="row my-3">

        //                         {
        //                             forums.generalForums && forums.generalForums.length > 0 ?
        //                                 forums.generalForums.map(forum => {
        //                                     return <Project_View
        //                                         class={layoutController(width + 370).class}
        //                                         title={forum.name}
        //                                         details={forum.description}
        //                                         deadline={forum.endDate && new Date(forum.endDate).toLocaleDateString()}
        //                                         completePercentage={forum.completePercentage ? forum.completePercentage : 0}
        //                                         images={forum.bannerImage}
        //                                         link={() => history('/respondent/group-forum-detail/' + forum._id)}
        //                                     />

        //                                 })
        //                                 :
        //                                 <div className="col-12 text-center">No General Forums found</div>
        //                         }

        //                     </div>

        //                     {/* <div className='col-12 text-center'>
        //                         <ButtonGroup size="sm">
        //                             <Button className={styles.sent_gradient_btn + " text-light px-5 py-2"} onClick={() => previous()} disabled={activityState.currentPage === 1 ? true : false}>
        //                                 Previous
        //                             </Button>
        //                             <Button className={styles.sent_gradient_btn + " text-light px-5 py-2"} onClick={() => next()} disabled={activityState.totalPages === activityState.currentPage ? true : false}>
        //                                 Next
        //                             </Button>
        //                         </ButtonGroup>
        //                     </div> */}
        //                 </LoadingOverlay>


        //             </div>
        //         )}
        //     />
        // </TransitionGroup>
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <LoadingOverlay tag="div" active={''}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                position: 'fixed',
                                top: '20%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        <React.Fragment>

                            <div className="container-fluid pt-3">








                                <div className="row mt-3">
                                    {/* removed resize detecor layout function here */}
                                    <div className='col-12 pt-3'>
                                        {/* Projects Section */}
                                        <div className={forums && forums.length >0 ?`${styles.card_border}`:``}>

                                            {/* <div className={styles.card_border_header}>
                                            Latest Tasks
                                        </div> */}

                                            <div className={styles.card_border_body + " pt-2 pb-3 px-0"}>
                                                <div className="scroll-area-lg" style={{ height: 'auto', minHeight: '100px' }}>
                                                    <PerfectScrollbar>
                                                        {/* individual project block */}
                                                     
                                                        {forums && forums.length > 0 ? forums.map(x => {
                                                            if (x != null) {
                                                                return (
                                                                    <div className={`container py-2`} key={x._id}>
                                                                        <div class="row">
                                                                            <div class="">
                                                                                {/* <img className={styles3.project_tag_banner} src='https://www.simplilearn.com/ice9/free_resources_article_thumb/What_Is_a_Project.jpg' alt={x.title}></img> */}
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <p className={`${styles3.group_forum_title} pt-2`} onClick={() => joinTask(x._id)}>{x.name}</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p className='py-3'>{x.activityType ==='General Forum' ? 100:150}<span><img src={medal} style={{ width: '20px' }}></img></span></p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p className='py-3'>{x.author.userName}<span></span></p>
                                                                            </div>

                                                                            <div class="col">
                                                                                <button className={`${styles3.apply_now_button} my-1`}>
                                                                                    <p className={`${styles3.apply_now_text} pt-2`} onClick={() => joinTask(x._id)}>Participate</p>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null; // Skip null values
                                                            }
                                                        }) : <div style={{textAlign:'center'}}>
                                                          
                                                        <img style={{ width: '70%', height: '50%' }} src={noActivtityImage}></img>
                                                        <p className='container pt-2' style={{ color: '#8E8E8E', fontFamily: 'Poppins', fontSize: '18px' }}>No community for now, but stay tuned! We're crafting exciting opportunities just for you.</p>
                                                       

                                                    </div>}
                                                       



                                                    </PerfectScrollbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>










                        </React.Fragment>


                    </LoadingOverlay>

                )}

            />

        </TransitionGroup >
    )
}

export default Group_Forums;