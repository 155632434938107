import React, { useEffect } from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import logo from '../../assets/images/logo.png'
import PhoneInput from 'react-phone-input-2';
import landing_bg_new from "../../assets/images/landing_page.png";
import landing_page_logo from '../../assets/images/landing_page_logo.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styles from '../../assets/css/styling.module.css'
import { Button } from 'reactstrap'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import behbud_logo from '../../assets/images/behbud_logo.png'

export default function Min_Landing_page() {

  const { ActivityId, audience } = useParams()

  //const [searchParams] = useSearchParams();
  //const audience = searchParams.get('audience');
  //console.log('came in min landing page')
  const clearCookies = () => {
    if (document.cookie) {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    }
  };

  const clearCaches = async () => {

    try {
      // Log caches before clearing
      if ('caches' in window) {

        const cacheNamesBefore = await caches.keys();
       // console.log('Caches before clearing:', cacheNamesBefore);
      }

      // Clear local storage and session storage
      localStorage.clear();
      sessionStorage.clear();
      clearCookies();

      // Clear service worker caches
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map((name) => caches.delete(name)));

        // Log caches after clearing
        const cacheNamesAfter = await caches.keys();
       // console.log('Caches after clearing:', cacheNamesAfter);
      }
    } catch (err) {
      //console.log('Error clearing caches and storage:', err)
    }
  };

  useEffect(() => {
    clearCaches()
  }, [])


  return (




    <>
      <div className="container-fluid p-2 px-4">
        <div className='row justify-content-center'>
          <div className={`row p-2 ${styles3.login_container}`}>

            <div className={`col-sm-5 col-12 p-0 align-self-center`}>

              <div className={`${styles3.login_img}`}
                style={{
                  backgroundImage: "url(" + landing_bg_new + ")",
                }} />


            </div>

            <div className="col-sm-7 col-12 bg-white align-self-center">
              <div className='row justify-content-center'>
                <div className={`${styles3.login_section}`}>
                  {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                  <div className=" container" >



                    <div className="container text-center">
                       <img className={`${styles3.login_logo} mb-5`} src={behbud_logo} alt="logo" width="200px" />
                      {/*<p style={{ color: '#000000', fontWeight: '600', fontSize: '1rem' }}>Welcome to Consumer Connect Community</p>
                      <p style={{ color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal' }}>Share your feedback through quick surveys and polls. Your opinions help brands improve and innovate. Join us to make a real impact on the products you use every day.</p> */}
                      <div >
                        {
                          audience === 'minimal' ?
                            <a href={`/minimal_login/${ActivityId}`} className={`btn-hover-shine btn text-light btn-block py-3`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} >
                              Returning Patient
                            </a> : ''
                        }

                        <div className='row'>
                          <div className='col'><hr></hr></div>
                          <div className='col-2'><div className='py-2' style={{ color: 'rgb(173, 167, 167)', fontWeight: 'bold' }}>Or</div></div>
                          <div className='col'><hr></hr></div>
                        </div>


                        {
                          audience === 'minimal' ?
                            <a href={`/minimal_setup_profile/${ActivityId}`} className={`btn-hover-shine btn text-light btn-block py-3`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} >
                              New Patient
                            </a> : ''
                        }
                      </div>












                    </div>
                  </div>
                </div>
              </div>










            </div>
          </div>
        </div>
      </div>
    </>

























  )
}