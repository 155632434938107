import React, { useState, useEffect } from 'react'
import '../../../../assets/components/pageheader/PageHeader'
import styles from '../../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../../assets/images/search_icon.png'
import {
    Card, CardBody,
} from "reactstrap";
import Listing from '../../../../assets/components/listing/Listing'
import { useNavigate } from 'react-router';
import CopyIcon from '../../../../assets/images/copy_icon.png'
import Eyeicon from '../../../../assets/images/eye_icon.png'
import Deleteicon from '../../../../assets/images/delete_icon.png'
import Editicon from '../../../../assets/images/edit_icon.png'
import ResizeDetector from "react-resize-detector";
import AlertModal from '../../../../assets/components/modal/AlertModal';
import { delCampaign, getAllCampaigns } from '../../../../actions/campaign_actions';
import Loader from "react-loaders";
import Swal from 'sweetalert'
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';

const CampaignMarketing = () => {

    const [delId, setDelID] = useState()
    const [isLoading, setLoading] = useState(true)

    const campaignState = useSelector( state => state.Campaign)
    const dispatch = useDispatch()
    const [campaignColumn, setCampaignColumn] = useState([
        { Name: 'Serial No', Type: 'serialNo', key: 'serialNo' },
        { Name: "Name of Campaigns", Type: 'string', key: 'title' },
        { Name: "Created Date", Type: 'date' , key: 'created_at' },
        { Name: "Created Time", Type: 'time' , key: 'created_at' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: "/admin/view-campaign-template",
                    type: 'button'
                },
                {
                    icon: Editicon,
                    url: "/admin/edit-campaign-template",
                    type: 'button'
                },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },
                {
                    icon: CopyIcon,
                    url: "/admin/create-campaign-template",
                    type: 'button'
                },

            ]
        }
    ])

    const [campaignData, setCampaignData] = useState([])

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    const [searchString, setSearchString] = useState('')
    const [searchedCampaign, setSearchedCampaign] = useState([])

    // Global filtering
    useEffect(() => {
        let search = new RegExp(searchString, 'i');
        let filteredCampaigns = campaignData.filter(row => {
            return (
                search.test(row.title) ||
                search.test(row.date)
            );
        });
        setSearchedCampaign(filteredCampaigns);
    }, [searchString]);

    // Get templates to show in listing
    const getCampaignListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getAllCampaigns(currentPage, dataLimit))
            .then(response => {
                setCampaignData(response.payload.content.campaigns)
                setLoading(false)
            })
            .catch(err => {
               // console.log(err);
                setCampaignData([])
                setLoading(false)
            })
    }

    const deleteCampaign = () => {
        dispatch(delCampaign(delId))
            .then(response => {
                setDelID()
                getCampaignListing(campaignState.currentPage, campaignState.dataLimit)
                Swal({
                    title: "Deleted",
                    text: "Template Deleted",
                    icon: 'info'
                });
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                });
            })
    }

    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    useEffect(() => {
        getCampaignListing()
    }, [])



    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                    {/* Delete Modal */}
                    <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={deleteCampaign}/>

                    <Card className="main-card mb-3">
                        <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                            <div className="text-white menu-header-title text-capitalize">Campaign List</div>

                            <div className={width > 470 && "collapse navbar-collapse"}>
                                <ul className="navbar-nav mr-auto">
                                </ul>
                                <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2.5rem' }}>
                                    <div className="input-group input-group-sm w-75">
                                        <div className="input-group-prepend border-0">
                                            <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                        </div>
                                        <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} onChange={(e) => setSearchString(e.target.value)} />
                                    </div>

                                </form>
                            </div>
                        </nav>

                        <CardBody className="p-0">
                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>
                                <CardBody className="p-0">

                                    {/* Listing */}
                                    <div className="table_border">
                                        <Listing columnName={campaignColumn}
                                            data={(searchString.length > 0 || searchedCampaign.length > 0) ? searchedCampaign : campaignData}
                                            currentPage={campaignState && campaignState.currentPage ? campaignState.currentPage : 1}
                                            totalPages={campaignState && campaignState.totalPages ? campaignState.totalPages : 1}
                                            getListingData={(currentPage, dataLimit) => getCampaignListing(currentPage, dataLimit)}
                                            startLoading={() => setLoading(true)}
                                        />
                                    </div>

                                </CardBody>
                            </LoadingOverlay>
                        </CardBody>
                    </Card>
                </TransitionGroup>
            )}
        />
    )
}

export default CampaignMarketing
