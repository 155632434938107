import React, { useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { CardHeader, Card, CardBody } from "reactstrap";
import { VerticalTimeline } from "react-vertical-timeline-component";
import avatarMen from "../../../../../../assets/images/profile_placeholder_men.png";
import avatarWomen from "../../../../../../assets/images/profile_placeholder.png";
import { getAllSurveyReply, deleteOneReplyOfASurvey } from '../../../../../../actions/activities_reply_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import SurveyView from '../../view/SurveyResponsesView'
import CreateExcerpt from '../../view/Excerpts/CreateExcerpt';
import Swal from 'sweetalert'

const ScreenerAnalytics = () => {


    const surveyState = useSelector(state => state.SurveyReply)
    const dispatch = useDispatch()
    const params = useParams()
    const [surveyReply, setSurveyReplies] = useState([])
    const [isLoading, setLoading] = useState(true)


    const [isModalOpen, setModal] = useState(false)

    const [excerptData, setExcerptData] = useState({
        selectedString: '',
        note: '',
        activity: '',
        responseAuthor: ''
    })

    const resetData = () => {
        setExcerptData({
            selectedString: '',
            note: '',
            activity: '',
            responseAuthor: ''
        })
    }

    // Show Image inModal
    const showModal = (author, details) => {
       
        resetData()
        setModal(!isModalOpen)

        setExcerptData(prevState => ({
            ...prevState,
            selectedString: window.getSelection().toString(),
            activityType: details.title,
            activityTitle: details.title,
            activity: params.activityid,
            responseAuthor: author
        }))

    }

    const getResponses = (page = 1, limit = 5) => {
        //dispatch(getAllSurveyReply({ page: page, limit: limit, activityId: params.activityid }))
        //pages 
//         .then((response) => {
//             console.log('response from backend for all replies ->',response.payload.content.replies)
//             const replies = response.payload.content.replies;
//             let result = [];
    
//             for (let i = 0; i < replies.length; i++) {
                
//   //previous logic
//                                 const repliesAnswers = response.payload.content.replies[i].questions
//                                 let singleReply = {
//                                     replies: [],
//                                     author: replies[i].author,
//                                     created_at: replies[i].created_at
//                                 }
//                                 for (let j = 0; j < replies[i].activity.survey.pages.length; j++) {
//                                     for (let k = 0; k < replies[i].activity.survey.pages[j].questions.length; k++) {
//                                         repliesAnswers.map(ans => {
//                                             if (ans.questionId === replies[i].activity.survey.pages[j].questions[k]._id) {
//                                                 // saving question object , answer and id
//                                                 singleReply.replies.push({
//                                                     ...replies[i].activity.survey.pages[j].questions[k],
//                                                     ...ans,
//                                                     _id: replies[i].activity.survey.pages[j].questions[k]._id
//                                                 })
//                                             }
//                                         })
//                                     }
//                                 }
//                                 result.push(singleReply)
                
                            

// // new logic
//                 // const reply = replies[i];
//                 // const singleReply = {
//                 //     replies: [],
//                 //     author: reply.author,
//                 //     created_at: reply.created_at
//                 // };
    
//                 // // Iterate through all pages of the survey
//                 // for (let j = 0; j < reply.activity.survey.pages.length; j++) {
//                 //     const page = reply.activity.survey.pages[j];
    
//                 //     // Iterate through all questions on the current page
//                 //     for (let k = 0; k < page.questions.length; k++) {
//                 //         const question = page.questions[k];
    
//                 //         // Find the corresponding answer in repliesAnswers
//                 //         const answer = reply.pages[j].questions[k];
    
//                 //         // Save the question object, answer, and question ID in the reply
//                 //         singleReply.replies.push({
//                 //             ...question,
//                 //             ...answer,
//                 //             _id: question._id
//                 //         });
//                 //     }
//                 // }
    
//                 result.push(singleReply);
//             }
    
//             setSurveyReplies(result);
//             setLoading(false)
//         })
    
            
        
dispatch(getAllSurveyReply({ page: page, limit: limit, activityId: params.activityid }))
.then((response) => {
   
    const replies = response.payload.content.replies;
   
    
   
    let result = [];

    for (let i = 0; i < replies.length; i++) {
        const reply = replies[i];
       
        const singleReply = {
            replies: [],
            author: reply.author,
            created_at: reply.created_at,
            replyId:reply._id,
            replyLoi: reply.loi
        };

       
        if (reply.questions && reply.questions.length !=0 && reply.pages && reply.pages.length === 0 ) {
         
            // Execute the previous logic block when we have only questions field in database
            const repliesAnswers = reply.questions;
            for (let j = 0; j < reply.activity.survey.pages.length; j++) {
                for (let k = 0; k < reply.activity.survey.pages[j].questions.length; k++) {
                    repliesAnswers.map(ans => {
                        if (ans.questionId === reply.activity.survey.pages[j].questions[k]._id) {
                            // saving question object, answer, and id
                            singleReply.replies.push({
                                ...reply.activity.survey.pages[j].questions[k],
                                ...ans,
                                _id: reply.activity.survey.pages[j].questions[k]._id
                            });
                        }
                    });
                }
            }
        } else {
          
            // Execute the new logic block
            // Iterate through all pages of the survey

//             for (let j = 0; j < reply.activity.survey.pages.length; j++) {
               
//                 const page = reply.activity.survey.pages[j];
               

//                 // Iterate through all questions on the current page in survey questionniare
//                 for (let k = 0; k < page.questions.length; k++) {
                  
//                     const question = page.questions[k];
// console.log('question id->', question)
//                     // Find the corresponding answer from survey questionniare in repliesAnswers
                  
//                     const answer = reply.pages[j].questions[k];
//                     console.log('respective answer having question id ->', answer)
                   
//                     singleReply.replies.push({
//                         ...question,
//                         ...answer,
//                         _id: question._id
//                     });
                
               
//                 }
//             }

// new logic for questions and answers mismatch
for (let j = 0; j < reply.activity.survey.pages.length; j++) {
    const page = reply.activity.survey.pages[j];

    // Iterate through all questions on the current page in survey questionnaire
    for (let k = 0; k < page.questions.length; k++) {
        const question = page.questions[k];
      

        // Find the corresponding answer from survey replies where IDs match
        const answer = reply.pages[j].questions.find(ans => ans.questionId === question._id);
       

        // Only push if matching answer is found
        if (answer) {
            singleReply.replies.push({
                ...question,
                ...answer,
                _id: question._id
            });
        }
    }
}
           
        }
       
        if (singleReply !== undefined && singleReply !== null) {
            result.push(singleReply);
        }

        //result.push(singleReply);
    
    result = result.filter(item => item !== undefined && item !== null);

    setSurveyReplies(result);}
    setLoading(false)
})


            .catch(err => {
                setLoading(false)
                // Swal({
                //     title: err.response ? err.response.data.name : "Error",
                //     text: err.response ? err.response.data.content : "Something went wrong",
                //     icon: 'error'
                // })
            })
    }

    useEffect(() => {
        getResponses()
    }, [])

    const next = () => {
        getResponses(surveyState.currentPage + 1, 5)
    }
   
    const deleteReply = (authorIdd, replyIdd, replyActivityIdd) => {

        const deleteReplyDetails = {
            authorId: authorIdd,
            replyId: replyIdd,
            replyActivityId: replyActivityIdd
        }
       
        dispatch(deleteOneReplyOfASurvey(deleteReplyDetails))
            .then(response => {

                if (response.payload.message === 'One Response deleted successfully') {
                    Swal({

                        text: "Response deleted",
                        icon: 'success'
                    })
                      .then( 
                        setTimeout(() => {
                            window.location.reload()
                          }, 2000)
                   
                      )
                }
                else if (response.payload.message === 'Unable to delete a response') {
                    Swal({

                        text: "Error in deleting response",
                        icon: 'error'
                    })
                }
            })
            .catch(err =>
                console.log('err')
            )
    }
    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <CreateExcerpt
                    data={excerptData} setData={setExcerptData}
                    isModalOpen={isModalOpen} showModal={() => setModal(false)}
                />

                {
                    surveyReply.length > 0 ?
                        surveyReply.map((reply, index) => {
                           
                            return <>
                                <Card className="main-card mb-3">
                                    {/* Author */}
                                    <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                                    <div className='px-2'>{`${index} )`}</div>
                                    
                                        <img src={reply.author && reply.author.gender ==='Male' ? avatarMen : avatarWomen} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                        {reply.author ? reply.author.userName: 'Respondent'} 
                                       

                                        <button class="btn mx-2" style={{ backgroundColor: 'white' }} onClick={() => deleteReply(reply.author ? reply.author._id: '', reply.replyId, params.activityid)} ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAVCAYAAABLy77vAAAACXBIWXMAAAsSAAALEgHS3X78AAABCklEQVQ4je2UsU0DQRBFnxG56QB3AB0AFXAlmGRiUwF2B+dwJyIkhApsOrA7cAtkZIe+mDud1rsgJEJ/aYOdnX0zq9X8Sdd11OTuM0DrYGaHaiJQBLn7BfAK3IzC78DCzHYl0FmlwDYga+AOWAHXikeRI50XummAK+DRzNoe7O6Cb4A50Ob3Sh2pMiMIsRdoDzSljiYppaa/HLqNZ60K+ermE3jJ4q2etoyn5HoqVQ7lZzv0a1oppS6ltOz3v608v/Zrf9YJdAL9N+gjm7mq3F3zKA1mNwbJyO7dXYP5E0R+NNhLHx8cMhJ0oAGWXQicS7Yrt5gCD2b2fAQawRZhF5eVpt7UUfjTt4AvZKN+gFmdfT0AAAAASUVORK5CYII=" alt="Delete Response" /></button>
                                       
                                        <div className='px-2'>{reply.replyLoi && reply.replyLoi}</div>
                                    </CardHeader>
                                    <CardBody>

                                        <VerticalTimeline layout="1-column"
                                            className="vertical-time-simple vertical-without-time">

                                            {
                                                
                                                reply.replies.map((question, index) => {
                                                    return <div onClick={() => showModal(reply.author, question)}>
                                                     
                                                        <SurveyView question={question} author={reply && reply.author && reply.author.userName} questionIndex={index} showVideos={false}/>
                                                    </div>
                                                })
                                            }

                                        </VerticalTimeline>
                                    </CardBody>
                                </Card>

                            </>
                        }) :
                        <div className={"pb-2 ml-5"}>
                            <div className="row px-3">
                                <div className="col -12 mt-3 align-self-center text-center">
                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Responses Found</span>
                                </div>
                            </div>
                        </div>
                }

                {/* Load More Data */}
                {
                    surveyState.totalPages > 0 && surveyState.totalPages !== surveyState.currentPage &&
                    <div className={"pb-2 ml-5"}>
                        <div className="row px-3">
                            <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Responses</span>
                            </div>
                        </div>
                    </div>
                }

            </LoadingOverlay>

        </TransitionGroup >
    )
}

export default ScreenerAnalytics
