import React, { Fragment, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import UnknownRoutes from './UnknownRoutes'
import Dashboard from './Dashboard/Dashboard';
import Referral from './Referral/Referral';
import Projectlist from './Project/ProjectList';
import Analytics from './Project/Activity/Analytics/Analytics';
import UserList from './UserManagement/UserList';
import UserActivities from './UserManagement/UserActivities'
import AddUser from './UserManagement/AddUser';
import Contact from './UserManagement/view/Contact';
import UserInfo from './UserManagement/view/UserInfo';
import CreateProject from './Project/create/CreateProject';
import Email from './Templates/Emails/Email';
import CreateTemplate from './Templates/Emails/create/CreateTemplate';
import CreateSmsTemplate from './Templates/sms/create/CreateSmsTemplate';
import Sms from './Templates/sms/Sms';
import Campaign from './Templates/Campaign/Campaign';
import CreateCampaignTemplate from './Templates/Campaign/create/CreateCampaignTemplate';
import Interview from './interview/Interview';
import SheduleInterview from './interview/create/SheduleInterview';
import Project from './Project/Project';
import Introduction from './Project/Introduction/Introduction';
import EditIntro from './Project/Introduction/EditIntro';
import FocusGroups from './Project/Participants/FocusGroup/FocusGroups';
import Main from './Project/Participants/Main';
import FocusGroupDetail from './Project/Participants/FocusGroup/FocusGroupDetail';
import InpersonInterview from './Project/Activity/create/InpersonInterview/InpersonInterview';
import InpersonInterviewPreview from './Project/Activity/create/InpersonInterview/Preview';
import OnlineInterview from './Project/Activity/create/OnlineInterview/OnlineInterview';
import OnlineInterviewPreview from './Project/Activity/create/OnlineInterview/OnlineInterviewPreview';
import Journal from './Project/Activity/create/journal/Journal';
import JournalPreview from './Project/Activity/create/journal/JournalPreview';
import Forum from './Project/Activity/create/Forum/Forum';
import ForumPreview from './Project/Activity/create/Forum/ForumPreview';
import Participant from './Project/Activity/view/Participant/Participant';
import Create_Survey from './Project/Activity/create/SurveyForm/CreateSurvey';
import SurveyFormPreview from './Project/Activity/create/SurveyForm/SurveyFormPreview';
import CreateEvaluation from './Project/Evaluation/CreateEvaluation';
import EvaluationFormPreview from './Project/Evaluation/EvaluationFormPreview';
import ExcerptsPreview from './Project/Activity/view/Excerpts/ExcerptsPreview';
import ExcerptsEdit from './Project/Activity/view/Excerpts/ExcerptsEdit';
import Respondent from './Project/Participants/Respondants';
import Researcher from './Project/Participants/Researcher';
import JournalActivity from './Project/Activity/view/Participant/JournalActivity';
import InpersonForumPreview from './Project/Activity/create/InpersonForum/InpersonForumPreview';
import InpersonForum from './Project/Activity/create/InpersonForum/InpersonForum';
import ViewEmail from './Templates/Emails/ViewEmail';
import ViewCampaign from './Templates/Campaign/ViewCampaign';
import ViewSms from './Templates/sms/ViewSms';
import SurveyReports from './Project/Activity/reports/SurveyReports';
import InterviewReports from './Project/Activity/reports/InterviewReports';
import JournalReports from './Project/Activity/reports/JournalReports';
import ForumReports from './Project/Activity/reports/ForumReports';
import Chat from './Chats/Chat';
import MiniSurveys from './MiniSurveys/MiniSurveys';
import CreateMiniSurvey from './MiniSurveys/CreateMiniSurvey';
import MiniSurveyPreview from './MiniSurveys/PreviewMiniSurvey';
import SearchResults from './Project/SearchResults/SearchResults';
import MiniSurveyParticipant from './MiniSurveys/view/MiniSurveyParticipant';
// import MyProfile from './Profile/MyProfile';
import Report from './Project/Report/Report';
import ProjectDetails from './Project/ProjectDetails/ProjectDetails';
import BroadcastMessages from './BroadcastMessages/BroadcastMessages';
import CreateBroadcastMessage from './BroadcastMessages/create/CreateBroadcastMessage';
import ViewBroadcastMessage from './BroadcastMessages/ViewBroadcastMessage';
import Settings from './Settings/Settings';
import MyProfile from './Profile/MyProfile';
import GeneralForum from './GeneralForums/GeneralForum';
import GeneralForumPreview from './GeneralForums/GeneralForumPreview';
import GeneralForumListing from './GeneralForums/GeneralForumListing';
import SurveyActivity from './Project/Activity/view/Participant/SurveyActivity';
import GeneralForumParticipants from './GeneralForums/view/Participants';
import GeneralForumActivity from './GeneralForums/view/GeneralForumActivity';
import MiniSurveyActivity from './MiniSurveys/view/MiniSurveyActivity';
import GuestProfile2 from '../Create Profile/GuestProfile_sharable';
import Scripting from '../Admin/MiniSurveys/view/Scripting'
import Script_Survey from './Project/Activity/create/SurveyForm/Script_Survey';
import RewardsTable from './Rewards/RewardsTable';
const Admin = () => {

    
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <AppHeader />
                    {width >= 1250 && <AppSidebar width={width} />}
                    <div className="app-main">
                       
                        {width < 1250 && <AppSidebar width={width} />}
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <div className="app-inner-layout">

                                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                                            <Routes>
                                        {/* <Route path="/guest_profile2" exact element={<GuestProfile2/>} />  */}
                                        {/* All Admin Routes  */}
                                        <Route path="/"  element={<Dashboard/>} />
                                        <Route path="/referral"  element={<Referral/>} />

                                        <Route path="/project" element={<Projectlist/>} />
                                        
                                        <Route path="/add-project" element={<CreateProject/>} />
                                        <Route path="/add-project/:projectid" exact element={<CreateProject/>} />
                                        <Route path="/edit-project/:projectid" exact element={<CreateProject/>} />

                                        <Route path="/project/intro/:projectid" exact element={<Project/>} />
                                        <Route path="/project/edit-intro/:projectid" exact element={<EditIntro/>} />
                                        <Route path="/project/detail/:projectid" exact element={<Project/>} />
                                        <Route path="/project/attachement/:projectid" exact element={<Project/>} />
                                        <Route path="/project/evaluation/:projectid" exact element={<Project/>} />
                                        <Route path="/project/activity/:projectid" exact element={<Project/>} />
                                        <Route path="/project/grouping/:projectid" exact element={<Project/>} />
                                        <Route path="/project/searchresults/:projectid" exact element={<SearchResults/>} />


                                        <Route path="/project/forum-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/journal-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/survey-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/interview-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/online-interview-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/inperson-forum-activity/:projectid/:activityid" exact element={<Project/>} />

                                        <Route path="/project/report/:projectid" exact element={<Report/>} />
 
   
                                        <Route path="/project/participants/:projectid" exact element={<Main/>} />
                                        <Route path="/project/groups/:projectid" exact element={<Main/>} />
                                        <Route path="/project/group/:projectid/:audienceId" exact element={<FocusGroupDetail/>} />
                                        <Route path="/respondantlist" exact element={<Respondent/>} />
                                        <Route path="/researcherlist" exact element={<Researcher/>} />

                                        <Route path="/minisurvey" exact element={<MiniSurveys/>} />
                                        <Route path="/create-minisurvey" exact element={<CreateMiniSurvey/>} />
                                        <Route path="/edit-minisurvey/:activityid" exact element={<CreateMiniSurvey/>} />
                                        <Route path="/preview-minisurvey/:activityid" exact element={<MiniSurveyPreview/>} />
                                        <Route path="/minisurvey-activity/:activityid" exact element={<MiniSurveyActivity/>} />
                                        <Route path="/minisurvey/responses/:activityid/:userid" exact element={<SurveyActivity/>} />
                                        <Route path="/minisurvey/scripting/:activityid" exact element={<Scripting/>} />
                                        
                                        <Route path="/minisurvey-analytics/:activityid" exact element={<Analytics/>} />

                                        <Route path="/project/create-interview/:projectid" exact element={<InpersonInterview/>} />
                                        <Route path="/project/create-interview/:projectid/:interviewid" exact element={<InpersonInterview/>} />
                                        <Route path="/project/preview-interview/:projectid/:interviewid" exact element={<InpersonInterviewPreview/>} />
                                        <Route path="/project/edit-interview/:projectid/:interviewid" exact element={<InpersonInterview/>} />

                                        <Route path="/project/create-onlineinterview/:projectid" exact element={<OnlineInterview/>} />
                                        <Route path="/project/create-onlineinterview/:projectid/:onlineinterviewid" exact element={<OnlineInterview/>} />
                                        <Route path="/project/preview-onlineinterview/:projectid/:onlineinterviewid" exact element={<OnlineInterviewPreview/>} />
                                        <Route path="/project/edit-onlineinterview/:projectid/:onlineinterviewid" exact element={<OnlineInterview/>} />

                                        <Route path="/project/create-journal/:projectid" exact element={<Journal/>} />
                                        <Route path="/project/create-journal/:projectid/:journalid" exact element={<Journal/>} />
                                        <Route path="/project/preview-journal/:projectid/:journalid" exact element={<JournalPreview/>} />
                                        <Route path="/project/edit-journal/:projectid/:journalid" exact element={<Journal/>} />

                                        <Route path="/project/preview-forum/:projectid/:forumid" exact element={<ForumPreview/>} />
                                        <Route path="/project/create-forum/:projectid" exact element={<Forum/>} />
                                        <Route path="/project/create-forum/:projectid/:forumid" exact element={<Forum/>} />
                                        <Route path="/project/edit-forum/:projectid/:forumid" exact element={<Forum/>} />

                                        <Route path="/project/preview-inpersonforum/:projectid/:forumid" exact element={<InpersonForumPreview/>} />
                                        <Route path="/project/create-inpersonforum/:projectid" exact element={<InpersonForum/>} />
                                        <Route path="/project/create-inpersonforum/:projectid/:forumid" exact element={<InpersonForum/>} />
                                        <Route path="/project/edit-inpersonforum/:projectid/:forumid" exact element={<InpersonForum/>} />

                                        <Route path="/project/create-survey/:projectid" exact element={<Create_Survey/>} />
                                        <Route path="/project/create-survey/:projectid/:surveyid" exact element={<Create_Survey/>} />

                                        <Route path="/project/script-survey/:projectid/:surveyid" exact element={<Script_Survey/>} />

                                        <Route path="/project/preview-survey/:projectid/:surveyid" exact element={<SurveyFormPreview/>} />
                                        <Route path="/project/edit-survey/:projectid/:surveyid" exact element={<Create_Survey/>} />

                                        <Route path="/project/preview-evaluation/:projectid/:evaluationid" exact element={<EvaluationFormPreview/>} />
                                        
                                        <Route path="/project/create-evaluation/:projectid" exact element={<CreateEvaluation/>} />
                                        <Route path="/project/edit-evaluation/:projectid/:evaluationid" exact element={<CreateEvaluation/>} />
                                        <Route path="/project/create-evaluation/:projectid/:evaluationid" exact element={<CreateEvaluation/>} />

                                        <Route path="/project/journal-activity/excerpt/:projectid/:excerptid" exact element={<ExcerptsPreview/>} />
                                        <Route path="/project/survey-activity/excerpt/:projectid/:excerptid" exact element={<ExcerptsPreview/>} />
                                        <Route path="/project/forum-activity/excerpt/:projectid/:excerptid" exact element={<ExcerptsPreview/>} />

                                        <Route path="/project/journal-activity/edit-excerpt/:projectid/:excerptid" exact element={<ExcerptsEdit/>} />
                                        <Route path="/project/survey-activity/edit-excerpt/:projectid/:excerptid" exact element={<ExcerptsEdit/>} />
                                        <Route path="/project/forum-activity/edit-excerpt/:projectid/:excerptid" exact element={<ExcerptsEdit/>} />
                                        <Route path="/project/participant-post-activity/:activityid/:userid" exact element={<JournalActivity/>} />
                                        <Route path="/project/participant-survey-activity/:activityid/:userid" exact element={<SurveyActivity/>} />

                                        <Route path="/project/journal-analytics/:projectid/:activityid" exact element={<Analytics/>} />
                                        <Route path="/project/survey-analytics/:projectid/:activityid" exact element={<Analytics/>} />
                                        <Route path="/project/forum-analytics/:projectid/:activityid" exact element={<Analytics/>} />

                                        <Route path="/project/survey-report/:projectid/:activityid" exact element={<SurveyReports/>} />
                                        <Route path="/project/online-interview-report/:projectid/:activityid" exact element={<InterviewReports/>} />
                                        <Route path="/project/inperson-interview-report/:projectid/:activityid" exact element={<InterviewReports/>} />
                                        <Route path="/project/journal-report/:projectid/:activityid" exact element={<JournalReports/>} />
                                        <Route path="/project/forum-report/:projectid/:activityid" exact element={<ForumReports/>} />
                                        <Route path="/project/inperson-forum-report/:projectid/:activityid" exact element={<InterviewReports/>} />


                                        <Route path="/user" exact element={<UserList/>} />
                                        <Route path="/add-user" exact element={<AddUser/>} />
                                        <Route path="/view-user/rewards/:userId" exact element={<UserInfo/>} />
                                        <Route path="/view-user/:userId" exact element={<UserInfo/>} />
                                        <Route path="/edit-user/:userId" exact element={<UserInfo/>} />
                                        <Route path="/view-user-activities/:userId" exact element={<UserActivities/>} />

                                        <Route path="/templates/email" exact element={<Email/>} />
                                        <Route path="/create-email-template" exact element={<CreateTemplate/>} />
                                        <Route path="/edit-email-template/:templateid" exact element={<CreateTemplate/>} />
                                        <Route path="/view-email-template/:templateid" exact element={<ViewEmail/>} />
                                        <Route path="/create-email-template/:templateid" exact element={<CreateTemplate/>} />

                                        <Route path="/create-sms-template" exact element={<CreateSmsTemplate/>} />
                                        <Route path="/edit-sms-template/:templateid" exact element={<CreateSmsTemplate/>} />
                                        <Route path="/templates/sms" exact element={<Sms/>} />
                                        <Route path="/view-sms-template/:templateid" exact element={<ViewSms/>} />
                                        <Route path="/create-sms-template/:templateid" exact element={<CreateSmsTemplate/>} />

                                        <Route path="/create-campaign-template" exact element={<CreateCampaignTemplate/>} />
                                        <Route path="/edit-campaign-template/:campaignId" exact element={<CreateCampaignTemplate/>} />
                                        <Route path="/templates/campaign" exact element={<Campaign/>} />
                                        <Route path="/view-campaign-template/:campaignId" exact element={<ViewCampaign/>} />
                                        <Route path="/create-campaign-template/:campaignId" exact element={<CreateCampaignTemplate/>} />

                                        <Route path="/interview" exact element={<Interview/>} />
                                        <Route path="/interview/add" exact element={<SheduleInterview/>} />
                                        <Route path="/chat" exact element={<Chat/>} />

                                        <Route path="/broadcast-message" exact element={<BroadcastMessages/>} />
                                        <Route path="/create-broadcast-message" exact element={<CreateBroadcastMessage/>} />
                                        <Route path="/view-broadcast-message/:msgid" exact element={<ViewBroadcastMessage/>} />

                                        <Route path="/general-forum" exact element={<GeneralForumListing/>} />
                                        <Route path="/create-generalforum" exact element={<GeneralForum/>} />
                                        <Route path="/create-generalforum/:activityid" exact element={<GeneralForum/>} />
                                        <Route path="/preview-generalforum/:activityid" exact element={<GeneralForumPreview/>} />
                                        <Route path="/edit-generalforum/:activityid" exact element={<GeneralForum/>} />
                                        <Route path="/generalforum-activity/:activityid" exact element={<GeneralForumActivity/>} />
                                        <Route path="/participant-post-activity/:activityid/:userid" exact element={<JournalActivity/>} />
                                        <Route path="/generalforum-analytics/:activityid" exact element={<Analytics/>} />

                                        <Route path="/excerpt/:excerptid" exact element={<ExcerptsPreview/>} />
                                        <Route path="/edit-excerpt/:excerptid" exact element={<ExcerptsEdit/>} />

                                        {/* <Route path="/admin/generalforum-analytics/:forumid" exact component={Stream} /> */}



                                        {/* <Route path="/admin/verify_account" exact component={AccountVerification} /> */}
                                        
                                        <Route path="/profile"  element={<MyProfile/>} />
                                        <Route path="/projects" exact element={<ProjectDetails/>} />
                                        <Route path="/settings" exact element={<Settings/>} />
                                        <Route path="/rewards" exact element={<RewardsTable/>} />
                                        <Route path="/*" exact element={<UnknownRoutes/>} />

                                        </Routes>
                                    </TransitionGroup>

                                </div>

                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        />
    )

}

export default Admin;