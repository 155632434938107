import React, { useEffect, useState } from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import styles from '../../assets/css/rstyling.module.css';
import landing_page_logo from '../../assets/images/landing_page_logo.png'
import login_bg_new from "../../assets/images/login_bg2.png";
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { forgetPassword } from '../../actions/auth_actions';





export default function AccountRecovery2() {



    const [email, setEmail] = useState('');
    const dispatch = useDispatch()
    const [err, setError] = useState({
        email: ''
    });

    const removeErr = () => {
        setError({
            email: ''
        })
    }

    const checkValidation = () => {
        removeErr()
        if (email === '') {
            setError((prevState) => ({ ...prevState, email: 'Email is required.' }))
        }
        else {
            return true
        }
    }
    const recoverPassword = () => {
        if (checkValidation()) {
            dispatch(forgetPassword({ email: email }))
                .then(response=> {
                    Swal({
                        title: "Recover email link is sent",
                        text: "Please check email",
                        icon: 'info'
                    });
                })
                .catch(err => {
                    //console.log(err.response)
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                })
        }
    }



  return (
    <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>

                        <div className="col-sm-7 col-12 bg-white align-self-center">
                            <div className='row justify-content-center'>
                                <div className={`${styles3.login_section}`}>
                                    {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                    <div className=" container" >



                                        <div className="container text-center">
                                            <img className={`${window.innerWidth < 768 ? ' d-none': styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                            <p className ={`${window.innerWidth < 768 ? ' mt-4': ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop:'50px' }}>Recover your Account.</p>
                                            <p style={{color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal'}}>Please enter your Account Email address to recover it..</p>

                                            <div>
                                            <div>
                                        <FormGroup className="mt-5">
                                            
                                                <Input type="email" onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter your Email" className={err.email ? styles.is_invalid + " mt-2" : 'valid mt-2'}  style={{paddingTop:'28px', paddingBottom:'28px'}}/>
                                            
                                            <small className="text-danger">
                                                {err.email}
                                            </small>

                                        </FormGroup>
                                    </div>

                                               


                                               
                                                <button className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} onClick={() => recoverPassword()}>
                                                    Recover Password
                                                </button>
                                                <div className='mt-4'>
                                                <a href="/login" style={{ color: '#006FA7', fontSize: '0.9rem' }}>
                                                    Login to existing account
                                                </a>
                                                </div>
                                            </div>
                                           












                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
            </div>
  )
}
