import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from '../../assets/css/styling.module.css';

import { TransitionGroup } from 'react-transition-group';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { IoArrowBackCircleSharp } from "react-icons/io5";
import Question_With_Radio_Script from '../../assets/components/survey-questions/Question_With_Radio_Script';
import Question_With_Radio2 from '../../assets/components/survey-questions/Question_With_Radio2';
import Question_With_Radio from '../../assets/components/survey-questions/Question_With_Radio';
import Question_With_Checkbox2 from '../../assets/components/survey-questions/Question_With_Checkbox2';
import Question_With_Checkbox from '../../assets/components/survey-questions/Question_With_Checkbox';
import Question_With_LongText from '../../assets/components/survey-questions/Question_With_LongText';
import Question_With_Image2 from '../../assets/components/survey-questions/Question_With_Image2';
import Question_With_Image_Text2 from '../../assets/components/survey-questions/Question_With_Image_Text2';
import Question_With_Video from '../../assets/components/survey-questions/Question_With_Video';
import Question_With_Video_Text2 from '../../assets/components/survey-questions/Question_With_Video_Text2';
import Question_With_Grid from '../../assets/components/survey-questions/Question_With_Grid';
import Question_With_Grid2 from '../../assets/components/survey-questions/Question_With_Grid2';
import Question_With_Spectrum from '../../assets/components/survey-questions/Question_With_Spectrum';
import { getRespondentActivityDetails, getActivityImg, activityQuestionsCategories, getActivityCategoryWise, getRespondentActivityDetails2, getRespondentActivityDetailsSharable } from '../../actions/activities_actions';
import { getSurveyReplies, createSurveyReply2, calculate_sec } from '../../actions/activities_reply_actions';
import { Button } from 'reactstrap';
import { BACK_SERVER_URL } from '../../config/server'
import axios from 'axios'
import CryptoJS from 'crypto-js';
import Question_With_LongText2 from '../../assets/components/survey-questions/Question_With_LongText2';
import Question_With_Spectrum2 from '../../assets/components/survey-questions/Question_With_Spectrum2';
import Question_With_Spectrum3 from '../../assets/components/survey-questions/Question_With_Spectrum3';
import Question_With_Checkbox_Script from '../../assets/components/survey-questions/Question_With_Checkbox_Script';
import Question_With_Slider2 from '../../assets/components/survey-questions/Question_With_Slider2';
import Question_With_Video2 from '../../assets/components/survey-questions/Question_With_Video2';
import SecureLS from 'secure-ls';


const ScriptedSharableSurveys = () => {

    const params = useParams();
    const ls = new SecureLS()
    const history = useNavigate()
    const dispatch = useDispatch()
  
    const userPhone = ls.get('accessToken').userPhone
    const userFullName = ls.get('accessToken').userFullName
    const currentPatientVisit = ls.get('accessToken').userTotalVisits
    const [buttonText, setButtonText] = useState('Submit');
    const [isLoading, setLoading] = useState(true)
    const [isSubmit, setSubmit] = useState(false)
    const [scriptEnabled, setScriptEnabled] = useState(false)
    const [Questions, setQuestions] = useState('');
    const [surveyDetails, setSurveyDetails] = useState({})
    const [bannerImg, setBannerImg] = useState({})
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionQuestion, set_selectedOptionQuestion] = useState('')
    const [isDisabled, setIsDisabled] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [catToDelete, setCatToDelete] = useState(false)
    const [catToReplace, setCatToReplace] = useState(false)

    const [startTime, setStartTime] = useState(null);
    const [duration, setDuration] = useState(0);
    const [selectedOption_ck, setSelectedOption_ck] = useState([]);

    const [unChecked_opt, set_unchecked_opt] = useState('')
    const [sec_question, set_questions_array] = useState([]);
    const [sec_responded_questions, set_responded_questions_array] = useState([]);
    const [sec_responded_options, set_sec_responded_options] = useState([])


    const handleRadioChange = (option, questionId) => {
        if (selectedOption_ck.length != 0) {
            setSelectedOption_ck([])
        }

        setSelectedOption(option);

        set_selectedOptionQuestion(questionId)

    };

    const handleCheckboxChange = (options, unChecked_option, questionId) => {
        if (selectedOption != '') {
            setSelectedOption('')
        }

        setSelectedOption_ck(options);
        set_unchecked_opt(unChecked_option)

        set_selectedOptionQuestion(questionId)

    };
    // Json Response
    const [surveyContent, set_surveyContent] = useState({
        content: []
    });
    // json response when pages
    const [surveyContent2, set_surveyContent2] = useState({
        pages: []
    });


    useEffect(() => {
        setStartTime(Date.now());
        return () => {

            if (startTime) {
                const endTime = Date.now();
                const surveyDuration = endTime - startTime;
                setDuration(duration + surveyDuration);
            }
        };
    }, []);



    let sec_questions = []
    let responded_sec_questions = []

    // render with scripting
    useEffect(() => {
        if (scriptEnabled === true) {
            let quesNo = 0;

            setCanSubmit(false)
            //     // Set Pages Content

            setCatToDelete(false)
            setCatToReplace(false)

            let allPageContents = [];

            surveyContent.content && surveyContent.content.map((category, categoryIndex) => {

                let pageContent = (
                    <React.Fragment key={category._id}>

                        {category.questions.map((question, quesIndex) => {
                            // Incrementing quesNo as needed
                            quesNo++;

                            switch (question.questionType) {
                                case 'radio script':
                                    return (
                                        <Question_With_Radio_Script
                                            key={question._id}
                                            questionId={question._id}
                                            id={`radio_${categoryIndex}_${quesIndex}`}
                                            questionText={`Q ${quesNo}. ${question.questionText}`}
                                            questionVideo={question.questionVideo}
                                            questionFile={question.questionFile}
                                            mediaType={question.mediaType}
                                            questionNo={quesNo}
                                            q={question.q}
                                            sec_responded_questions={sec_responded_questions}
                                            set_questions_array = {set_questions_array}
                                            set_responded_questions_array = {set_responded_questions_array}
                                            
                                            sec_questions ={sec_questions}
                                            // responded_sec_questions = {responded_sec_questions}
                                            sec = {question.sec ? question.sec : false}
                                            // sec_responded_options = {sec_responded_options}
                                            // set_sec_responded_options = {set_sec_responded_options}
                                            options={question.options}
                                            selected={question.selected}
                                            otherText={question.otherText}
                                            categoryId={category._id}
                                            isOtherAllowed={question.isOtherAllowed}
                                            required={question.required}
                                            pageIndex={categoryIndex}
                                            questionIndex={quesIndex}
                                            categoryIndex={categoryIndex}
                                            allContent={surveyContent}
                                            setContent={set_surveyContent}
                                            isAnswered={isAnswered(question, categoryIndex)}
                                            selectedOption={selectedOption}
                                            selectedOptionQuestion={selectedOptionQuestion}
                                            handleRadioChange={handleRadioChange}
                                        />
                                    );

                                case 'radio':
                                    return (
                                        <Question_With_Radio2
                                            key={question._id}
                                            id={`radio_${categoryIndex}_${quesIndex}`}
                                            questionId={question._id}
                                            questionText={`Q ${quesNo}. ${question.questionText}`}
                                            questionVideo={question.questionVideo}
                                            questionFile={question.questionFile}
                                            mediaType={question.mediaType}
                                            categoryId={category._id}
                                            options={question.options}
                                            questionNo={quesNo}
                                            q={question.q}
                                            sec_responded_questions={sec_responded_questions}
                                            set_questions_array = {set_questions_array}
                                            set_responded_questions_array = {set_responded_questions_array}
                                            
                                            sec_questions ={sec_questions}
                                            // responded_sec_questions = {responded_sec_questions}
                                            sec = {question.sec ? question.sec : false}
                                            // sec_responded_options = {sec_responded_options}
                                            // set_sec_responded_options = {set_sec_responded_options}
                                            selected={question.selected}
                                            otherText={question.otherText}
                                            isOtherAllowed={question.isOtherAllowed}
                                            required={question.required}
                                            pageIndex={categoryIndex}
                                            questionIndex={quesIndex}
                                            categoryIndex={categoryIndex}
                                            allContent={surveyContent}
                                            setContent={set_surveyContent}
                                            isAnswered={isAnswered(question, categoryIndex)}
                                        />
                                    );

                                case 'checkbox':
                                    return (
                                        <Question_With_Checkbox2
                                            key={question._id}
                                            id={`check_${categoryIndex}_${quesIndex}`}
                                            questionText={`Q ${quesNo}. ${question.questionText}`}
                                            questionVideo={question.questionVideo}
                                            questionFile={question.questionFile}
                                            categoryId={category._id}
                                            mediaType={question.mediaType}
                                            questionNo={quesNo}
                                            q={question.q}
                                            options={question.options}
                                            selected={question.selected}
                                            otherText={question.otherText}
                                            isOtherAllowed={question.isOtherAllowed}
                                            required={question.required}
                                            pageIndex={categoryIndex}
                                            questionIndex={quesIndex}
                                            categoryIndex={categoryIndex}
                                            allContent={surveyContent}
                                            setContent={set_surveyContent}
                                            isAnswered={isAnswered(question, categoryIndex)}
                                        />
                                    );

                                case 'checkbox script':
                                    return (
                                        <Question_With_Checkbox_Script
                                            key={question._id}
                                            questionId={question._id}
                                            id={`check_${categoryIndex}_${quesIndex}`}
                                            questionText={`Q ${quesNo}. ${question.questionText}`}
                                            questionVideo={question.questionVideo}
                                            categoryId={category._id}
                                            questionFile={question.questionFile}
                                            mediaType={question.mediaType}
                                            questionNo={quesNo}
                                            q={question.q}
                                            options={question.options}
                                            selected={question.selected}
                                            otherText={question.otherText}
                                            isOtherAllowed={question.isOtherAllowed}
                                            required={question.required}
                                            pageIndex={categoryIndex}
                                            questionIndex={quesIndex}
                                            categoryIndex={categoryIndex}
                                            allContent={surveyContent}
                                            setContent={set_surveyContent}
                                            isAnswered={isAnswered(question, categoryIndex)}
                                            selectedOption={selectedOption_ck}
                                            selectedOptionQuestion={selectedOptionQuestion}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                    );

                                case 'long text':

                                    return <Question_With_LongText2
                                        key={question._id}
                                        id={`long_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ` + question.questionText}
                                        questionFile={question.questionFile}
                                        questionVideo={question.questionVideo}
                                        categoryId={category._id}
                                        mediaType={question.mediaType}
                                        questionNo={quesNo}
                                        q={question.q}
                                        answer={question.answer}
                                        textLimit={question.textLimit}
                                        required={question.required}
                                        pageIndex={0}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />

                                case 'grid':

                                    return <Question_With_Grid2
                                        key={question._id}
                                        id={'grid' + CryptoJS.lib.WordArray.random(5)}
                                        questionText={`Q ${quesNo}. ` + question.questionText}
                                        questionVideo={question.questionVideo}
                                        questionFile={question.questionFile}
                                        categoryId={category._id}
                                        mediaType={question.mediaType}
                                        rows={question.rows}
                                        columns={question.columns}
                                        selected={question.selected}
                                        q={question.q}
                                        questionNo={quesNo}
                                        comment={question.comment}
                                        isComment={question.isAdditionalComment}
                                        categoryIndex={categoryIndex}
                                        uniqueSelected={question.isUniqueSelected}
                                        required={question.required}
                                        pageIndex={0}
                                        questionIndex={quesIndex}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />

                                case 'spectrum':

                                    return <Question_With_Spectrum3
                                        key={question._id}
                                        id={`spectrum_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ${question.questionText}`}
                                        questionVideo={question.questionVideo}
                                        questionFile={question.questionFile}
                                        mediaType={question.mediaType}
                                        categoryId={category._id}
                                        options={question.options}
                                        questionNo={quesNo}
                                        q={question.q}
                                        selected={question.selected}
                                        otherText={question.otherText}
                                        isOtherAllowed={question.isOtherAllowed}
                                        required={question.required}
                                        pageIndex={categoryIndex}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />

                                case 'slider':

                                    return <Question_With_Slider2
                                        key={question._id}
                                        id={`slider_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ${question.questionText}`}
                                        questionVideo={question.questionVideo}
                                        questionFile={question.questionFile}
                                        mediaType={question.mediaType}
                                        categoryId={category._id}
                                        options={question.options}
                                        questionNo={quesNo}
                                        q={question.q}
                                        selected={question.selected}
                                        otherText={question.otherText}
                                        isOtherAllowed={question.isOtherAllowed}
                                        required={question.required}
                                        pageIndex={categoryIndex}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />

                                case 'video':

                                    return <Question_With_Video2
                                        key={question._id}
                                        id={`video_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ${question.questionText}`}
                                        questionFile={question.questionFile}
                                        questionVideo={question.questionVideo}
                                        mediaType={question.mediaType}
                                        categoryId={category._id}
                                        video={question.video}
                                        q={question.q}
                                        questionNo={quesNo}
                                        pageIndex={categoryIndex}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        filesLimit={question.filesLimit}
                                        filesSize={question.filesSize}
                                        comment={question.comment}
                                        isComment={question.isComment}
                                        required={question.required}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />

                                case 'video text':

                                    return <Question_With_Video_Text2
                                        key={question._id}
                                        id={`videoText_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ${question.questionText}`}
                                        questionFile={question.questionFile}
                                        questionVideo={question.questionVideo}
                                        mediaType={question.mediaType}
                                        categoryId={category._id}
                                        video={question.video}
                                        q={question.q}
                                        questionNo={quesNo}
                                        pageIndex={categoryIndex}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        filesLimit={question.filesLimit}
                                        filesSize={question.filesSize}
                                        answer={question.answer}
                                        comment={question.comment}
                                        isComment={question.isComment}
                                        required={question.required}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />



                                case 'image':

                                    return <Question_With_Image2
                                        key={question._id}
                                        id={`image_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ${question.questionText}`}
                                        questionFile={question.questionFile}
                                        questionVideo={question.questionVideo}
                                        mediaType={question.mediaType}
                                        image={question.image}
                                        categoryId={category._id}
                                        q={question.q}
                                        questionNo={quesNo}
                                        pageIndex={categoryIndex}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        filesLimit={question.filesLimit}
                                        filesSize={question.filesSize}
                                        comment={question.comment}
                                        isComment={question.isComment}
                                        required={question.required}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />

                                case 'image text':

                                    return <Question_With_Image_Text2
                                        key={question._id}
                                        id={`imageText_${categoryIndex}_${quesIndex}`}
                                        questionText={`Q ${quesNo}. ${question.questionText}`}
                                        questionFile={question.questionFile}
                                        questionVideo={question.questionVideo}
                                        mediaType={question.mediaType}
                                        image={question.image}
                                        categoryId={category._id}
                                        q={question.q}
                                        questionNo={quesNo}
                                        pageIndex={categoryIndex}
                                        questionIndex={quesIndex}
                                        categoryIndex={categoryIndex}
                                        filesLimit={question.filesLimit}
                                        filesSize={question.filesSize}
                                        comment={question.comment}
                                        answer={question.answer}
                                        isComment={question.isComment}
                                        required={question.required}
                                        allContent={surveyContent}
                                        setContent={set_surveyContent}
                                        isAnswered={isAnswered(question, categoryIndex)}
                                    />


                                default:
                                    return null;
                            }
                        })}
                    </React.Fragment>
                );

                allPageContents.push(pageContent);

                setQuestions(allPageContents);
                return pageContent;
            });

        }
    }, [surveyContent, catToDelete === true, catToReplace === true, isSubmit]);


    



    // useEffect(()=>{
       
    
    // if(allSecQuestionsAnswered === true){
    //     dispatch(calculate_sec(new_options_array, params.UserId))
    //     .then((response) =>{
    
    //     })
    // }
    // },[allSecQuestionsAnswered === true, new_options_array])

    const getSurvey = () => {

        dispatch(getActivityImg(params.ActivityId))
            .then((response) => {
                try {
                    //console.log('banner img in respondant side survey form->', response.payload.content.fileData)
                    setBannerImg(response.payload.content.fileData)
                } catch (err) {
                    // console.log('img not available') 
                }

            }).catch(err => {
                //console.log(err)
            })


        //setLoading(true)
        setTimeout(() => {


            if ((selectedOption === '' && selectedOptionQuestion === '') || (selectedOption_ck == [] && selectedOptionQuestion === '')) {

                dispatch(activityQuestionsCategories(params.ActivityId))
                    .then((response) => {

                        //set_surveyContent(response.payload.finalCategory[0])
                        set_surveyContent(response.payload)
                        //setLoading(false)


                    })
            }


            else if ((selectedOption != '' && selectedOptionQuestion != '') || (selectedOption_ck != [] && selectedOptionQuestion != '')) {
              
                dispatch(getActivityCategoryWise(params.ActivityId, selectedOption, selectedOption_ck, unChecked_opt, selectedOptionQuestion))
                    .then((response) => {

                        // if() case when:
                        // 1) either that option does not have matching category
                        // 2) un-render that category if some other option selected
                        if (response.payload.message === 'Relevent category not found' || response.payload.message === 'Relevant ck scripted cats not found') {

                            let questionIdToFind = response.payload.content
                            let categoryFound

                            surveyContent.content.map((category) => {
                                let conditionQuestion = category.conditionQuestion
                                if (conditionQuestion === questionIdToFind) {
                                    categoryFound = category
                                }


                            }
                            )



                            let findIndex = surveyContent.content.indexOf(categoryFound)
                            //console.log('findIndex to delete ->', findIndex)


                            if (findIndex != -1) {
                                let deleteCat = surveyContent.content.splice(findIndex, 1) // incase if only one category should unrender and not the children categories after that. This is for when a radio button is clicked which has no category available

                                setCatToDelete(true)

                            }



                        }
                        else if (response.payload.message === 'Relevent category found to be deleted in checkbox script') {

                            const cat_to_delete = response.payload.content
                            let categoryFound;
                            surveyContent.content.map((category) => {

                                if (cat_to_delete === category._id) {
                                    categoryFound = category
                                }


                            }
                            )
                            let findIndex = surveyContent.content.indexOf(categoryFound)

                            if (findIndex != -1) {
                                let deleteCat = surveyContent.content.splice(findIndex, 1) // incase if only one category should unrender and not the children categories after that. This case is for when one checkbox is unchecked after selected multiple checkboxes

                                setCatToDelete(true)

                            }


                        }
                        else if (response.payload.message === 'Relevent category not found to be deleted in checkbox script') {

                        }
                        // case when relevant category matched with option
                        else {
                            const matchedCategory = response.payload.content;
                            const matchedCategories = response.payload.content

                            // when matched category is already present in surveyContent with same category ID so we do nothing





                            //     const existingIndexCase1 = surveyContent.content.findIndex(category => category._id === matchedCategory._id);
                            //     // when matched child category is already present in surveyContent with same conditionQuestion so we replace it.This is for multiple radio options each with multiple child categories 
                            //     const existingIndexCase2 = surveyContent.content.findIndex(category => category.conditionQuestion === matchedCategory.conditionQuestion)
                            //     if (existingIndexCase2 !== -1) {
                            //         // replace matched category with sameIndexCat
                            //         surveyContent.content[existingIndexCase2] = matchedCategory

                            //         // add this logic to delete all children categories after the first child category which was replaced
                            //         surveyContent.content.splice(existingIndexCase2 + 1)

                            //         setCatToReplace(true)


                            //     }
                            //     if (existingIndexCase1 !== -1) {
                            //         console.log('came here for -1')
                            //     } else {

                            //         // If the category doesn't exist, add it to the array just after parent cat
                            //         if (existingIndexCase2 === -1) {


                            //             console.log('matched cat ->', matchedCategory)
                            //            // let newConditionOption = matchedCategory.ConditionOption
                            //             let newConditionQuestion = matchedCategory.conditionQuestion

                            //             // get relevant parent category from survey content array                                
                            //             const getCat = surveyContent.content.map(
                            //                 (cat) => cat.questions.map((question) => {

                            //                     if (newConditionQuestion === question._id) {

                            //                         return cat
                            //                     }

                            //                 }
                            //                 ))
                            //             const final = getCat
                            //                 .filter(array => array && array.length > 0)
                            //                 .map(array => array.find(obj => obj !== undefined));
                            //             const parentCat = final.filter(x => x != undefined)[0]

                            //             const Index_of_Parent_cat = surveyContent.content.indexOf(parentCat)


                            //             //set_surveyContent(prevState => ({ content: [...prevState.content, matchedCategory] }))
                            //             set_surveyContent(prevState => {
                            //                 // Find the index after which you want to insert the new object
                            //                 let index = Index_of_Parent_cat

                            //                 // Split the array into two parts
                            //                 let before = prevState.content.slice(0, index + 1); // Elements before the index
                            //                 let after = prevState.content.slice(index + 1);     // Elements after the index

                            //                 // Insert the new category just after parent
                            //                 let newContent = [...before, matchedCategory, ...after];

                            //                 // Return the updated state
                            //                 return { content: newContent };
                            //             });





                            //         }
                            //     }
                            // }








                            matchedCategories.forEach(matchedCategory => {
                                // when matched category is already present in surveyContent with same category ID so we do nothing
                                const existingIndexCase1 = surveyContent.content.findIndex(category => category._id === matchedCategory._id);

                                // when matched child category is already present in surveyContent with same conditionQuestion so we replace it. This is for multiple radio script options each with multiple categories. In case of checkbox script, this logic will not be applied
                                const existingIndexCase2 = surveyContent.content.findIndex(category =>
                                    category.conditionQuestion === matchedCategory.conditionQuestion

                                );



                                if (existingIndexCase2 !== -1 && matchedCategory.conditionQuestionType === 'radio script') {
                                    // replace matched category with sameIndexCat
                                    surveyContent.content[existingIndexCase2] = matchedCategory;

                                    // add this logic to delete all children categories after the first child category which was replaced
                                    surveyContent.content.splice(existingIndexCase2 + 1);

                                    setCatToReplace(true);
                                }
                                else if (existingIndexCase2 !== -1 && matchedCategory.conditionQuestionType === 'checkbox script' && existingIndexCase1 === -1) {



                                    let newConditionQuestion = matchedCategory.conditionQuestion;

                                    // get relevant parent category from survey content array                                
                                    const getCat = surveyContent.content.map(cat =>
                                        cat.questions.map(question => {
                                            if (newConditionQuestion === question._id) {
                                                return cat;
                                            }
                                        })
                                    );
                                    const final = getCat
                                        .filter(array => array && array.length > 0)
                                        .map(array => array.find(obj => obj !== undefined));
                                    const parentCat = final.filter(x => x != undefined)[0];

                                    const Index_of_Parent_cat = surveyContent.content.indexOf(parentCat);

                                    set_surveyContent(prevState => {
                                        // Find the index after which you want to insert the new object
                                        let index = Index_of_Parent_cat;

                                        // Split the array into two parts
                                        let before = prevState.content.slice(0, index + 1); // Elements before the index
                                        let after = prevState.content.slice(index + 1);     // Elements after the index

                                        // Insert the new category just after parent
                                        let newContent = [...before, matchedCategory, ...after];

                                        // Return the updated state
                                        return { content: newContent };
                                    });

                                }

                                if (existingIndexCase1 !== -1) {

                                } else {
                                    // If the category doesn't exist, add it to the array just after parent cat
                                    if (existingIndexCase2 === -1) {

                                        let newConditionQuestion = matchedCategory.conditionQuestion;

                                        // get relevant parent category from survey content array                                
                                        const getCat = surveyContent.content.map(cat =>
                                            cat.questions.map(question => {
                                                if (newConditionQuestion === question._id) {
                                                    return cat;
                                                }
                                            })
                                        );
                                        const final = getCat
                                            .filter(array => array && array.length > 0)
                                            .map(array => array.find(obj => obj !== undefined));
                                        const parentCat = final.filter(x => x != undefined)[0];

                                        const Index_of_Parent_cat = surveyContent.content.indexOf(parentCat);

                                        set_surveyContent(prevState => {
                                            // Find the index after which you want to insert the new object
                                            let index = Index_of_Parent_cat;

                                            // Split the array into two parts
                                            let before = prevState.content.slice(0, index + 1); // Elements before the index
                                            let after = prevState.content.slice(index + 1);     // Elements after the index

                                            // Insert the new category just after parent
                                            let newContent = [...before, matchedCategory, ...after];

                                            // Return the updated state
                                            return { content: newContent };
                                        });
                                    }
                                }
                            });

                        }


                    })
            }
        }, 100);

        // currently for survey title and description        
        dispatch(getRespondentActivityDetailsSharable(params.ActivityId))
            .then((response) => {


                let script = response.payload.content.survey.scriptEnabled
                setScriptEnabled(script)
                setSurveyDetails(response.payload.content)

                set_surveyContent2(response.payload.content.survey)
                setLoading(false)


            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {

        getSurvey()
    }, [selectedOption, selectedOptionQuestion, selectedOption_ck])

    const isAnswered = (question, categoryIndex) => {
     
        if (isSubmit) {


            if (question.questionType === "radio" && question.selected && question.selected !== '')
                return true;

            else if (question.questionType === "radio script" && question.selected && question.selected !== '')
                return true;

            else if (question.questionType === "checkbox" && question.selected && question.selected.length > 0)
                return true;

            else if (question.questionType === "checkbox script" && question.selected && question.selected.length > 0)
                return true;

            else if (question.questionType === "grid" && question.selected && question.selected.length === question.rows.length)
                return true;

            else if (question.questionType === "long text" && question.answer && question.answer !== '')
                return true;

            else if (question.questionType === "image" && question.image && question.image.length > 0)
                return true;

            else if (question.questionType === "video" && question.video && question.video.length > 0)
                return true;

            else if (question.questionType === "image text" && question.image && (question.image.length > 0 && question.answer !== ''))
                return true;

            else if (question.questionType === "video text" && question.video && (question.video.length > 0 && question.answer !== ''))
                return true;

            else if (question.questionType === "spectrum" && question.selected && question.selected !== '')
                return true;
            else if (question.questionType === "slider" && question.selected && question.selected !== '')
                return true;

            else
                return false;

        } else {

            return true;
        }
    }



    let new_options_array = []
   

  
    surveyContent && surveyContent.content && surveyContent.content.map( (cat) => {
        
       

        cat.questions.map((question) => {
            if (sec_question.includes(question._id)) {
                if (!new_options_array.includes(question.selected)) {


                    new_options_array.push(question.selected)
                }
            }
        })
    }
    )
  

    const allSecQuestionsAnswered = sec_question.length > 0 &&
    sec_responded_questions.length > 0 &&
    sec_question.every(questionId => sec_responded_questions.includes(questionId));


useEffect(() => {


    if (allSecQuestionsAnswered === true) {
      
        dispatch(calculate_sec(new_options_array, params.UserId))
            .then((response) => {

            })
    }
}, [allSecQuestionsAnswered === true, new_options_array])


    const isValid = () => {
        let notCompleted = false;
        let questionss = []

        surveyContent.content.map(obj =>
            obj.questions.map(question =>
                questionss.push(question)
            )
        )

        //const questions = surveyContent.content[0].questions;

        // Check Requried question validation
        for (let i = 0; i < questionss.length; i++) {

            if (questionss[i].required) {

                if (questionss[i].questionType === 'radio' && (!questionss[i].selected || questionss[i].selected === '')) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'radio script' && (!questionss[i].selected || questionss[i].selected === '')) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'checkbox' && (!questionss[i].selected || questionss[i].selected.length === 0)) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'checkbox script' && (!questionss[i].selected || questionss[i].selected.length === 0)) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'grid' && (!questionss[i].selected || questionss[i].selected.length < questionss[i].rows.length)) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'long text' && (!questionss[i].answer || questionss[i].answer === '')) {
                   
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'image' && (!questionss[i].image || questionss[i].image.length === 0)) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'video' && (!questionss[i].video || questionss[i].video.length === 0)) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'image text' && (!questionss[i].image || (questionss[i].image.length === 0 || questionss[i].answer === ''))) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'video text' && (!questionss[i].video || (questionss[i].video.length === 0 || questionss[i].answer === ''))) {
                    notCompleted = true;
                    break;
                }

                else if (questionss[i].questionType === 'spectrum' && (!questionss[i].selected || questionss[i].selected === '')) {
                    notCompleted = true;
                    break;
                }
                else if (questionss[i].questionType === 'slider' && (!questionss[i].selected || questionss[i].selected === '')) {
                    notCompleted = true;
                    break;
                }

                else {
                    notCompleted = false;
                }

            }

        }
        // check validtion passed then proceed next step
        return !notCompleted

    }

    // const handleClick = () => {
    //     if ( !canSubmit) {
    //       // First click: disable the button and start the timer
    //       setIsDisabled(true);
    //       setTimeout(() => {
    //         setIsDisabled(false);
    //         setCanSubmit(true);
    //       }, 5000); // 2000 milliseconds = 2 seconds
    //     } else if (canSubmit) {
    //       // Subsequent click after 2 seconds: submit data
    //       onSubmit();
    //       setCanSubmit(false); // Reset to prevent further submissions without delay
    //     }
    //   };
    const handleClick = () => {
     
        if (!canSubmit) {
            // First click: change text to "Checking..." and start the timer
           
            setButtonText('Please wait...');
            setTimeout(() => {
                setButtonText('Submit');
                setCanSubmit(true);
            }, 2500); // 2000 milliseconds = 2 seconds
        } else {
            // Subsequent click after 2 seconds: submit data
            onSubmit();
            setCanSubmit(false); // Reset to prevent further submissions without delay
        }
    };

    const onSubmit = () => {
        setSubmit(true)
        const endTime = Date.now();
        const surveyDuration = endTime - startTime;
        const totalDuration = duration + surveyDuration; // Calculate the total duration
        const minutes = Math.floor(totalDuration / 60000);
        const seconds = ((totalDuration % 60000) / 1000).toFixed(0);


        const LOI = `${minutes} min, ${seconds} sec`



        // setSubmit(true)
        // let activity = params.ActivityId
        // let url = `${BACK_SERVER_URL}api/replies/activityPoints/${activity}`;

        // const request = axios.get(url)
        //     .then(response => response.data);

        if (isValid()) {
            

            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            const formData = new FormData()
            formData.append('activity', params.ActivityId)
            formData.append('activityType', 'Quick Survey')
            formData.append('loi', LOI)
            formData.append('user', params.UserId)



            for (let i = 0; i < surveyContent.content.length; i++) {
                for (let j = 0; j < surveyContent.content[i].questions.length; j++) {

                    formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][questionId]`, surveyContent.content[i].questions[j]._id)


                    if (surveyContent.content[i].questions[j].questionType === 'radio' || surveyContent.content[i].questions[j].questionType === 'radio script' || surveyContent.content[i].questions[j].questionType === 'spectrum' || surveyContent.content[i].questions[j].questionType === 'slider') {


                        formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][selectedOptions][0]`, surveyContent.content[i].questions[j].selected)

                    }
                    else if (surveyContent.content[i].questions[j].questionType === 'checkbox' || surveyContent.content[i].questions[j].questionType === 'checkbox script') {

                        surveyContent.content[i].questions[j].selected && surveyContent.content[i].questions[j].selected.length > 0 &&
                            surveyContent.content[i].questions[j].selected.map((value, index) => {
                                formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][selectedOptions][${index}]`, value)
                            })

                    } else if (surveyContent.content[i].questions[j].questionType === 'long text') {

                        formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseText]`, surveyContent.content[i].questions[j].answer)

                    }

                    else if (surveyContent.content[i].questions[j].questionType === 'image') {

                        for (let k = 0; k < surveyContent.content[i].questions[j].image.length; k++) {
                            formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseFiles][${k}]`, surveyContent.content[i].questions[j].image[k])

                        }

                    }
                    else if (surveyContent.content[i].questions[j].questionType === 'video') {

                        for (let k = 0; k < surveyContent.content[i].questions[j].video.length; k++) {
                            formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseFiles][${k}]`, surveyContent.content[i].questions[j].video[k])

                        }

                    }

                    else if (surveyContent.content[i].questions[j].questionType === 'image text') {

                        for (let k = 0; k < surveyContent.content[i].questions[j].image.length; k++) {
                            formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseFiles][${k}]`, surveyContent.content[i].questions[j].image[k])

                        }

                        formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseText]`, surveyContent.content[i].questions[j].answer)

                    }

                    else if (surveyContent.content[i].questions[j].questionType === 'video text') {

                        for (let k = 0; k < surveyContent.content[i].questions[j].video.length; k++) {
                            formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseFiles][${k}]`, surveyContent.content[i].questions[j].video[k])

                        }

                        formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][responseText]`, surveyContent.content[i].questions[j].answer)

                    }


                    else if (surveyContent.content[i].questions[j].questionType === 'grid' && surveyContent.content[i].questions[j].rows.length > 0) {
                        //     console.log('coming here 8')
                        // if (surveyContent.pages[i].questions[j].isUniqueSelected) {
                        surveyContent.content[i].questions[j].rows.map((row, rowIndex) => {
                            formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][gridResponse][${rowIndex}][row]`, row.text)

                            surveyContent.content[i].questions[j].selected.map((selected, selectedIndex) => {
                                if (selected.row === rowIndex + 1)
                                    formData.append(`pages[0][questions][${surveyContent.content[i].questions[j].q}][gridResponse][${rowIndex}][column]`, surveyContent.content[i].questions[j].columns[selected.column - 1].text)
                            })

                        })
                    }

                    // }
                }

            }


            dispatch(createSurveyReply2(formData))
                .then((response) => {
                    Swal.stopLoading()
                    Swal.close()
                    //getReplies(response.payload.content._id)
                    let surveyId = response.payload.content.activity
                    let responseId = response.payload.content._id
                    let userId = response.payload.content.author
                    if (response.payload.message === 'Replies Created') {
                        Swal({
                            title: "Submitted",
                            text: 'Congratulations, Your Form has been Submitted',
                            icon: 'success'
                        }).then(() => {

                            // for behbud clinic audience 
                            if (params.ActivityId === '671615207cb78802148dfb58' || params.ActivityId === '671615207cb78802148dfb59') {
                                history(`/sharable-survey-minimal/671615207cb78802148dfb58/minimal`)

                            }
                            else {
                                // for everyone else
                                history(`/thank-you-message/${params.ActivityId}`)
                                //history(`/sharable-survey-analysis/${responseId}/${surveyId}/${userId}`)
                            }
                        }


                        )
                    }
                })

                .catch(err => {
                    console.log(err)
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })

        }


    }













    return (

        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <>

                    {/* <Back /> */}
                    {/* <Button onClick={() => history('/respondent')} className='my-4 mx-4' style={{ backgroudColor: '#0069AB' }}><IoArrowBackCircleSharp size={20} /> Back</Button> */}

                    <div className="_survey-steps_">
                        <div className="container-fluid pt-3">

                            {/* <div className='row _survey-steps_'> */}

                            <div className="mb-3">
                                {/* Image Display */}
                                {surveyDetails.bannerImage &&

                                    // Image Display
                                    <div>
                                        <div className="position-relative">
                                            <img src={`data:${bannerImg.type} ;base64, ${bannerImg.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px', width: 'max-content' }} alt="img" />
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* </div> */}

                            {/* Heading */}
                            <h5 style={{ color: "#000", fontWeight: 500, fontSize: "20px" }} className="mt-5">
                                {surveyDetails.name}
                            </h5>
                            <div className="my-3">
                                <div className={styles.card_border}>
                                    <div className={styles.card_border_body + " py-4"}>
                                        <p style={{ color: '#000', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }} className="mb-0">
                                            {surveyDetails.description}

                                            {params.ActivityId === '671615207cb78802148dfb58' || params.ActivityId === '671615207cb78802148dfb59' ?
                                                <div className='row my-2'>


                                                    <div className='col-12 text-primary'>
                                                        {`Patient Full Name: ${userFullName}`}

                                                    </div>
                                                    <div className='col-12 text-primary'>
                                                        {`Patient Phone/Cnic: ${userPhone}`}
                                                    </div>
                                                    <div className='col-12 text-primary'>
                                                        {`Patient Current Visit: ${currentPatientVisit + 1}`}
                                                    </div>
                                                </div> : <></>
                                            }

                                        </p>

                                    </div>
                                </div>
                            </div>

                            {/* Heading */}
                            {/* <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="pt-3 mb-0">
                                Questions
                            </h5> */}

                            {/* Questions */}
                            {Questions}


                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button className={"float-right btn-hover-shine px-5 py-2 mx-4 my-4"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }} onClick={handleClick}>
                                    {buttonText}
                                </Button>
                            </div>

                        </div>
                    </div>

                </>



            </LoadingOverlay >


        </TransitionGroup>

    )

}



export default ScriptedSharableSurveys;