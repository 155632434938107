import React, { useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Create_Image_Section from '../../../../assets/components/create-section/Create_Image_Section';
import Create_Video_Section from '../../../../assets/components/create-section/Create_Video_Section';
import Create_File_Section from '../../../../assets/components/create-section/Create_File_Section';
import Create_List_Section from '../../../../assets/components/create-section/Create_List_Section';
import IntroIcon from '../../../../assets/images/create_icon.png'
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addProjectIntro, editProjectIntro, getProjectIntro } from '../../../../actions/project_actions';
import Swal from 'sweetalert'

const EditIntro = (props) => {

    const dispatch = useDispatch()
    const params = useParams()
    const history = useNavigate()
    const projectState = useSelector(state => state.Project)
    const [isEdit, setEditMode] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [projectDescription, setDescription] = useState()
    const [selected, setSelection] = useState('image')
    const [column, setColumn] = useState([
        { Name: 'ID', Type: 'number' },
        { Name: "Username", Type: 'string' },
        { Name: "Last Name", Type: 'string' },
        { Name: "Last Visit", Type: 'date' }
    ]);

    const [projectIntro, setProjectIntro] = useState({})


    useEffect(() => {
        dispatch(getProjectIntro({ projectId: params.projectid }))
            .then(response => {
                setProjectIntro(response.payload.content)
                setDescription(response.payload.content.description)
                setEditMode(true)
                setLoading(false)
            })
            .catch(err => {
                //console.log(err)
                setLoading(false)

            })
    }, [])

    // For adding new Sections
    const addSection = () => {

        switch (selected) {
            case 'image':
                setProjectIntro({
                    ...projectIntro,
                    imagesSection: {
                        images: [],
                        isVisible: '',
                        limit: 1
                    }
                })

                break;

            case 'video':
                setProjectIntro({
                    ...projectIntro,
                    videosSection: {
                        videos: [],
                        isVisible: '',
                        limit: 1
                    }
                })

                break;

            case 'file':
                setProjectIntro({
                    ...projectIntro,
                    filesSection: {
                        files: [],
                        isVisible: '',
                        limit: 1
                    }
                })

                break;

            case 'list':
                setProjectIntro({
                    ...projectIntro,
                    participantsSection: {
                        participants: true,
                        isVisible: ''
                    }
                })

                break;
            default:
                break;
        }

    }

    const onSave = (e) => {
        e.preventDefault()
        const formData = new FormData()
        projectDescription && formData.append('description', projectDescription)
        formData.append('projectId', params.projectid)
        
        if (projectIntro.imagesSection) {
            projectIntro.imagesSection.isVisible && formData.append(`imagesSection[isVisible]`, projectIntro.imagesSection.isVisible)
            projectIntro.imagesSection.limit && formData.append(`imagesSection[limit]`, projectIntro.imagesSection.limit)

            if (projectIntro.imagesSection.images.length > 0) {
                for (let i = 0; i < projectIntro.imagesSection.images.length; i++) {
                    formData.append(`imagesSection[images][${i}]`, projectIntro.imagesSection.images[i])
                }
            } else {
                formData.append(`imagesSection[images]`, [])
            }
        }
        if (projectIntro.videosSection) {
            projectIntro.videosSection.isVisible && formData.append(`videosSection[isVisible]`, projectIntro.videosSection.isVisible)
            projectIntro.videosSection.limit && formData.append(`videosSection[limit]`, projectIntro.videosSection.limit)

            if (projectIntro.videosSection.videos.length > 0) {
                for (let i = 0; i < projectIntro.videosSection.videos.length; i++) {
                    formData.append(`videosSection[videos][${i}]`, projectIntro.videosSection.videos[i])
                }
            } else {
                formData.append(`videosSection[videos]`, [])
            }
        }
        if (projectIntro.filesSection) {
            projectIntro.filesSection.isVisible && formData.append(`filesSection[isVisible]`, projectIntro.filesSection.isVisible)
            projectIntro.filesSection.limit && formData.append(`filesSection[limit]`, projectIntro.filesSection.limit)

            if (projectIntro.filesSection.files.length > 0) {
                for (let i = 0; i < projectIntro.filesSection.files.length; i++) {
                    formData.append(`filesSection[files][${i}]`, projectIntro.filesSection.files[i])
                }
            } else {
                formData.append(`filesSection[files]`, [])
            }
        }
        if (projectIntro.participantsSection) {
            projectIntro.participantsSection.isVisible && formData.append(`participantsSection[isVisible]`, projectIntro.participantsSection.isVisible)
            projectIntro.participantsSection.participants && formData.append(`participantsSection[participants]`, projectIntro.participantsSection.participants)
        }

        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        if (isEdit) {
            dispatch(editProjectIntro(projectState.projectIntro._id, formData))
                .then(response => {
                    Swal.stopLoading();
                    Swal.close();
                    Swal({
                        title: response.payload.title ? response.payload.title : "Added",
                        text: response.payload.message ? response.payload.message : 'Project Introduction added',
                        icon: 'success'
                    }).then(() => {
                        history('/admin/project/intro/' + params.projectid)
                    })
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    }).then(() => {
                        Swal.stopLoading();
                        Swal.close();
                    })
                })
        } else {
            dispatch(addProjectIntro(formData))
                .then(response => {
                    Swal.stopLoading();
                    Swal.close();
                    Swal({
                        title: response.payload.title ? response.payload.title : "Added",
                        text: response.payload.message ? response.payload.message : 'Project Introduction added',
                        icon: 'success'
                    }).then(() => {
                        history('/admin/project/intro/' + params.projectid)
                    })
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    }).then(() => {
                        Swal.stopLoading();
                        Swal.close();
                    })
                })
        }


    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="create_header">
                <PageHeader
                    heading="Edit Introduction" img={IntroIcon}
                />
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className='_survey-steps_ mt-3'>
                <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Description</h6>
                <div className="text_editor">
                    {!isLoading &&
                        <CKEditor
                            initData={projectDescription}
                            onChange={(e) => setDescription(e.editor.getData())}
                            editor={ClassicEditor}
                            config={{
                                toolbar: [{
                                    name: 'document',
                                    items: ['Print']
                                },
                                {
                                    name: 'clipboard',
                                    items: ['Undo', 'Redo']
                                },
                                {
                                    name: 'styles',
                                    items: ['Format', 'Font', 'FontSize']
                                },
                                {
                                    name: 'colors',
                                    items: ['TextColor', 'BGColor']
                                },
                                {
                                    name: 'align',
                                    items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                                },
                                    '/',
                                {
                                    name: 'basicstyles',
                                    items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
                                },
                                {
                                    name: 'links',
                                    items: ['Link', 'Unlink']
                                },
                                {
                                    name: 'paragraph',
                                    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                                },
                                {
                                    name: 'insert',
                                    items: ['Image', 'Table']
                                },
                                {
                                    name: 'tools',
                                    items: ['Maximize']
                                },
                                {
                                    name: 'editing',
                                    items: ['Scayt']
                                }
                                ],
                                extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
                                extraPlugins: 'print,format,font,colorbutton,justify,uploadimage'
                            }}

                        />
                    }
                </div>

                    {/* Images Section */}
                {
                    projectIntro.imagesSection && projectIntro.imagesSection.limit > 0 &&
                    <Create_Image_Section
                        allContent={projectIntro}
                        setContent={setProjectIntro}
                        limit={projectIntro.imagesSection && projectIntro.imagesSection.limit}
                    />
                }

                {/* Video Section */}
                {
                    projectIntro.videosSection && projectIntro.videosSection.limit > 0 &&
                    <Create_Video_Section
                        allContent={projectIntro}
                        setContent={setProjectIntro}
                        limit={projectIntro.videosSection && projectIntro.videosSection.limit}
                    />
                }

                {/* Files Section */}
                {
                    projectIntro.filesSection && projectIntro.filesSection.limit > 0 &&
                    <Create_File_Section
                        allContent={projectIntro}
                        setContent={setProjectIntro}
                        limit={projectIntro.filesSection && projectIntro.filesSection.limit}
                    />
                }

                {/* Participants Section */}
                {
                    projectIntro.participantsSection && projectIntro.participantsSection.participants === true &&
                    <Create_List_Section
                        allContent={projectIntro}
                        setContent={setProjectIntro}
                    />
                }

            </div>


            {/* Add a Question */}
            <div className="row my-3">

                <div className="col-12 mb-1">
                    <label style={{ color: '#666', fontSize: "0.95rem", fontWeight: '400' }}>Section</label>
                </div>

                <div className="col-9 pr-0">

                    {/* Question Type */}
                    <div className="form-group mb-0">
                        {
                            // isEdit ?
                            // <select className="form-control" onChange={(e) => setSelection(e.target.value)}>
                            //     {projectIntro.imagesSection ? (projectIntro.imagesSection.images.length === 0 && <option value="image">Image</option>) : <option value="image">Image</option>}
                            //     {projectIntro.filesSection ? (projectIntro.filesSection.files.length === 0 && <option value="file">File</option>) : <option value="file">File</option>}
                            //     {projectIntro.videosSection ? (projectIntro.videosSection.videos.length > 0 && <option value="video">Video</option>) : <option value="video">Video</option>}
                            //     {projectIntro.participantsSection ? (projectIntro.participantsSection.participants === true && <option value="list">Participants List</option>) :  <option value="list">Participants List</option>}
                            // </select>
                            // :
                            <select className="form-control" onChange={(e) => setSelection(e.target.value)}>
                                <option value="image">Image</option>
                                <option value="file">File</option>
                                <option value="video">Video</option>
                                <option value="list">Participants List</option>
                            </select>
                        }

                    </div>

                </div>
                <div className="col-3 align-self-center">
                    <button className="btn btn-hover-shine text-light py-2 px-4 mr-1" style={{ backgroundColor: '#006FA7' }} onClick={() => addSection()}>Add</button>
                    <button className="btn btn-hover-shine text-light py-2 px-4" style={{ backgroundColor: '#006FA7' }} onClick={(e) => onSave(e)}>Save</button>

                </div>
            </div>


        </TransitionGroup >
    )
}

export default EditIntro
