import React, { useEffect, useState } from 'react';

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import _ from "lodash"
import styles3 from '.././../../assets/css/newStyling.module.css'
import Back from '../../../assets/components/back/Back';
import styles from '../../../assets/css/rstyling.module.css'
import { Line } from 'rc-progress';
import Project_View from './Project_View';
import Project_Description_View from './Project_Description_View';
import { useDispatch, useSelector } from 'react-redux';
import { getRespondentActivity } from '../../../actions/activities_actions';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { acceptInviteNotification, joinProject } from '../../../actions/participant_actions';
import { FaArrowCircleRight } from "react-icons/fa"
import { RiSurveyFill } from "react-icons/ri"
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdAssignmentLate } from "react-icons/md";
import { GoCircle } from "react-icons/go";




const Project_Detail_View = (props) => {




    const location = useLocation();

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(true)
    const [projectActivities, setProjectActivities] = useState([])
    const [projectDetails, setProjectDetails] = useState({})
    const [projectScreener, setProjectScreener] = useState({})
    const [projectCompletionPercentage, setProjectCompletionPercentage] = useState(0)
    const activityState = useSelector(state => state.Activity)

    const getActivityListing = (page = 1, limit = 15) => {

        dispatch(getRespondentActivity({ page: page, limit: limit, projectId: params.projectid }))
            .then((response) => {

                setProjectDetails(response.payload.content.project)
                setProjectScreener(response.payload.content.screener)
                setProjectActivities([...response.payload.content.activities])
                setProjectCompletionPercentage(response.payload.content.projectCompletion)

                setLoading(false)
            })
            .catch(err => {
                //setLoading(false)
            })
    }
    useEffect(() => {

        getActivityListing()
    }, [])

    const previous = () => {
        getActivityListing(activityState.currentPage - 1, 5)
    }

    const next = () => {
        getActivityListing(activityState.currentPage + 1, 5)
    }

    //const projectDetails =   location
    // console.log('projectDetails.isMyproject->',projectDetails.isMyProject)
    // const [isMyProject, setMyProject] = useState(projectDetails.isMyProject)
    const acceptInvite = () => {
        dispatch(acceptInviteNotification({ projectId: params.projectid }))
            .then((response) => {

                Swal({
                    title: "Accepted",
                    text: "Project Invitation Accepted",
                    icon: 'success'
                }).then(() => {
                    //  setMyProject(true)

                })
            })
            .catch(err => {
                //console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }
    const join = () => {
        dispatch(joinProject({ projectId: params.projectid }))
            .then((response) => {
                Swal({
                    title: "Joined",
                    text: "Project Joined",
                    icon: 'success'
                }).then(() => {
                    // setMyProject(true)
                })
            })
            .catch(err => {

                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }

    const activityLink = (activityId, activityType, AllReplies, repliesId) => {


        if (activityType === 'Screener') {
            let path1 = `/respondent/evaluation/${activityId}`
            let path2 = `/respondent/survey-analysis/${activityId}/${repliesId}`
            AllReplies == true ? navigate(path2) : navigate(path1)
        }

        if (activityType === 'Survey') {

            let path1 = `/respondent/survey/${activityId}`
            let path2 = `/respondent/survey-analysis/${activityId}/${repliesId}`
            AllReplies == true ? navigate(path2) : navigate(path1)
        }
        else if (activityType === 'In-Depth Interview') {
            let path = `/respondent/project/interview/${params.projectid}/${activityId}`
            navigate(path)
        }
        else if (activityType === 'Online In-Depth Interview') {
            let path = `/respondent/project/onlineinterview/${params.projectid}/${activityId}`
            navigate(path)
        }

        else if (activityType === 'Focus Group Discussion') {
            let path = `/respondent/project/forum/${params.projectid}/${activityId}`
            navigate(path)
        }

        else if (activityType === 'Forum') {
            let path = `/respondent/group-forum-detail/${activityId}`
            navigate(path)
        }

        else if (activityType === 'Diary') {
            let path = `/respondent/project/journal/${params.projectid}/${activityId}`
            navigate(path)
        }


    }

    return (
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <div className="">


                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,
                                    // position: 'fixed',
                                    // top: '2%'
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>






                            {/* <div className="mt-5">

                               
                                <h4 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="mb-0 text-center">
                                    Project Accepted
                                </h4>

                            </div> */}



                            {/* {
                            
                                <>
                                    {
                                        projectScreener &&
                                        <>
                                            <div className="pt-3 mb-2">
                                              
                                                <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>Project Screener</h5>
                                            </div>

                                           
                                            <div className="row py-3">

                                                {
                                                    (_.isEmpty(projectScreener) === false) ?
                                                        <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={projectScreener.name}
                                                            details={projectScreener.description}
                                                            deadline={projectScreener.endDate && new Date(projectScreener.endDate).toLocaleDateString()}
                                                            completePercentage={projectScreener.completionPercentage ? projectScreener.completionPercentage : 0}
                                                            //img here
                                                            images={projectScreener.bannerImage}
                                                            link={() => projectScreener.replyExists ?
                                                                history(`/respondent/survey-analysis/${projectScreener._id}/${projectScreener.repliesId}`) :
                                                                history(`/respondent/evaluation/${projectScreener._id}`)}
                                                        /> :
                                                        <div className={"pb-2 col-12"}>
                                                            <div className="row px-3">
                                                                <div className="col -12 ]mt-3 align-self-center text-center">
                                                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Survey Available</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }


                                            </div>
                                        </>
                                    }


                                    <div className="pt-3 mb-2">
                                        
                                        <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>All Activities</h5>
                                    </div>
                                    <div className='row py-3'>
                                        {
                                           
                                            (projectActivities.length > 0 ?
                                                projectActivities.map(activity => {

                                                    if (activity.activityType === 'In-Depth Interview') {
                                                        return <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={activity.name}
                                                            details={activity.description.substring(0, 180)}
                                                            deadline={activity.endDate && new Date(activity.endDate).toLocaleDateString()}
                                                            completePercentage={activity.completionPercentage ? activity.completionPercentage : 0}
                                                            // img here
                                                            images={activity.bannerImage}
                                                            link={() => history(`/respondent/project/interview/${params.projectid}/${activity._id}`)}
                                                        />
                                                    } else if (activity.activityType === 'Online In-Depth Interview') {
                                                        return <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={activity.name}
                                                            details={activity.description.substring(0, 180)}
                                                            deadline={activity.endDate && new Date(activity.endDate).toLocaleDateString()}
                                                            completePercentage={activity.completionPercentage ? activity.completionPercentage : 0}
                                                            images={activity.bannerImage}
                                                            link={() => history(`/respondent/project/onlineinterview/${params.projectid}/${activity._id}`)}
                                                        />
                                                    } else if (activity.activityType === 'Survey') {
                                                        return <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={activity.name}
                                                            details={activity.description.substring(0, 180)}
                                                            deadline={activity.endDate && new Date(activity.endDate).toLocaleDateString()}
                                                            completePercentage={activity.completionPercentage ? activity.completionPercentage : 0}
                                                            images={activity.bannerImage}
                                                            link={() => activity.replyExists ? history(`/respondent/survey-analysis/${activity._id}/${activity.repliesId}`) : history(`/respondent/survey/${activity._id}`)}
                                                        />
                                                    } else if (activity.activityType === 'Diary') {
                                                        return <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={activity.name}
                                                            details={activity.description.substring(0, 180)}
                                                            deadline={activity.endDate && new Date(activity.endDate).toLocaleDateString()}
                                                            completePercentage={activity.completionPercentage ? activity.completionPercentage : 0}
                                                            // img here
                                                            images={activity.bannerImage}
                                                            link={() => history(`/respondent/project/journal/${params.projectid}/${activity._id}`)}
                                                        />
                                                    } else if (activity.activityType === 'Forum') {
                                                        return <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={activity.name}
                                                            details={activity.description.substring(0, 180)}
                                                            deadline={activity.endDate && new Date(activity.endDate).toLocaleDateString()}
                                                            completePercentage={activity.completionPercentage ? activity.completionPercentage : 0}
                                                            images={activity.bannerImage}
                                                            link={() => history('/respondent/group-forum-detail/' + activity._id)}
                                                        />

                                                    } else if (activity.activityType === 'Focus Group Discussion') {
                                                        return <Project_View
                                                            class={layoutController(width + 370).class}
                                                            title={activity.name}
                                                            details={activity.description.substring(0, 180)}
                                                            deadline={activity.endDate && new Date(activity.endDate).toLocaleDateString()}
                                                            completePercentage={activity.completionPercentage ? activity.completionPercentage : 0}

                                                            // img here
                                                            images={activity.bannerImage}
                                                            link={() => history(`/respondent/project/forum/${params.projectid}/${activity._id}`)}
                                                        />
                                                    }
                                                }) :
                                                <div className={"pb-2 col-12"}>
                                                    <div className="row px-3">
                                                        <div className="col -12 ]mt-3 align-self-center text-center">
                                                            <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Activities Available</span>
                                                        </div>
                                                    </div>
                                                </div>)

                                        }

                                        {
                                            (projectScreener && projectScreener.replyApproved) &&
                                            <div className='col-12 text-center mt-5'>
                                                <ButtonGroup size="sm">
                                                    <Button className={"px-2 text-light"} style={{ backgroundColor: '#006FA7', borderColor: '#006FA7' }} onClick={() => previous()} disabled={activityState.currentPage === 1 ? true : false}>
                                                        Previous
                                                    </Button>
                                                    <Button className={"px-3 text-light"} style={{ backgroundColor: '#006FA7', borderColor: '#006FA7' }} onClick={() => next()} disabled={(activityState.totalPages === activityState.currentPage) ? true : false}>
                                                        Next
                                                    </Button>
                                                </ButtonGroup>
                                            </div>

                                        }
                                    </div>
                                </>

                            } */}
                            <div className={`${styles.card_border}`} style={{ height: 'auto' }}>
                                {projectDetails &&

                                    <div className={`${styles.card_border_body} col-12 p-0`} style={{ height: 'auto' }} >

                                        <div className='row m-2'>
                                            <div class="col-2 col-md-2 p-0">
                                                <img src='https://img.freepik.com/free-vector/startup-project-isometric-concept-with-descriptions-marketing-analysts-work-with-equipment-tools-vector-illustration_1284-69340.jpg' className={styles3.project_tag_banner3}></img>
                                            </div>
                                            <div class="col-10 col-md-5">

                                                <p className={`${styles3.continue_project_title}  ml-md-5 ml-0`}>{projectDetails.title}</p>

                                                <div className='d-flex'>
                                                    <span className="d-none d-md-block">
                                                        <img src='https://www.svgrepo.com/show/382106/male-avatar-boy-face-man-user-9.svg' className={`${styles3.continue_project_admin_avatar2} ml-md-5 ml-0`} alt="Admin Avatar"></img>
                                                    </span>
                                                    <span className="d-none d-md-block pt-2" style={{ color: '#000', fontWeight: '500', paddingLeft: '20px' }}>{projectDetails.author && projectDetails.author.userName ? projectDetails.author.userName : projectDetails.author && projectDetails.author.firstName ? projectDetails.author.firstName: 'Admin' }</span>
                                                </div>

                                            </div>

                                            <div class="col-12 col-md-5 pt-2 my-1">

                                                <div className='d-flex px-0' style={{ alignItems: 'center' }}>
                                                    <p className='p-0 m-0' style={{ color: '#000' }}>Progress</p>

                                                    <Line className='container' percent={projectCompletionPercentage} strokeWidth={4} trailWidth={4} trailColor='#F4F6F8' strokeColor="#0069AB" />
                                                    <p className='p-0 m-0' style={{ color: '#000' }}>{projectCompletionPercentage ? projectCompletionPercentage : '0'}%</p>
                                                </div>
                                            </div>

                                        </div>




                                    </div>
                                }
                                {projectScreener && projectScreener != null ?
                                    <div className={`${styles.card_border} col-11 my-3 container`} style={{ height: 'auto' }} >

                                        <div className='row py-4'>
                                            <div className={`col-1 m-0 p-0`} style={{ display: window.innerWidth >= 992 ? 'flex' : 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                {projectScreener.AllReplies && projectScreener.AllReplies === true ?

                                                    (
                                                        <IoMdCheckmarkCircle size={29} style={{ color: '#0069AB' }} />)
                                                    :
                                                    (
                                                        <GoCircle size={30} style={{ color: '#0069AB' }} />)
                                                }
                                            </div>
                                            <div class="col-7" style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                <p style={{ fontWeight: '600', fontSize: '15px',  margin: '0px' }} onClick={() => { activityLink(projectScreener._id, projectScreener.activityType, projectScreener.replyExists, projectScreener.repliesId ? projectScreener.repliesId : '')}}>{projectScreener.name}</p>

                                            </div>
                                            <div className={`${styles3.activity_block} col-2`}>
                                                <span className='mx-2' style={{ fontWeight: '500' }}>{projectScreener.questionsCount && projectScreener.questionsCount != null ? projectScreener.questionsCount : 'NA'} Questions</span>
                                                <RiSurveyFill size={20} style={{ color: '#0069AB' }} />

                                            </div>
                                            <div className={`${styles3.activity_block} col-1`}>

                                                <FaArrowCircleRight size={20} style={{ color: '#0069AB' }} onClick={() => { activityLink(projectScreener._id, projectScreener.activityType, projectScreener.replyExists, projectScreener.repliesId ? projectScreener.repliesId : '') }} />

                                            </div>

                                        </div>




                                    </div> :
                                    // <p className='px-4 py-4 d-flex' style={{ fontWeight: '500', fontSize: '18px', justifyContent: 'center' }}>No Screener Found</p>
                                    ''
                                }


                                {projectActivities && projectActivities.length > 0 ? projectActivities.map(x => {
                                    return (
                                        <div key={x._id} className={`${styles.card_border} col-11 my-3 container`} style={{ height: 'auto' }} >

                                            <div className='row py-4'>
                                                <div className={`col-1 m-0 p-0`} style={{ display: window.innerWidth >= 992 ? 'flex' : 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                    {x.AllReplies && x.AllReplies === true ?

                                                        (
                                                            <IoMdCheckmarkCircle size={29} style={{ color: '#0069AB' }} />)
                                                        :
                                                        (
                                                            <GoCircle size={30} style={{ color: '#0069AB' }} />)
                                                    }
                                                </div>

                                                <div class="col-7" style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                    <p style={{ fontWeight: '600', fontSize: '15px', margin: '0px' }} onClick={() => { activityLink(x._id, x.activityType, x.replyExists, x.repliesId ? x.repliesId : '') }}>{x.name}</p>

                                                </div>

                                                <div className={`col-2`} style={{ display: window.innerWidth >= 992 ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center' }}  >


                                                    {x.activityType === 'Survey' ? <span className='mx-1' style={{
                                                        fontWeight: '500', overflow: 'hidden',
                                                        whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                                    }}>{x.questionsCount && x.questionsCount != null ? x.questionsCount : 'NA'} Questions</span>
                                                        : <span className='mx-1' style={{
                                                            fontWeight: '500', overflow: 'hidden',
                                                            whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                                        }}></span>
                                                    }
                                                    {/* <span className='mx-1' style={{
                                                        fontWeight: '500', overflow: 'hidden',
                                                        whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                                    }}>{x.questionsCount && x.questionsCount != null ? x.questionsCount : 'NA'} Questions</span> */}
                                                    {x.activityType === 'Survey' ?
                                                        <RiSurveyFill className='d-none d-md-block' size={20} style={{ color: '#0069AB' }} />
                                                        :
                                                        // <MdAssignmentLate className='d-none d-md-block' size={20} style={{ color: '#0069AB' }} />
                                                        ''
                                                    }

                                                </div>
                                                <div className={`col-1 `} style={{ display: window.innerWidth >= 992 ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center' }}>

                                                    <FaArrowCircleRight className='d-none d-md-block' size={20} style={{ color: '#0069AB', cursor: 'pointer' }} onClick={() => { activityLink(x._id, x.activityType, x.replyExists, x.repliesId ? x.repliesId : '') }} />

                                                </div>

                                            </div>




                                        </div>)
                                }) :

                                    <p className='px-4 py-4 d-flex' style={{ fontWeight: '500', fontSize: '18px', justifyContent: 'center' }}>No Activities Found</p>
                                }
                            </div>


                        </LoadingOverlay>

                    </div>
                )}
            />
        </TransitionGroup>
    )

}

export default Project_Detail_View;