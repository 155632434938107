import React, { useEffect, useState } from 'react';
import { getActivityQuestionImage, getActivityVideo2 } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from '../../css/styling.module.css';
import YouTube from 'react-youtube'
import { MdCircle } from "react-icons/md";
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';
const Question_With_Radio_Scripts = (props) => {

    const[selectColor, setSelectColor] = useState(null)
    const { selectedOption, handleRadioChange, selectedOptionQuestion } = props;


    if(props.sec === true){
        if(!props.sec_questions.includes(props.questionId)){
     props.sec_questions.push(props.questionId)
     //console.log('props.sec_questions ->', props.sec_questions)
    props.set_questions_array(props.sec_questions)
    
        }
    }

   
    // On Radio Value change
    const onRadioChange = (value) => {

        // if (props.allContent.questions[props.questionIndex].selected === value) {
        //     return; // Do nothing if the option is already selected
        // }
setSelectColor(value)



     
        handleRadioChange(value, props.questionId);

        
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].selected = value;
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;
         props.setContent({ ...props.allContent });
        
         if(props.sec === true){
          
            if(!props.sec_responded_questions.includes(props.questionId)){
           props.sec_responded_questions.push(props.questionId)
           //console.log('props.sec_responded_questionsn, ->', props.sec_responded_questions)
          props.set_responded_questions_array(props.sec_responded_questions)
           }
      
        //    if(!props.sec_responded_options.includes(finalOption[0])){
        //        props.sec_responded_options.push(finalOption[0])
            
        //       props.set_sec_responded_options(props.sec_responded_options)
        //        }
          
       
       }



    }

    // On Change Other Text
    const onChangeOtherText = (e) => {

        props.allContent.pages[props.pageIndex].questions[props.questionIndex].otherText = e.target.value;
        props.setContent({ ...props.allContent });

    }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };
    const params = useParams();

    const userId = params.UserId
    const dispatch = useDispatch();
 
  
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);

    const catId = props.categoryId
    const questionIndex = props.questionIndex

   
    useEffect(() => {
        if(props.questionFile && props.questionFile !==''){
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {
                    
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('question video not available') }
                    setIsLoading(false);

                })

           
        }
    }

}, []);

    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }

    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {/* {props.questionText} */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '30px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }


                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>
                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">

                                                {isLoading ? (
                                                    <b>Loading Video...</b>
                                                ) : qVideo.data ? (
                                                    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                    <ReactPlayer
                                                        url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                        controls
                                                        width ='100%'
                                                    />
                                                    </div>
                                                ) : (
                                                    <b>Loading Video...</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    /* Question Image */
                                    <div className="mb-3 mt-3">
                                        {/* Image Display */}
                                        {qVideo.data &&

                                            // Image Display
                                            <div>
                                                <div className="position-relative">
                                                    <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                        </>


                    }



                    {/* Radios */}
                    <div className="row mt-4 mx-1">

                        {props.options.map((option, index) => {

                            return <div className={`${styles.card_border} col-12 d-flex px-4 my-2`} style={{ backgroundColor: selectColor === option ? '#E8F6FF' : '', border: selectColor === option ? '2px solid #0069AB' : '' , cursor:'pointer'}} onClick={() => onRadioChange(option)}>
                            <MdCircle className='mr-4 mt-2' size={20} style={{ color: selectColor === option ? '#1575E5' : '#C6C6C6' }} />
                            <label className='mt-2' htmlFor={props.id + index}><span style={{ color: '#000', fontSize: '18px', fontWeight: '500',  cursor:'pointer' }}  > {option}</span></label>
                            <input type="radio" id={props.id + index} name={props.name} className="custom-control-input" value={option}   onClick={(e) => { e.stopPropagation(); onRadioChange(option); }} checked={props.selected === option} />
                        </div>
                        
                            
                            // <div className={`${styles.card_border} col-12 d-flex px-4 my-2`} style={{backgroundColor: selectColor === option ? '#E8F6FF':'', border:selectColor ===option? '2px solid #0069AB':''}}>
                            //     <MdCircle className='mr-4 mt-2' size={20} style={{color:selectColor ===option ? '#1575E5':'#C6C6C6'}}/>
                            //         <label className='mt-2' htmlFor={props.id + index}><span style={{ color: '#000', fontSize: '18px', fontWeight: '500', cursor:'pointer' }}> {option}</span></label>

                            //          <input type="radio" id={props.id + index} name={props.name} className="custom-control-input" value={option} onChange={e => onRadioChange(e.target.value)} checked={props.selected === option} />
                            //     </div>

                                {/* Radio */}
                                {/* <div className="custom-radio custom-control">
                                    <input type="radio" id={props.id + index} name={props.name} className="custom-control-input" value={option} onChange={e => onRadioChange(e.target.value)} checked={props.selected === option} />

                                    <label className="custom-control-label" htmlFor={props.id + index}>
                                        <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>{option}</span>
                                    </label>
                                </div> */}


                        

                        })}

                        {/* Other */}
                        {props.isOtherAllowed && (
                            <div className="col-md-6 col-12 mb-4">

                                <div className="input-group">

                                    <div className="input-group-prepend align-self-center">

                                        {/* Other Radio */}
                                        <div className="custom-radio custom-control">
                                            <input type="radio" id={props.id} name={props.name} className="custom-control-input" value="other" onChange={e => onRadioChange(e.target.value)} checked={props.selected === "other"} />
                                            <label className="custom-control-label" htmlFor={props.id}>
                                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Other</span>
                                            </label>
                                        </div>

                                    </div>

                                    {/* Other Text */}
                                    {props.selected === "other" && (
                                        <div className="form-group mx-3 mb-0">
                                            <input className="form-control" type="text" value={props.otherText} onChange={onChangeOtherText} placeholder="Write here" />
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}

                    </div>

                    {/* Required Answer error */}
                   
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg-${props.id}`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )

}

export default Question_With_Radio_Scripts;