import React, { useState, useEffect } from 'react'
import '../../../assets/components/pageheader/PageHeader'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import {
    Card, CardBody,
} from "reactstrap";
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import Listing from '../../../assets/components/listing/Listing'
import { useNavigate, useParams } from 'react-router';
import UserIcon from '../../../assets/images/user_header_icon.png'
import Eyeicon from '../../../assets/images/eye_icon.png'
import ResizeDetector from "react-resize-detector";
import Editicon from '../../../assets/images/edit_icon.png'
import { useDispatch, useSelector } from 'react-redux';
import { getUserAllActivities } from '../../../actions/user_action';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'

const UserActivities = () => {

    const history = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const { userId } = useParams()
    //const userState = useSelector(state => state.User)
    const [userColumns, setUserColumn] = useState([
        // { Name: 'Serial No', Type: 'serialNo', key: 'serialNo' },
        { Name: "User Activity Name", Type: 'string', key: 'activityName', fixWidth: 1 },
        { Name: "User Activity Type", Type: 'string', key: 'activityType', fixWidth: 1 },
        { Name: "User Responded At", Type: 'number', key: 'activityRespondedAt', fixWidth: 10 },
        { Name: "User Points Earned", Type: 'number', key: 'activityPointsEarned' },
        // {
        //     Name: "Action", Type: 'action', options: [
        //         {
        //             icon: Eyeicon,
        //             url: "/admin/view-user",
        //             type: 'button'
        //         },
        //         {
        //             icon: Editicon,
        //             url: "/admin/edit-user",
        //             type: 'button'
        //         },
        //         {
        //             icon: Eyeicon,
        //             url: "/admin/view-user-activities",
        //             type: 'button'
        //         },
        //     ]
        // }
    ])

    const [userData, setUserData] = useState([])


    //const [searchString, setSearchString] = useState('')
    //const [searchedUser, setSearchedUser] = useState([])




    // Filtering data based on search values
    // useEffect(() => {


    //     let search = new RegExp(searchString, 'i');


    //     let filteredUsers = userData.filter(row => {
    //         return (
    //             search.test(row.age) ||
    //             search.test(row.gender) ||
    //             search.test(row.city) ||
    //             search.test(row.income) ||
    //             search.test(row.maritalStatus) ||
    //             search.test(row.education) ||
    //             search.test(row.firstName) ||
    //             search.test(row.lastName) ||
    //             search.test(row.email) ||
    //             search.test(row.userName) ||
    //             search.test(row.role) ||
    //             search.test(row.pointsEarned)



    //         );
    //     });




    //     setSearchedUser(filteredUsers);
    //     //console.log('all user details ->', filteredUsers)


    //     getUserListing(1, 5)

    // }, [searchString]);



    // const getUserListing = (currentPage = 1, dataLimit = 5, searched = searchString) => {
const getUserListing = () =>{
        dispatch(getUserAllActivities(userId))
            .then(response => {
              //console.log('all activities ->', response.payload.content)
                setUserData(response.payload.content)

                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
        }
    // }


    useEffect(() => {

        getUserListing()

        //getUserListing(1, 5)


    }, []);

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                    {/* <div className="admin_header">
                        <PageHeader heading="Users" btnText="Add New User" img={UserIcon} btnClick={() => history("/admin/add-user")} />
                    </div> */}
                    <Card className="main-card mb-3">
                        <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                            <div className="text-white menu-header-title text-capitalize">User Responded Activities List</div>

                            <div className={width > 470 && "collapse navbar-collapse"}>
                                <ul className="navbar-nav mr-auto" >
                                </ul>
                                {/* <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2.5rem' }}>
                                    <div className="input-group input-group-sm w-75">
                                        <div className="input-group-prepend border-0">
                                            <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                        </div>
                                        <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} onChange={(e) => setSearchString(e.target.value)} />
                                    </div>

                                </form> */}
                            </div>
                        </nav>

                        <CardBody className="p-0" >
                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,

                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>
                                <CardBody className="p-0">
                                  
                                    {/* Listing */}
                                    <div className="table_border">
                                        <Listing columnName={userColumns}
                                            //data={(searchString.length > 0 && searchedUser.length > 0) ? searchedUser : userData}
                                            data={userData}
                                            
                                            //currentPage={userState && userState.currentPage ? userState.currentPage : 1}
                                            //totalPages={userState && userState.totalPages ? userState.totalPages : 1} finalPoints={userState && userState.finalPoints ? userState.finalPoints : 0}
                                            //getListingData={(currentPage, dataLimit) => getUserListing(currentPage, dataLimit)}
                                            startLoading={() => setLoading(true)}
                                        />
                                    </div>

                                </CardBody>
                            </LoadingOverlay>
                        </CardBody>
                    </Card>
                </TransitionGroup>
            )}
        />
    )
}

export default UserActivities