import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css'
import noTasksImage from '../../../assets/images/no-project-img.png'
import ResizeDetector from "react-resize-detector";
import { useNavigate, Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { participantsProjects, getProjectsCompletionPercentage } from '../../../actions/project_actions';
import { joinProject } from '../../../actions/participant_actions'
import Project_Detail_View from './Project_Detail_View';
import Project_View from './Project_View';
import { Line } from 'rc-progress';
import SecureLS from 'secure-ls';
import Swal from 'sweetalert'

const ProjectsList = () => {
    const ls = new SecureLS()
    const userId = ls.get('accessUser').user._id
    const [projectPercentage, setProjectPercentage] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [noInterests_msg, setNoInterests_msg] = useState(false)
    const [enrolledProjectsData, setEnrolledProjectsData] = useState({

        projects: []
    })
    const [upcomingProjectsData, setUpcomingprojectsData] = useState({ upcomingProjects: [] })
    const dispatch = useDispatch()
    //const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        let currentPage = 1;
        let dataLimit = 5
        //setLoading(true)
        let interest = {
            interest: 'All',
            continueProjectBlock: false
        }
        // let interest = 'All'
        dispatch(participantsProjects(currentPage, dataLimit, interest))
            .then(response => {
                if (response.payload.message === 'Interests not set yet') {
                    setNoInterests_msg(true)
                    setLoading(false)
                }
                else {
                    
                    setEnrolledProjectsData(response.payload.content.projects)
                    setUpcomingprojectsData(response.payload.content.upcomingProjects)
                    let projectIds = response.payload.content.projects

                    dispatch(getProjectsCompletionPercentage(projectIds, userId))
                        .then(response => {

                            setProjectPercentage(response.payload.content)
                        }).catch(err => {
                           // console.log(err);
                        })
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log('err');
                //setLoading(false)
            })
    }, [])
    const JoinProject = (projectId) => {

        dispatch(joinProject(projectId))
            .then(response => {
                Swal({
                    
                    text: "Project Joined",
                    icon: 'success'
                }).then(() => {
                    setTimeout(() => {
                        navigate(`/respondent/project-detail-view/${response.payload.content.projectId}`)
                    }, 2000)


                }).catch(err => { console.log('err') })
            })
            .catch(err => { console.log('err') })
    }
    return (
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <>

                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,

                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" type="ball-pulse" />
                            }>
                                {noInterests_msg ?  
                                <div className={`${styles.card_border_body} p-2`} style={{ height: 'auto' }}>
                               <h4 className='text-center menu-header-title pt-4'  style={{ fontWeight: 450 }}>No interests Selected. Please complete your profile and select your interests to see the relevant projects.</h4>






                               
                            </div> : 
                            enrolledProjectsData && enrolledProjectsData.length > 0 || upcomingProjectsData && upcomingProjectsData.length > 0 ?
                            <div className={`${styles.card_border_body} p-2`} style={{ height: 'auto' }}>
                                {/* <div className="d-flex" style={{ height: '15rem'}}> */}



                                {/* for in progress projects */}
                                {enrolledProjectsData && enrolledProjectsData.length > 0 ? enrolledProjectsData.map(x => {

                                    if (x != null) {
                                        const matchingProject = projectPercentage && projectPercentage.find(project => project.projectId === x._id);


                                        const completionPercentage = matchingProject ? matchingProject.projectCompletionPercentage : '';


                                        return (

                                            <div className={`${styles.card_border} col-12 p-0 my-4`} style={{ height: 'auto' }} key={x._id}>

                                                <div className='row m-2'>

                                                    <div className="col-2 col-md-2 p-0">
                                                        <img src='https://img.freepik.com/free-vector/startup-project-isometric-concept-with-descriptions-marketing-analysts-work-with-equipment-tools-vector-illustration_1284-69340.jpg' className={styles3.project_tag_banner3} alt="Project Banner"></img>
                                                    </div>
                                                    <div className="col-10 col-md-5">
                                                        <Link to={`/respondent/project-detail-view/${x._id}`}><p className={`${styles3.continue_project_title} ml-md-5 ml-0`} >{x.title}</p></Link>

                                                        {/* Hide on smaller screens */}
                                                        <div className='d-flex'>
                                                            <span className="d-none d-md-block">
                                                                <img src='https://www.svgrepo.com/show/382106/male-avatar-boy-face-man-user-9.svg' className={`${styles3.continue_project_admin_avatar2} ml-md-5 ml-0`} alt="Admin Avatar"></img>
                                                            </span>
                                                            <span className="d-none d-md-block pt-2" style={{ color: '#000', fontWeight: '500', paddingLeft: '20px' }}>{x.admin ? x.admin : 'Admin'}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-5 px-0 my-1" >

                                                        {completionPercentage < 100 ? <p className='badge badge-secondary my-2 pt-2 d-none d-md-block col-5' style={{ backgroundColor: '#edf0f7', borderRadius: '41px', height: '25px', color: '#4580F0', fontWeight: '400' }} >In Progress</p> :
                                                            <p className='badge badge-secondary my-2 pt-2 d-none d-md-block col-5' style={{ backgroundColor: '#edf0f7', borderRadius: '41px', height: '25px', color: '#4580F0', fontWeight: '400' }} >Completed</p>
                                                        }


                                                        <div className='d-flex px-0' style={{ alignItems: 'center' }}>
                                                            <p className='p-0 m-0' style={{ color: '#000' }}>Progress</p>

                                                            <Line className='container' percent={completionPercentage} strokeWidth={4} trailWidth={4} trailColor='#F4F6F8' strokeColor="#0069AB" />
                                                            <p className='p-0 m-0' style={{ color: '#000' }}>{completionPercentage === null ? 0 : completionPercentage}%</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                        );

                                    } else {
                                        return null; // Skip null values
                                    }
                                }) : ''}

                                {/* for new projects based on interests (All) */}
                                {upcomingProjectsData && upcomingProjectsData.length > 0 ? upcomingProjectsData.map(x => {
                                    if (!x.title) {
                                        return null; // Skip rendering for empty objects
                                    }
                                    return (

                                        <div className={`${styles.card_border}  col-12 p-0 my-4`} style={{ height: 'auto' }} key={x._id}>
                                            <div className='row m-2'>


                                                <div className="col-2 col-md-2 p-0">
                                                    <img src='https://img.freepik.com/free-vector/yellow-project-management-concept-flat-style_23-2147795105.jpg' className={styles3.project_tag_banner3} alt="Project Banner"></img>
                                                </div>


                                                <div className="col-10 col-md-5">
                                                    {/* <Link to={`/respondent/project-detail-view/${x._id}`}> */}
                                                    <p className={`${styles3.continue_project_title} ml-md-5 ml-0`} >{x.title}</p>
                                                    {/* </Link> */}

                                                    <div className="d-flex">
                                                        <span className='d-none d-md-block'>
                                                            <img src='https://www.svgrepo.com/show/382106/male-avatar-boy-face-man-user-9.svg' className={`${styles3.continue_project_admin_avatar2} ml-md-5 ml-0`} alt="Administrator Avatar"></img>
                                                        </span>
                                                        <span className='d-none d-md-block pt-2' style={{ color: '#000', fontWeight: '500', paddingLeft: '20px' }}>{x.admin ? x.admin : 'Admin'}</span>


                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 px-0 py-2" >



                                                    <div className='d-sm-block px-0 float-right ' >
                                                        <Link to={`/respondent/project-detail-view/${x._id}`}>
                                                            <button type='button' className='btn' style={{ backgroundColor: '#0069AB', color: 'white' }} onClick={() => { JoinProject(x._id) }}>Participate Now</button>
                                                        </Link>



                                                    </div>
                                                </div>
                                                {/* Participate Now button */}
                                                {/* <div className='float-right d-none d-md-block '>
                                                        <Link to={`/respondent/project-detail-view/${x._id}`}>
                                                            <button type='button' className='btn' style={{ backgroundColor: '#0069AB', color: 'white' }}>Participate Now</button>
                                                        </Link>
                                                    </div> */}
                                            </div>

                                        </div>


                                    )
                                }) : ''}







                                {/* </div> */}
                            </div> :
                           <div className='mt-5 container'>
                           <img style={{ maxWidth: '100%' }} src={noTasksImage}></img>
                           <p className='container' style={{ color: '#8E8E8E', fontFamily: 'Poppins', fontSize: '20px' }}>No matching projects now, but stay tuned! We're crafting exciting opportunities just for you.</p>
                         </div>
                }

                        </LoadingOverlay>
                    </>



                )
                }
            />
        </TransitionGroup >
    )

}

export default ProjectsList;