import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import PageHeader from '../../../../../assets/components/pageheader/PageHeader'
import CreateIcon from '../../../../../assets/images/create_icon.png'
import styles from '../../../../../assets/css/rstyling.module.css'
import { Button } from 'reactstrap'
import { useNavigate, useParams, useLocation } from 'react-router';
import { createCampaign, editCampaign, getCampaignDetails } from '../../../../../actions/campaign_actions';
import Swal from 'sweetalert'
import { useDispatch } from 'react-redux';
import { getAllActiveProjects } from '../../../../../actions/project_actions';
import { getAllSmsTemplates, getUserSmsTemplateTitle } from '../../../../../actions/sms_template_actions';
import { getUserEmailTemplatesTitle } from '../../../../../actions/email_template_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { Formik } from 'formik'
import * as Yup from 'yup'
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import SecureLS from 'secure-ls';
import _ from 'lodash'

const CreateCampaignTemplate = () => {

    const params = useParams()
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [activeProjects, setActiveProjects] = useState([])
    const [smsTemplate, setSmsTemplate] = useState([])
    const [emailTemplate, setEmailTemplate] = useState([])
    const ls = new SecureLS()

    const getPageHeader = () => {
        switch (currentPath) {
            case '/admin/edit-campaign-template/' + params.campaignId:
                return <PageHeader heading="Edit Template" img={CreateIcon} />

            default:
                return <PageHeader heading="Create Template" img={CreateIcon} />
        }
    }

    const [campaignData, setCampaignData] = useState({
        title: '',
        audience: '',
        smsTemplate: '',
        emailTemplate: '',
        scheduledDate: '',
        project: '',
        author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
    })

    const onCreateCampaign = (values) => {

        dispatch(createCampaign(values))
            .then(response => {
                Swal({
                    title: "Created",
                    text: response.content ? response.content : "Campaign Created",
                    icon: 'success'
                }).then(() => {
                    history('/admin/templates/campaign')
                })

            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Try again",
                    icon: 'error'
                });
            })
    }

    const onEditCampaign = (values) => {
        if (params.campaignId) {

            dispatch(editCampaign(params.campaignId, values))
                .then(response => {
                    Swal({
                        title: response.message ? response.message : "Edited",
                        text: response.content ? response.content : "Campaign edited",
                        icon: 'success'
                    }).then(() => {
                        history('/admin/templates/campaign')
                    })

                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Try again",
                        icon: 'error'
                    });
                })
        }
    }

    const onSave = (values) => {
        const editUrl = '/admin/edit-campaign-template/' + params.campaignId

        if (params.campaignId) {
            if (currentPath === editUrl)
                onEditCampaign(values);
            else
                onCreateCampaign(values)

        } else {
            onCreateCampaign(values)
        }
    }

    const getActiveProjects = () => {
        dispatch(getAllActiveProjects())
            .then(response => {
                setActiveProjects([...response.payload.content])
            })
            .catch(err => {
                //console.log(err);
                setActiveProjects([])
            })
    }

    const getSmsTemplates = () => {
        dispatch(getUserSmsTemplateTitle())
            .then(response => {
                setSmsTemplate(response.payload.content)
            })
            .catch(err => {
              //  console.log(err);
                setSmsTemplate([])
            })
    }

    const getEmailTemplates = () => {
        dispatch(getUserEmailTemplatesTitle())
            .then(response => {
                setEmailTemplate(response.payload.content)
            })
            .catch(err => {
               // console.log(err);
                setEmailTemplate([])
            })
    }


    useEffect(() => {
        getActiveProjects()
        getSmsTemplates()
        getEmailTemplates()
    }, []);


    // For geting details of template in case of edit or copy template
    useEffect(() => {
        if (params.campaignId) {
            // getting template details
            dispatch(getCampaignDetails(params.campaignId))
                .then(response => {
                    const date = new Date(response.payload.content.scheduledDate)
                    const scheduledDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)) + "-" + date.getDate()
                    setCampaignData({
                        title: response.payload.content.title,
                        audience: response.payload.content.audience === 'all' ? 'all' : 'project',
                        project: response.payload.content.project,
                        smsTemplate: response.payload.content.smsTemplate && response.payload.content.smsTemplate._id,
                        emailTemplate: response.payload.content.emailTemplate._id,
                        scheduledDate: scheduledDate,
                    })
                    setLoading(false)
                })
                .catch(err => {
                  //  console.log(err)
                    setLoading(false)
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    });
                })
        } else {
            setLoading(false)
        }

    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '20%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <div className="create_header">
                    {getPageHeader()}
                </div>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <div className="container-fluidd mt-3">
                    <div className="row">

                        <div className='col-12'>
                        <Formik
                            initialValues={campaignData}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                const id = values.audience
                                if (values.audience === '' || values.audience === 'all') {
                                    values.audience = 'all'
                                } else {
                                    values.audience = 'project'
                                    values.project = id
                                }
                                const newValues = {
                                    title: values.title,
                                    audience: values.audience,
                                    smsTemplate: values.smsTemplate && values.smsTemplate,
                                    emailTemplate: values.emailTemplate,
                                    project: values.project,
                                    scheduledDate: values.scheduledDate
                                }
                                Object.keys(newValues).filter(value => {
                                    if (newValues[value] === '') {
                                        delete newValues[value]
                                    }
                                })
                                onSave(newValues)

                            }}

                            const validationSchema={Yup.object().shape({
                                title: Yup.string().required("Campaign Name is required"),
                                // audience: Yup.string().required("Audience is required"),
                                //emailTemplate: Yup.string().required("Email Template is required"),
                                scheduledDate: Yup.date("Scheduled date is required").required("Scheduled date is required"),

                            })}

                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                } = props;

                                return (
                                    <form autoComplete="off" onSubmit={handleSubmit}>

                                        {/* Start of form */}

                                        <div className='row'>

                                            <div className="col-md-6 col-sm-12">
                                                <FormGroup>
                                                    <Label for="campaignName">Campaign Name</Label>
                                                    <Input type="text"
                                                        className={errors.title && touched.title ? styles.is_invalid : 'valid'}
                                                        name="title"
                                                        placeholder="Title"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.title}
                                                    />
                                                    {errors.title && touched.title && (
                                                        <small className="text-danger">
                                                            {errors.title}
                                                        </small>
                                                    )}
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <FormGroup>
                                                    <Label for="audience">Select Audience</Label>
                                                    <Input type="select"
                                                        className={errors.audience && touched.audience ? styles.is_invalid : 'form-control valid'}
                                                       
                                                        name="audience"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.audience}
                                                    >
                                                        <option value="">All Participant</option>
                                                        {
                                                            activeProjects.map(project => {
                                                                return <option value={project._id}>
                                                                    {project.title}
                                                                </option>
                                                            })
                                                        }
                                                    </Input>

                                                    {errors.audience && touched.audience && (
                                                        <small className='text-danger'>
                                                            {errors.audience}
                                                        </small>
                                                    )}

                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <FormGroup>
                                                    <Label for="smsTemplate">Select SMS Template</Label>
                                                    <Input type="select"
                                                        className={errors.smsTemplate && touched.smsTemplate ? styles.is_invalid : 'form-control valid'}
                                                        name="smsTemplate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.smsTemplate}
                                                    >
                                                        <option value="">Select</option>
                                                        {
                                                            smsTemplate.map(sms => {
                                                                return <option value={sms._id}>
                                                                    {sms.title}
                                                                </option>
                                                            })
                                                        }
                                                    </Input>

                                                    {errors.smsTemplate && touched.smsTemplate && (
                                                        <small className='text-danger'>
                                                            {errors.smsTemplate}
                                                        </small>
                                                    )}

                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <FormGroup>
                                                    <Label for="emailTemplate">Select Email Template</Label>
                                                    <Input type="select"
                                                        className={errors.emailTemplate && touched.emailTemplate ? styles.is_invalid : 'form-control valid'}
                                                        name="emailTemplate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.emailTemplate}
                                                    >
                                                        <option value="">Select</option>
                                                        {
                                                            emailTemplate.map(email => {
                                                                return <option value={email._id}>
                                                                    {email.title}
                                                                </option>
                                                            })
                                                        }
                                                    </Input>

                                                    {errors.emailTemplate && touched.emailTemplate && (
                                                        <small className='text-danger'>
                                                            {errors.emailTemplate}
                                                        </small>
                                                    )}

                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <FormGroup>
                                                    <Label for="type">Scheduled Date</Label>
                                                    <Input type="date"
                                                        className={errors.scheduledDate && touched.scheduledDate ? styles.is_invalid : 'valid'}
                                                        name="scheduledDate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.scheduledDate}
                                                    />
                                                    {errors.scheduledDate && touched.scheduledDate && (
                                                        <small className="text-danger">
                                                            {errors.scheduledDate}
                                                        </small>
                                                    )}
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6 col-sm-12 align-self-center pt-3 text-right">
                                                <Button className={`${styles.bg_blue} text-white mr-2`} type="submit">Save</Button>
                                                <Button className={`btn-light text-muted`} type="button" style={{ border: '1px solid #6c757d ' }} onClick={() => history('/admin/templates/campaign')}>Cancel</Button>
                                            </div>
                                        </div>

                                    </form>
                                )
                            }}

                        </Formik>
                        </div>


                    </div>
                </div>

            </LoadingOverlay >

        </TransitionGroup>

    )
}

export default CreateCampaignTemplate
