import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createPostReply } from '../../../actions/activities_reply_actions';

import styles3 from '../../../assets/css/newStyling.module.css';
import { FaLocationArrow } from "react-icons/fa"
import styles from '../../../assets/css/styling.module.css';
import Swal from 'sweetalert'
import ImageModal from '../../../assets/components/modal/ImageModal';

const Group_Forum_Write_Reply = (props) => {

    const activityState = useSelector(state => state.ActivityReply)
    const dispatch = useDispatch()
    const params = useParams()
    const [isShowImageModal, setImageModal] = useState(false)
    const [forumReply, setForumReply] = useState({
        description: '',
        images: []
    })

    const onImageClick = () => {
        document.getElementById('replyImage').click()
    }

    const createForumReply = () => {
        if (forumReply.description !== '') {
            const formData = new FormData()
            formData.append('activity', params.forumid)
            formData.append('activityType', 'Forum')
            formData.append('description', forumReply.description)

            if (forumReply.images) {
                for (let i = 0; i < forumReply.images.length; i++) {
                    formData.append(`images[${i}]`, forumReply.images[i])

                }
            }

            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            dispatch(createPostReply(formData))
                .then((response) => {
                    Swal.stopLoading()
                    Swal.close()
                    // const page = activityState.totalPages > 0 ? activityState.totalPages : 1
                    props.getReplies(1, 5)
                
                    setForumReply({
                        description: '',
                        images: []
                    })
                    // Swal({
                    //     title: response.payload.title ? response.payload.title : "Created",
                    //     text: response.payload.message ? response.payload.message : 'Entry Created',
                    //     icon: 'success'
                    // })
                })
                .catch(err => {
                    console.log('err')
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        }

    }

    // useEffect(() =>{
    //     createForumReply()
    // },[props.getReplies(1,5)])

    return (

        <div className="row px-3">

            {/* <div className="d-inline-flex align-self-center">
                <div className="rounded-circle" style={{ backgroundColor: "#e8e6e5", width: '38px', height: '38px' }}>
                </div>
            </div> */}

            <div className="col align-self-center">

                <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                    <input type="text"
                        className={"form-control " + styles.reply_comment_textbox}
                        value={forumReply.description}
                        style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }}
                        placeholder={props.placeholder}
                        onChange={(e) => setForumReply({ ...forumReply, description: e.target.value })}
                    />
                    {/* <div className="input-group-append" onClick={() => onImageClick()}>
                        {
                            forumReply.images && forumReply.images[0] ?

                                <>
                                    <img src={typeof forumReply.images[0] === 'string' ? forumReply.images[0] : URL.createObjectURL(forumReply.images[0])} alt="" width="40px" height="40px"
                                        onClick={() => setImageModal(!isShowImageModal)} />
                                    <ImageModal
                                        isModalOpen={isShowImageModal} setModal={() => setImageModal(!isShowImageModal)}
                                        image={typeof forumReply.images[0] === 'string' ? forumReply.images[0] : URL.createObjectURL(forumReply.images[0])}
                                        changeImage={(files) => setForumReply({ ...forumReply, images: files })}
                                        delImage={() => setForumReply({ ...forumReply, images: [] })}
                                    />

                                </>
                                :
                                <>
                                    <span for="replyImage" className={styles.upload_media_icon} ></span>
                                    <input type="file" accept=".png, .jpg, .jpeg"
                                        id="replyImage"
                                        style={{ display: 'none' }}
                                        onChange={(e) => setForumReply({ ...forumReply, images: e.target.files })}
                                    />
                                </>
                        }

                    </div> */}
                </div>

            </div>

           
            <div className=''>
                <button className={`${styles3.submit_button} p-0`} style={{ borderStyle: 'none' }} onClick={() => createForumReply()}>

                    <span><FaLocationArrow size={27} /></span>

                </button>

            </div>

        </div>

    )

}

export default Group_Forum_Write_Reply;