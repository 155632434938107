import React, { useState, useContext } from 'react'
import logo from '../../assets/images/logo.png'
import styles from '../../assets/css/rstyling.module.css'
import { IoIosEye } from 'react-icons/io'
import { IoIosEyeOff } from 'react-icons/io'
import login_bg from "../../assets/images/login_bg.jpg";
import axios from 'axios'
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useLocation } from 'react-router'
//import ShowMore from 'react-responsive-tabs/lib/components/ShowMore'
import Swal from 'sweetalert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { BACK_SERVER_URL } from '../../config/server'
import { useNavigate, useParams} from 'react-router-dom';
import { TokenContext } from '../../ResearcherToken';
const ResearcherInvite = () => {
    const {token} = useParams();
    let { search } = useLocation();
    const query = new URLSearchParams(window.decodeURIComponent(search));

    const [userData, setData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        userName: '',
        phone: '',
        password: '',
        confirmPassword: '',
        
        
    })
    const [state, setState] = useState()
    const [show, setPassword] = useState({
        password: false,
        confirmPassword: false
    })

   // const {tokenn, setToken} = useContext(TokenContext);
    const history = useNavigate();
    const onSubmit = (values) => {
        if (values.email) {
            
            //setToken(token)
            axios.post(`${BACK_SERVER_URL}api/users/researcherSignup`, values)
                .then(response => {
                    setState(response.data.message)
                   
                    
                     if(response.data.message == 'Researcher SignUp Successful'){
                        axios.get(`${BACK_SERVER_URL}api/participants/acceptProjectInvite?token=${token}`)
                        .then(response => {
                            console.log('response->')})
                    Swal({
                        title : "Signed Up",
                        text: 'An OTP code has been sent to email for verification.',
                        icon: 'success'
                    })
                    history(`/otp`)}
                })
                .catch(err => {
                    //setState(err)
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        } else {
            Swal({
                title: "Error",
                text: 'Invitation Link is broken',
                icon: 'error'
            })
        }
    }

    return (

        <>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-lg-4 p-0 d-none d-lg-block">
                        <div className={`h-100 ${styles.gradient_grbl}`}>
                            <div
                                style={{
                                    backgroundImage: "url(" + login_bg + ")",
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    opacity: "0.2",
                                    zIndex: "5",
                                    backgroundSize: "cover",
                                    top: 0,
                                    left: 0
                                }} />

                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 bg-white">

                        {/* Logo */}
                        <ul className="nav justify-content-end">
                            <li className="nav-item">
                                <img src={logo} alt="logo" width="150px" style={{ margin: '2rem 1rem 0 0' }} />
                            </li>
                        </ul>

                        <div className="row">
                            <div className="col-lg-9 col-md-10 col-sm-12 pt-5">

                                <div className="justify-content-center align-self-center mx-2">
                                    <h4 className="mb-0">
                                        <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Welcome Researcher</h3>
                                        <p style={{ fontSize: '1rem' }}>Please fill this form to create your account.</p>
                                    </h4>

                                    <div className="row mt-5">

                                        <Formik
                                            initialValues={userData}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true)
                                                onSubmit(values)

                                            }}


                                            const validationSchema={Yup.object().shape({
                                                email: Yup.string().required("Email is required"),
                                                firstName: Yup.string().required("First Name is required"),
                                                lastName: Yup.string().required("Last Name is required"),
                                                userName: Yup.string().required("Username is required"),
                                                phone: Yup.number().required("Phone Number is required"),
                                                password: Yup.string().required("Password is required").min(8),
                                                confirmPassword: Yup.string().required("Password is required").oneOf([Yup.ref('password'), null], "Password does not match")
                                            })}


                                        >
                                            {props => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue
                                                } = props;



                                                return (
                                                    <form autoComplete="off" onSubmit={handleSubmit}>

                                                        {/* Start of form */}
                                                        <div className='row mx-2'>
                                                        <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label for="firstName">Email</Label>
                                                                    <Input
                                                                        className={errors.email && touched.email ? styles.is_invalid : 'valid'}
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}

                                                                    />
                                                                    {errors.email && touched.email && (
                                                                        <small className='text-danger'>
                                                                            {errors.email}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label for="firstName">First Name</Label>
                                                                    <Input
                                                                        className={errors.firstName && touched.firstName ? styles.is_invalid : 'valid'}
                                                                        name="firstName"
                                                                        placeholder="First Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.firstName}

                                                                    />
                                                                    {errors.firstName && touched.firstName && (
                                                                        <small className='text-danger'>
                                                                            {errors.firstName}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label for="lastName">Last Name</Label>
                                                                    <Input
                                                                        className={errors.lastName && touched.lastName ? styles.is_invalid : 'valid'}
                                                                        name="lastName"
                                                                        placeholder="Last Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.lastName}

                                                                    />
                                                                    {errors.lastName && touched.lastName && (
                                                                        <small className='text-danger'>
                                                                            {errors.lastName}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label for="Username">Username</Label>
                                                                    <Input
                                                                        className={errors.userName && touched.userName ? styles.is_invalid : 'valid'}
                                                                        name="userName"
                                                                        placeholder="UserName"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.userName}

                                                                    />
                                                                    {errors.userName && touched.userName && (
                                                                        <small className='text-danger'>
                                                                            {errors.userName}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label for="phoneNumber">Phone Number</Label>
                                                                    <Input
                                                                        className={errors.phone && touched.phone ? styles.is_invalid : 'valid'}
                                                                        name="phone"
                                                                        placeholder="Phone Number"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.phone}
                                                                        type="number"

                                                                    />
                                                                    {errors.phone && touched.phone && (
                                                                        <small className='text-danger'>
                                                                            {errors.phone}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label className={styles.password}>Password
                                                                        <Input
                                                                            className={errors.password && touched.password ? styles.is_invalid + " mt-2" : 'valid mt-2'}
                                                                            name="password"
                                                                            placeholder="Password"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.password}
                                                                            type={show.password ? "text" : "password"} />
                                                                        <span className={styles.password_ico_position} onClick={() => setPassword({ ...show, password: !show.password })}>
                                                                            {show.password ? <IoIosEye /> : <IoIosEyeOff />}
                                                                        </span>
                                                                    </Label>
                                                                    {errors.password && touched.password && (
                                                                        <small className='text-danger'>
                                                                            {errors.password}
                                                                        </small>
                                                                    )}

                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label className={styles.password}>Confirm Password
                                                                        <Input
                                                                            className={errors.confirmPassword && touched.confirmPassword ? styles.is_invalid + " mt-2" : 'valid mt-2'}
                                                                            name="confirmPassword"
                                                                            placeholder="Confirm Password"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.confirmPassword}
                                                                            type={show.confirmPassword ? "text" : "password"}
                                                                           />
                                                                        <span className={styles.password_ico_position} onClick={() => setPassword({ ...show, confirmPassword: !show.confirmPassword })}>
                                                                            {show.confirmPassword ? <IoIosEye /> : <IoIosEyeOff />}
                                                                        </span>
                                                                    </Label>
                                                                    {errors.confirmPassword && touched.confirmPassword && (
                                                                        <small className='text-danger'>
                                                                            {errors.confirmPassword}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-12 align-self-end text-right">
                                                                <Button className={`${styles.bg_blue} mb-2`} type="submit">Sign up</Button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                )
                                            }}

                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ResearcherInvite
