import React, { useState, useEffect } from 'react'
import { CardHeader, Card, CardBody, Label, Input, FormGroup, Button } from 'reactstrap'
import Chart from 'react-apexcharts'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { Typeahead } from 'react-bootstrap-typeahead'
import styles from '../../../../assets/css/rstyling.module.css'
import Listing from '../../../../assets/components/listing/Listing'
import Search from '../../../../assets/images/search_icon.png'
import ResizeDetector from 'react-resize-detector'
import { TransitionGroup } from 'react-transition-group';
import { getProjectAllActivity } from '../../../../actions/activities_actions'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { projectExcerptReport } from '../../../../actions/project_actions'

const ExcerptReport = () => {

    const dispatch = useDispatch()
    const params = useParams()

    const [isShowReport, showReport] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isShowActivities, showActivities] = useState(false)
    const [isAllActivities, setAllActivities] = useState(false)

    const [activitiesList, setActivitiesList] = useState([])
    const [selectedActivities, setSelectedActivities] = useState([])
    // const [report, setReport] = useState([])

    const [reportCharts, setReport] = useState({})

    const [column, setColumn] = useState([
        { Name: 'Activity Name', Type: 'string', key: 'activityName' },
        { Name: 'Activity Type', Type: 'string', key: 'activityType' },
        { Name: "Status", Type: 'string', key: 'status' },
        { Name: "Start Date", Type: 'date', key: 'startDate' },
        { Name: "Start Time", Type: 'time', key: 'startDate' },
        { Name: "Excerpts", Type: 'number', key: 'totalExcerpts' },
        { Name: "Total Respondents", Type: 'number', key: 'totalRespondents' },
    ]);

    const [activitiesData, setActivitiesData] = useState([])

    const getActivities = () => {
        dispatch(getProjectAllActivity({ projectId: params.projectid }))
            .then(response => {
               
                setActivitiesList(response.payload.content.activities)
            }).catch(err => {
               // console.log(err)
            })
    }

    useEffect(() => {
        getActivities()
    }, [])

    const getReport = () => {
        if (isShowActivities || isAllActivities) {
            setLoading(true)
            let data = []
            if (isShowActivities) {
                const activities = selectedActivities.map(activity => activity._id)
                // report for selected activities
                data = {
                    projectId: params.projectid,
                    activities: activities
                }
            } else {
                // report for all activities
                data = {
                    projectId: params.projectid,
                    activities: []
                }
            }

            dispatch(projectExcerptReport(data))
                .then(response => {
                  
                    setActivitiesData(response.payload.content.reports)
                    const report = response.payload.content.reports
                    let completed = []
                    let activityName = []

                    report.map(activity => {
                        activityName.push(activity.activityName)
                        completed.push(activity.totalExcerpts)

                    })
                    // Saving data to plot graph
                    setReport({
                        series: [{
                            name: 'Completed',
                            data: completed
                        }],
                        options: {
                            chart: {
                                type: 'bar',
                                height: 250,
                                stacked: true,
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                },
                            },
                            stroke: {
                                width: 1,
                                colors: ['#fff']
                            },
                            xaxis: {
                                categories: activityName,
                            },
                            yaxis: {
                                categories: activityName,
                            },
                            fill: {
                                opacity: 1
                            },
                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center',
                                offsetX: 40,
                            }
                        },
                    })
                    setLoading(false)

                    showReport(true)


                }).catch(err => {
                    showReport(true)
                    //console.log(err)
                    setLoading(false)
                })
        }
    }

    const [searchString, setSearchString] = useState('')
    const [searchedResult, setSearchedResult] = useState([])

    // Listing search
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredActivities = activitiesData.filter(row => {
            return (
                search.test(row.activityName) ||
                search.test(row.status) ||
                search.test(row.startDate) ||
                search.test(row.activityType)
            );
        });
        setSearchedResult(filteredActivities);
    }, [searchString]);

    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            <div className="container-fluid">
                                {/* Form */}
                                <Card className="shadow-none mb-2" style={{ borderRadius: '0.4rem' }}>
                                    <CardHeader className={styles.text_blue}>Excerpt Report</CardHeader>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-12">
                                                <FormGroup>
                                                    <input type="checkbox" id="allActivities" className="mr-2" checked={isAllActivities}
                                                        onChange={(e) => setAllActivities(e.target.checked)}
                                                    />
                                                    <label for="allActivities">All Activities</label>
                                                </FormGroup>
                                            </div>
                                            <div className="col-12">
                                                <FormGroup>
                                                    <input type="checkbox" id="participant" className="mr-2" checked={isShowActivities}
                                                        onChange={(e) => { showActivities(e.target.checked) }}
                                                    />
                                                    <label for="participant">Select Activities</label>
                                                </FormGroup>
                                            </div>
                                            <div className="col-12">
                                                {
                                                    isShowActivities &&
                                                    <FormGroup className="light_typeahead custom_drop">
                                                        <Label for="activity">Activity</Label>
                                                        <Typeahead
                                                            id="participantList"
                                                            multiple
                                                            onChange={setSelectedActivities}
                                                            selected={selectedActivities}
                                                            options={activitiesList}
                                                            labelKey={(option) => `${option.name}`}
                                                            placeholder="Select Activities"
                                                        />
                                                    </FormGroup>
                                                }
                                            </div>
                                        </div>

                                    </CardBody>
                                </Card>

                                <div className="row">
                                    <div className="col-12 align-self-center pt-3 text-right" >
                                        <Button className={`${styles.bg_blue} px-5 w-25`} onClick={getReport}>
                                            Generate Report
                                        </Button>
                                    </div>
                                </div>

                                {
                                    isShowReport &&
                                    <>
                                        {/* Chart */}
                                        <div className="my-3">
                                            <div id="chart" >
                                                <Chart options={reportCharts.options} series={reportCharts.series} type="bar" height={350} />
                                            </div>
                                        </div>

                                        {/* Listing */}
                                        <Card className="main-card mb-3">
                                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                                <div className="text-white menu-header-title text-capitalize">Activities List</div>

                                                <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                                    <ul className="navbar-nav mr-auto">
                                                    </ul>
                                                    <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                                        <div className="input-group input-group-sm w-75">
                                                            <div className="input-group-prepend border-0">
                                                                <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                            </div>
                                                            <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                                onChange={(e) => setSearchString(e.target.value)} />                                                            </div>

                                                    </div>
                                                </div>
                                            </nav>

                                            <CardBody className="p-0">
                                                <Listing columnName={column}
                                                    data={(searchString.length > 0 || searchedResult.length > 0) ? searchedResult : activitiesData}
                                                />
                                            </CardBody>
                                        </Card>
                                    </>
                                }

                            </div>


                        </LoadingOverlay>


                    </TransitionGroup>
                </>
            )}
        />
    )
}

export default ExcerptReport
