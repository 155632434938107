import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SecureLS from 'secure-ls';
import { BACK_SERVER_URL } from '../../../config/server';
import axios from 'axios';
import styles3 from '../../../assets/css/newStyling.module.css'
import styles from '../../../assets/css/styling.module.css'
export default function NotificationsSection() {

    const [completeProfile, setCompleteProfile] = useState('')
    const ls = new SecureLS()
    const [msg,setMsg] = useState(false)
    let userId
    let userName;
    try {
        userId = ls.get('accessUser').user._id
    } catch (err) {
       // console.log(err)
    }
    try {
        userName = ls.get('accessUser').user.firstName
    } catch (err) {
        //console.log(err)
    }

    useEffect(() => {
        const url = `${BACK_SERVER_URL}api/users/profileCompletion/${userId}`
        const request = axios.get(url)
            .then(response => {

                setCompleteProfile(response.data.content)
                if (response.data.content < 100) {
                     setMsg(true)
                }
                // setProfileComp(response.data.content)
                // setNotificationType(response.data.message)

            })
            .catch(err =>
                console.log('err')
            )

    }, [])

    return (
        <div>

            <div className='col-12'>
{msg === true &&   <div className={styles3.card_border_borderless} style={{ backgroundColor: '#EAF7FF' }}>



<div className={styles.card_border_body + " pt-2 pb-3 px-0"} style={{ display: 'flex', overflow: 'hidden' }}>
    <div style={{ height: 'auto', flex: '90%' }}>

        <p className={`${styles3.welcome_user_text} container`}>Join the community and complete your profile to unlock<span className={styles3.welcome_user_text_bold}> 100 Connect points</span>. Make connections that matter today!</p>

    </div>
    <div className='container pt-5' style={{ flex: '10%' }}>
        <a href="https://app.consumerconnect.net/respondent/profile" target="_blank" rel="noopener noreferrer">
            <button className={`${styles3.view_profile_page}`}>View Profile Page</button>
        </a>
    </div>
</div>

</div>}
{msg === false && <h4  className={`container pt-4 text-center`}> No new Notifications</h4>}
              








            </div>
        </div>
    )
}
