import React, { useEffect, useState } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, NavItem, Button } from 'reactstrap'
import {
    IoIosNotificationsOutline,
} from "react-icons/io";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";

import PerfectScrollbar from "react-perfect-scrollbar";
import city3 from "../../assets/utils/images/dropdown-header/city3.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getMyNotifications, changeNotificationStatus, getMyNewNotifications } from '../../actions/notification_actions'
import InfiniteScroll from 'react-infinite-scroll-component';
import { acceptInviteNotification, rejectInviteNotification } from '../../actions/participant_actions';
import Swal from 'sweetalert'
import { useNavigate, useLocation } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { BACK_SERVER_URL } from '../../config/server';
import axios from 'axios'
import styles3 from '../../assets/css/newStyling.module.css'
import { TRUE } from 'sass';

const Notifications = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [notifications, setNotifications] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [message, setMessage] = useState('Loading...')
    //const notificationState = useSelector(state => state.Notifications)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [incompleteProfile, set_incompleteProfile] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ls = new SecureLS()
    const [role, setRole] = useState(0)
    const [completeProfile, setCompleteProfile] = useState(false)
    const [newMsg, setNewMsg] = useState(false)
    const [pending, setPending] = useState(0)
    const [refresh, set_refresh] = useState(false)
    let userId
    let userRole;
    try {
        userId = ls.get('accessUser').user._id
        userRole = ls.get('accessUser').user.role
    } catch (err) {

    }
   


    const getNotifications = () => {
      
        dispatch(getMyNewNotifications(10))
            .then(res => {
               

                setRole(res.payload.content.userRole)
               
                

                    setPending(res.payload.content.newNotifications.length) // total no of notifications unseen/un-acknowledged
                    setCount(res.payload.content.newNotifications.length)
                
                setNotifications(res.payload.content.newNotifications)
                set_refresh(false)
               
               

            })
            .catch(err => {
                //console.log(err)
            })
            

    }

    useEffect(() => {
       
        getNotifications()
        

    }, [refresh === true]);



    // for getting more notifications 
    // const fetchMoreData = () => {
    //     if (notifications.length >= count) {
    //         setHasMore(false)
    //     } else {
    //         setCount(count + 8)
    //         getNotifications(currentPage + 1)
    //     }
    // };

    const acceptInvite = (notificationType, notificationId) => {

        if (notificationType === 'Reward') {

           
            // change pending status of notification to completed
            //let status = 'seen'
            dispatch(changeNotificationStatus(notificationId))
                .then((response) => {
                    
                    if (response.payload.message === 'Notification Status has been updated successfully') {
                       
                        set_refresh(true)
                        navigate('/admin/rewards')
                           
            
                       
                    }
                })

        } else {
            let projectId = notificationType
            dispatch(acceptInviteNotification({ projectId: projectId, notificationId: notificationId }))
                .then((response) => {
                    set_refresh(true)
                    Swal({
                        title: "Accepted",
                        text: "Project Invitation Accepted",
                        icon: 'success'
                    }).then(() => {
                        redirect(projectId)
                    })
                })
                .catch(err => {
                    //console.log(err)
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }
    }

    // Redirect to a project screen from notification
    const redirect = (projectId) => {
        if (currentPath.split('/')[1] === 'researcher')
            navigate('/researcher/project/intro/' + projectId)
        else
            navigate({
                pathname: '/respondent/project-detail-view/' + projectId,
                state: { title: '', endDate: '', status: '' }
            })
    }

    const rejectInvite = (projectId, notificationId) => {
        dispatch(rejectInviteNotification({ projectId: projectId, notificationId: notificationId }))
            .then((response) => {
                set_refresh(true)
                // Swal({
                //     title: "Rejected",
                //     text: "Project Invitation Rejected",
                //     icon: 'success'
                // })
                //getMyNewNotifications(1)
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }

   

    useEffect(() => {
        const url = `${BACK_SERVER_URL}api/users/profileCompletion/${userId}`
        const request = axios.get(url)
            .then(response => {
              
                if (response.data.message === 'Profile is completed') {

                }
                else if (response.data.message === 'Profile is incomplete') {




                    setCompleteProfile(true)

                   
                   
                }
                else {

                }



            })
            .catch(err =>
                console.log(err)
            )

    }, [])


    return (
        <div className="header-dots">
            <UncontrolledDropdown>
                <DropdownToggle className="p-0" color="link">
                    <div className="icon-wrapper ">
                        <div className="" />
                        <IoIosNotificationsOutline color="#6a6a6a" fontSize="25px" />

                        {/* {newMsg && newMsg == true && */}

                        {pending > 0 &&
                            <b className='text-light bg-danger px-1 mb-4'>{completeProfile ? pending +1 : pending}</b>
                        }

                        {/* } */}
                    </div>
                </DropdownToggle>
                {/* Header */}
                <DropdownMenu end className="dropdown-menu rm-pointers" style={{ width: "350px" }}>
                    <div className="dropdown-menu-header mb-0">
                        <div className="dropdown-menu-header-inner bg-dark">
                            <div className="menu-header-image opacity-1"
                                style={{
                                    backgroundImage: "url(" + city3 + ")",
                                }} />
                            <div className="menu-header-content text-white">
                                <h5 className="menu-header-title">Notifications</h5>
                            </div>
                        </div>
                    </div>
                    {/* <div className='mt-3' style={{ textAlign: 'center' }}>
                        {completeProfile && completeProfile < 100 && userRole != 1 && userRole != 2 ? <p className='container'>Complete your profile to gain access to exciting projects <span>
                            <a href="https://app.consumerconnect.net/respondent/profile" target="" rel="noopener noreferrer">
                                <button className={`${styles3.view_profile_page_red} mt-2`}
                                >View Profile Page</button>
                            </a>
                        </span></p> : ''}
                    </div> */}
                    <div className="scroll-area-sm">
                        {

                            <PerfectScrollbar>
                                <div className="p-3">
                                    {/* Notifications */}
                                    <VerticalTimeline className="vertical-time-simple vertical-without-time" layout="1-column" id="scrollableDiv">



                                        {completeProfile &&
                                            <VerticalTimelineElement className="vertical-timeline-item">
                                                <div className="row">
                                                    <div className="col-9 align-self-center">
                                                        <h4 className="timeline-title" style={{ cursor: 'pointer' }} onClick={() => navigate('/respondent/profile')}>Complete your profile to earn points</h4>
                                                    </div>
                                                </div>
                                            </VerticalTimelineElement>
                                        }
                                        {notifications.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={notifications.length > 0 ? notifications.length : 0}


                                            >

                                                {
                                                    notifications.map((notification, index) => {
                                                        return <VerticalTimelineElement key={index} className="vertical-timeline-item">
                                                            <div className="row">
                                                                <div className="col-9 align-self-center">
                                                                    <h4 className="timeline-title" style={{ cursor: notification.status && 'pointer' }} onClick={() => notification.status && redirect(notification.project)}>{notification.description}</h4>
                                                                </div>

                                                                {
                                                                    notification.status === 'pending' &&
                                                                    <div className="col px-0 align-self-center">
                                                                        <div className="btn-group">
                                                                            {notification.type === 'Reward' ? <></> :
                                                                                <button className="btn btn-link py-0 px-2" onClick={() => rejectInvite(notification.project, notification._id)}>
                                                                                    <img src={require('../../assets/images/cross_icon.png')} width='11' height="11" />
                                                                                </button>
                                                                            }
                                                                            {notification.type === 'Reward' ?

                                                                                <button className="btn btn-link py-0 px-2" onClick={() => acceptInvite(notification.type, notification._id)}><img src={require('../../assets/images/tick_icon.png')} width='15' height="15" /></button> :

                                                                                <button className="btn btn-link py-0 px-2" onClick={() => acceptInvite(notification.project, notification._id)}>
                                                                                    <img src={require('../../assets/images/tick_icon.png')} width='15' height="15" />
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>
                                                        </VerticalTimelineElement>
                                                    })

                                                }

                                            </InfiniteScroll>
                                            :
                                            ls.get('userStatus') === 'complete' && <p className='text-center'>No Notifications</p>}
                                        {/* </div> */}
                                    </VerticalTimeline>
                                </div>
                            </PerfectScrollbar>

                        }

                    </div>
                    {/* <div className="border-top">
                        <div className="pt-2 text-center">
                            <button className="btn-pill btn btn-alternate" style={{ backgroundColor: '#0069AB', borderStyle: 'none' }} onClick={allRead}>Marked as all read</button>
                        </div>
                    </div> */}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default Notifications
