import React, { useState } from 'react'
import styles from '../../css/styling.module.css';
import { Droppable, Draggable } from "react-beautiful-dnd";
import DndIcon from '../../images/dnd_icon.png'
import CrossICon from '../../images/close_icon.png'
import Swal from 'sweetalert'
import ImageModal from '../modal/ImageModal';
import AttachementIcon from '../../images/attachement_icon.png'
import YouTube from 'react-youtube'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Label} from 'reactstrap';


const Create_Question_With_Spectrum2 = (props) => {
    const question = props.questionText
    // const [fileName, setFileName] = useState([])
    // const [isModalOpen, setModal] = useState([])
    const [isShowQuestionFileModal, setQuestionFileModal] = useState(false)
    const [typingTimer, setTypingTimer] = useState(null);
     const [youtube, setYoutube] = useState([]);
        const [isChecked, setIsChecked] = useState(false);


    // For changing text in question
    const onQuestionTextChange = (value2) => {
        const value = value2.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>');
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionText = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }

    // For changing texts in options
    const onOptionTextChange = (value, optionIndex) => {
        
        
        
      
        let pages = [...props.allContent.pages]
        
        pages[props.pageIndex].questions[props.questionIndex].pointsOptions[optionIndex].text = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }
    const onOptionPointChange = (value, optionIndex) => {
       
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].pointsOptions[optionIndex].points = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
        
    }

    // For selecting question as required
    const onChangeRequired = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].required = !pages[props.pageIndex].questions[props.questionIndex].required;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For adding additionalComment 
    // const onSelectAddComment = () => {

    //     let pages = [...props.allContent.pages]

    //     pages[props.pageIndex].questions[props.questionIndex].isComment = !pages[props.pageIndex].questions[props.questionIndex].isComment;

    //     props.setContent({
    //         ...props.allContent,
    //         pages: [...pages]
    //     })
    // }

    // For setting question in random Order
    // const onSelectRandomOrder = () => {

    //     let pages = [...props.allContent.pages]

    //     pages[props.pageIndex].questions[props.questionIndex].isRandomOrder = !pages[props.pageIndex].questions[props.questionIndex].isRandomOrder;

    //     props.setContent({
    //         ...props.allContent,
    //         pages: [...pages]
    //     })
    // }

    // For adding a new option 
    const addNewOption = () => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].pointsOptions.push({
              text: "",
               points: "",
        })

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting an option
    const delOption = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions[props.questionIndex].pointsOptions.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // For deleting a question
    const delQuestion = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // const removeOptionImage = (index) => {
    //     let files = fileName
    //     files.splice(index, 1)
    //     setFileName(files)

    //     let pages = [...props.allContent.pages]

    //     pages[props.pageIndex].questions[props.questionIndex].options[index].image = "";

    //     props.setContent({
    //         ...props.allContent,
    //         pages: [...pages]
    //     })

    // }

    // const onOptionImageSelect = (file, optionIndex) => {

    //     if (file && file[0]) {


    //         let files = fileName
    //         files.push({
    //             index: optionIndex,
    //             name: file[0].name
    //         })
    //         setFileName(files)


    //         // File Extension Check
    //         let fileExtension = file[0].name.split('.').pop();

    //         if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "mp4") {

    //             var reader = new FileReader();

    //             reader.onload = function (event) {

    //                 const imgElement = new Image();

    //                 imgElement.src = event.target.result;

    //                 imgElement.onload = function (e) {

    //                     const canvas = document.createElement('canvas');

    //                     canvas.width = e.target.width;
    //                     canvas.height = e.target.height;

    //                     const ctx = canvas.getContext('2d');

    //                     ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

    //                     const srcEncoded = ctx.canvas.toDataURL('image/jpeg', 0.5);

    //                     // Set All Content
    //                     let pages = [...props.allContent.pages]

    //                     pages[props.pageIndex].questions[props.questionIndex].options[optionIndex].questionFile = srcEncoded;
    //                     pages[props.pageIndex].questions[props.questionIndex].options[optionIndex].mediaType = fileExtension;

    //                     props.setContent({
    //                         ...props.allContent,
    //                         pages: [...pages]
    //                     })

    //                 }

    //             }

    //             reader.readAsDataURL(file[0]);

    //         }

    //     }
    // }

    // Show Image inModal
    // const showModal = (type, index, value) => {
    //     setModal([{
    //         index: index, type: type, value: value
    //     }])
    // }

    // // For changing question image
    const onQuestionImageSelect = (file) => {
        const selectedFile = file[0]; // Assuming only a single file is allowed
        const maxSize = 25 * 1024 * 1024;
        if (selectedFile && selectedFile.size <= maxSize) {
        if (file && file[0]) {

            // File Extension Check
            let fileExtension = file[0].name.split('.').pop();

            if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {

                // Set All Content
                let pages = [...props.allContent.pages]

                pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                props.setContent({
                    ...props.allContent,
                    pages: [...pages]
                })

            } else if (fileExtension === "mp4") {

                // Set All Content
                let pages = [...props.allContent.pages]

                pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                props.setContent({
                    ...props.allContent,
                    pages: [...pages]
                })
            }

        }

    }else{
        Swal({
            title: "Error",
            text: "Video size must not exceed 25MB",
            icon: 'error'
        })
    } }

    // For deleting an Question File
    const removeQuestionFile = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionFile = "";

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For embedding youtube video as a question
const onSelectEmbedVideo = (value) => {
   
    let pages = [...props.allContent.pages]

    pages[props.pageIndex].questions[props.questionIndex].questionVideo = value;

    props.setContent({
        ...props.allContent,
        pages: [...pages]
    })
}
const opts = {
    width: '640',
    height: '360',
    playerVars: {
      autoplay: 0
    },
};
 const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
      
        clearTimeout(typingTimer);
        const newTypingTimer = setTimeout(() => {
            onQuestionTextChange(newContent);
        }, 2000);
        setTypingTimer(newTypingTimer)

    }

    const addYoutube = () => {

        setYoutube([...youtube, { id: youtube.length + 1, value: '' }]);

    }
    const clearYouTube = () => {
        setYoutube([])
    }

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setIsChecked(checked);

        if (checked) {
            addYoutube();
        }
        if (!checked) {
            clearYouTube();
        }
    };


    return (
        <div className="my-0">
            <div className={`${styles.card_border} ${styles.question_card}`} style={!props.isValidQuestion ? { borderColor: '#d92550' } : {}}>

                <div className={styles.card_border_body + " pt-4 pb-1"}>


                    <div style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        <div className="d-flex">
                            <label className="mr-auto">Question # {props.questionNo}</label><br />
                            <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" onClick={() => delQuestion(props.questionIndex)} type="button">
                                <img src={CrossICon} alt="" width="15px" />
                            </button>
                            <span {...props.dragHandleProps} style={{ margin: '-1.5rem -3.3rem 0 0' }} className={styles.dnd_question}>
                                <img src={DndIcon} alt="" />
                            </span>
                        </div>

                        {/* Embedded YOutube video */}
                        <div>
                        <input className='mx-4'
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <Label>Add a Youtube Video</Label>
                    </div>
                    {youtube.map((dropDown, index) => (
                        <div key={index}>
                            <input type="text" placeholder="Write YouTube video ID here" value={props.questionVideo} className="form-control"
                                onChange={(e) => onSelectEmbedVideo(e.target.value)}
                            />

                            <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>


                        </div>
                    ))}

                        {/* Question text */}
                        <CKEditor
                            editor={ClassicEditor}
                            data={question}

                            onChange={handleEditorChange}


                        />

                    </div>

                    {/* Question Image */}
                    <div className="row mt-3">
                        <div className="col-md-6 col-sm-12 col-12">
                            <label>Add Image or Video</label>
                            <div className={`input-group mb-3 hide-file-text`}>
                                <input type="file" className="pl-1 py-1 form-control" multiple={false} accept={".jpg, .jpeg, .png, .mp4"}
                                    style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                    onChange={(e) => onQuestionImageSelect(e.target.files)}
                                />
                                {
                                    props.questionFile ?
                                        (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ?
                                            <>
                                                <div className="position-relative" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} style={{ cursor: 'pointer' }}>
                                                {<p>Video Selected</p>}
                                                    {/* <video width={200} controls className="img-fluid- border" style={{ width: "40px", height: "40px", cursor: 'pointer' }}>
                                                        <source src={props.questionFile} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video> */}
                                                </div>
                                                <ImageModal
                                                    isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                    image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                    changeImage={onQuestionImageSelect}
                                                    type="video"
                                                    // index={index}
                                                    delImage={removeQuestionFile}
                                                />
                                            </>
                                            :
                                            <>
                                                <img src={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)} alt="" width="40px" height="40px" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} />


                                                <ImageModal
                                                    isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                    image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                    changeImage={onQuestionImageSelect}
                                                    // index={index}
                                                    delImage={removeQuestionFile}
                                                />



                                            </>

                                        :
                                        <label className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                            <img src={AttachementIcon} alt="" width="20px" />
                                        </label>
                                }
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12">
                            <label>Answer Type</label>
                            <input type="text" value={props.questionType} disabled className="form-control" />
                        </div>
                    </div>

                    {/* Checkboxes */}
                    <div className=" mt-4 mx-1">

                        <Droppable droppableId={`droppable${props.questionIndex}`} type={`${props.questionIndex}`}>

                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    className="row"
                                >

                                    <div className="col-12">
                                        <label>Spectrum</label>
                                    </div>

                                    {props.pointsOptions.map((option, index) => {
                                        return <div className="col-md-6 col-sm-12 col-12 options" key={`${props.questionIndex} ${index}`}><Draggable
                                            key={`${props.questionIndex} ${index}`}
                                            draggableId={`${props.questionIndex} ${index}`}
                                            index={index}

                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >

                                                    {/* Spectrum */}

                                                    <div className={`input-group input-group-feedback mb-3 ${styles.option_input}`}>
                                                      {/* Option */}
                                                        <input type="text" placeholder="Option" value={option.text} className="form-control"
                                                            style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                            onChange={(e) => onOptionTextChange(e.target.value, index)}
                                                        />
                                                        {/* points */}
                                                         <input type="text" placeholder="Points" value={option.points} className="form-control"
                                                            style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                                            onChange={(e) => onOptionPointChange(e.target.value, index)} 
                                                        />

                                                        <div className={`input-group-append ${styles.dnd_options}`}>
                                                            <span {...provided.dragHandleProps} >
                                                                <img src={DndIcon} alt="" width="40px" />
                                                            </span>
                                                        </div>
                                                        <div className={`input-group-append`}>
                                                            <button className="btn btn-light" style={{ height: "2.5rem" }} onClick={() => delOption(index)} type="button">
                                                                <img src={CrossICon} alt="" width="15px" />
                                                            </button>
                                                        </div>
                                                    </div>


                                                </div>
                                            )}
                                        </Draggable>
                                        </div>

                                    })}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-light rounded-circle float-right" onClick={() => addNewOption()} type="button">+</button>
                            </div>
                        </div>

                    </div>

                    <div className="d-flex">
                        {/* Required */}
                        <div className="custom-checkbox custom-control pb-4 col-md-3 col-6">
                            <input type="checkbox" id={`required ${props.questionIndex}`} className="custom-control-input" checked={props.required} onChange={() => onChangeRequired()} />
                            <label className="custom-control-label" htmlFor={`required ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Required</span>
                            </label>
                        </div>

                        {/* Additional Comment Selection */}
                        {/* <div className="custom-checkbox custom-control pb-4 mr-3">
                            <input type="checkbox" id={`additionalComment ${props.questionIndex}`} className="custom-control-input" value={props.additionalComment} onChange={(e) => onSelectAddComment()} />
                            <label className="custom-control-label" htmlFor={`additionalComment ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Additional Comment Required</span>
                            </label>
                        </div> */}

                        {/* Random Order Selection */}
                        {/* <div className="custom-checkbox custom-control pb-4 mr-3">
                            <input type="checkbox" id={`randomOrder ${props.questionIndex}`} className="custom-control-input" value={props.randomOrder} onChange={(e) => onSelectRandomOrder()} />
                            <label className="custom-control-label" htmlFor={`randomOrder ${props.questionIndex}`}>
                                <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Randomized Order</span>
                            </label>
                        </div> */}
                    </div>

                    {/* Validation error */}
                    <div className="col-12">
                        {!props.isValidQuestion && (
                            <small className="text-danger">
                                Please complete this question.
                            </small>
                        )}
                    </div>

                </div>
            </div>
        </div>


    )
}

export default Create_Question_With_Spectrum2


