import React, { useEffect, useState } from 'react';
import { getActivityVideo, getActivityQuestionImage, getActivityVideo2 } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from '../../css/styling.module.css';
import YouTube from 'react-youtube';
import Dropzone from "react-dropzone";
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';
const Question_With_Image_Text2 = (props) => {

    const [answerValue, setAnswerValue] = useState('')
    // On image Select
    const onImageSelect = (file) => {
        //console.log('image file answer func ->', file)
                if (file) {
        
                    // Remove Error Message
                    document.getElementById(`error-msg-${props.id}`).innerHTML = "";
        
                    for (let i = 0; i < file.length; i++) {

                        // File Extension Check
                        let fileExtension = file[i].name.split('.').pop();
        
                        if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {
        
        
                            
        
        
        
        
                            if (props.allContent.content[props.categoryIndex].questions[props.questionIndex].image) {
                               
                                props.allContent.content[props.categoryIndex].questions[props.questionIndex].image.push(file[i]);
                               
                            } else {
                              
        
        
                                props.allContent.content[props.categoryIndex].questions[props.questionIndex].image = [file[i]]
                            }
        
                            props.allContent.content[props.categoryIndex].questions[props.questionIndex].q = props.questionNo;
                            props.setContent({ ...props.allContent });
        
                        }
                    }
                
        
                }
        
                else {
        
                    document.getElementById(`error-msg-${props.id}`).innerHTML = `
                        <div class="my-2">
                            <div class="alert alert-danger mb-0">
                                Invalid file type
                            </div>
                        </div>
                    `;
                }
        
            }

    // on Cancel Image
    const onCancelImg = (index) => {
        props.allContent.content[props.categoryIndex].questions[props.questionIndex].image.splice(index, 1);
        props.setContent({ ...props.allContent });
    }

    // On Change Text
    const onChangeText = (e) => {
       
        

        props.allContent.content[props.categoryIndex].questions[props.questionIndex].answer = e.target.value
        props.setContent({ ...props.allContent });
        

    }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };

    const params = useParams();
    const userId = params.UserId
    const dispatch = useDispatch();
    const catId = props.categoryId
    const questionIndex = props.questionIndex
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if(props.questionFile && props.questionFile !==''){
        if (params.surveyid || params.ActivityId || params.activityid) {
            //console.log('cat id, ques index ->',catId, questionIndex)
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {

                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('video not available') }
                    setIsLoading(false);

                })
                .catch(err=>err)

            // dispatch(getActivityQuestionImage(params.ActivityId || params.surveyid || params.activityid))
            //     .then((response) => {
            //         try {

            //             setQImage(response.payload.content.fileData)
            //         } catch (err) { console.log('image in questionnaire not available') }
            //         setIsLoading(false);
            //     })
            //     .catch(err=>err)



        }
    }
        // else if (params.evaluationid) {
        //     dispatch(getActivityVideo2(params.evaluationid, ))
        //         .then((response) => {
        //             try {

        //                 setQVideo(response.payload.content.fileData)
        //             } catch (err) { console.log('video not available') }
        //             setIsLoading(false);

        //         })
        //         .catch(err=>err)
        //     dispatch(getActivityQuestionImage(params.evaluationid))
        //         .then((response) => {
        //             try {
        //                 //console.log('question image long text in respondant side survey->', response.payload.content.fileData)
        //                 setQImage(response.payload.content.fileData)
        //             } catch (err) { console.log('image in questionnaire not available') }
        //             setIsLoading(false);
        //         })
        //         .catch(err=>err)
        // }
    }, []);

    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }
    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {/* {props.questionText} */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }

                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                    <b>Loading Video...</b>
                                                ) : qVideo.data ? (
                                                    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                        <ReactPlayer
                                                            url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                            controls
                                                            width='100%'
                                                        />
                                                    </div>
                                                ) : (
                                                    <b>Loading Video....</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <div className="mb-3 mt-3">
                                        {/* Image Display */}
                                        {qVideo.data &&

                                            // Image Display of question
                                            <div>
                                                <div className="position-relative">
                                                    <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                        </>


                    }


                    <div className="mt-3 mx-3">

                        <div className="form-group mt-1 mb-3">
                            <label>Answer Field</label>

                            {/* Text Limit */}
                            {props.textLimit && !Number.isNaN(parseInt(props.textLimit.max)) && (
                                <small className="text-danger mx-1">(Max Character Limit: {props.textLimit.max})</small>
                            )}

                            <input className="form-control" type="text" value={props.answer} onChange={onChangeText} placeholder="Write here" />
                        </div>

                        {/* File Limit and File Size */}
                        <small className="text-danger">
                            Max files Limit: {props.filesLimit ? props.filesLimit : "1"} | Max file Size: {props.filesSize ? props.filesSize : "1"} MB
                        </small>

                        {/* Image Display & Dropzone */}
                        {props.image && props.image.length > 0 ? (

                            // Image Display
                            <div className="row pt-3">

                                {props.image.map((imgSrc, index) => {
                                    return (
                                        <div key={index} className="position-relative mx-3 mb-3">
                                            <span className="questionImg_close" onClick={() => onCancelImg(index)}>&times;</span>
                                            <img src={typeof imgSrc === 'string' ? imgSrc : URL.createObjectURL(imgSrc)} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="survey-img" width="200px" />
                                        </div>
                                    )
                                })}

                            </div>

                        ) : (

                            // Image Drop Zone 
                            < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                                <Dropzone

                                    maxFiles={!Number.isNaN(parseInt(props.filesLimit)) ? parseInt(props.filesLimit) : 0}
                                    maxSize={!Number.isNaN(parseFloat(props.filesSize)) ? (parseFloat(props.filesSize) * 1048576) : (1048576)}
                                    multiple={!Number.isNaN(parseInt(props.filesLimit)) ? (parseInt(props.filesLimit) > 0 ? true : false) : false}
                                    accept={".jpg, .jpeg, .png"}
                                    onDrop={file => onImageSelect(file)}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>

                                                <div className="d-flex">
                                                    <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>
                                                    <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Try dropping image here, or click to select image to upload.</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                        )}

                    </div>

                    {/* Upload related error */}
                    <div id={`error-msg-${props.id}`}></div>

                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div >
    )

}

export default Question_With_Image_Text2;