import React, { useState, useEffect } from 'react'
import Projecticon from '../../../assets/images/project_header_icon.png'
import Eyeicon from '../../../assets/images/eye_icon.png'
import Deleteicon from '../../../assets/images/delete_icon.png'
import Editicon from '../../../assets/images/edit_icon.png'
import TickIcon from '../../../assets/images/tick_icon.png'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import { useNavigate } from 'react-router'
import ResizeDetector from "react-resize-detector";
import Listing from '../../../assets/components/listing/Listing'
import GraphIcon from '../../../assets/images/graph_icon.png'
import AlertModal from '../../../assets/components/modal/AlertModal'
import CopyIcon from '../../../assets/images/copy_icon.png'
import { delActivity, getQuickSurvey, statusComplete_Activity } from '../../../actions/activities_actions'
import Loader from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'

const MiniSurveys = () => {

    const [delId, setDelID] = useState()
      const [tickId, setTickID] = useState()
    const history = useNavigate()
    const [isLoading, setLoading] = useState(true)
    const activityState = useSelector(state => state.Activity)
    const dispatch = useDispatch()
    const [surveyColumn, setSurveyColumn] = useState([
        { Name: 'Title', Type: 'string', key: 'name' },
        { Name: "Description", Type: 'string', key: 'description' },
        { Name: "Dates", Type: 'qsdates', key: 'dates' },
        // { Name: "Start Date", Type: 'qsstartdate', key: 'startDate' },
        // { Name: "End Date", Type: 'qsenddate', key: 'endDate' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: "/researcher/minisurvey-activity",
                    type: 'button',
                },
                {
                    icon: GraphIcon,
                    url: '/researcher/minisurvey-analytics',
                    type: 'button',
                },
                {
                    icon: Editicon,
                    url: "/researcher/edit-minisurvey",
                    type: 'button'
                },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },
                {
                    icon: TickIcon,
                    setID: setTickID,
                    type: 'complete'
                }
            ]
        }

    ]);

    const [surveyData, setSurveyData] = useState([])

    const [searchString, setSearchString] = useState('')
    const [searchedSurveyResult, setSearchedSurveyData] = useState([])

    // Search surveys through survey name in listing
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredSurvey = surveyData.filter(row => {
            return (
                search.test(row.name)
            );
        });
        setSearchedSurveyData(filteredSurvey);
    }, [searchString]);

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    useEffect(()=>{

        if(tickId){
            dispatch(statusComplete_Activity(tickId))
                .then(response => {
                    //setDelID()
                    
                    if(response.payload.status === 200 && response.payload.data.message ==='Activity Marked Completed'){
        
                    
                        getSurveyListing(activityState.currentPage, 5)
                    Swal({
                        title: "Completed",
                        text: "Activity marked completed",
                        icon: 'success'
                    });
                   
                }
                else if(response.payload.data.message ==='Activity Already Marked Completed'){
                    Swal({
                        title: "Info",
                        text: "Activity already marked completed",
                        icon: 'info'
                    });
                }
                })
                .catch(err => {
                    Swal({
                        title: err.content ? err.content : "Error",
                        text: "Please try again!",
                        icon: 'error'
                    });
                })
            }
            },[tickId])

    // Show / hide delete modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    const getSurveyListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getQuickSurvey({ page: currentPage, limit: dataLimit }))
            .then(response => {
               
                setSurveyData([...response.payload.content.activities])
                setLoading(false)
            })
            .catch(err => {
                //console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        getSurveyListing()
    }, [])

    const deleteActivity = () => {
        dispatch(delActivity(delId))
            .then(response => {
                setDelID()
                getSurveyListing(activityState.currentPage, 5)
                Swal({
                    title: "Deleted",
                    text: "Activity Deleted",
                    icon: 'info'
                });
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }


    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>


                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={deleteActivity} />

                        <>
                            <div className="project_header">
                                <PageHeader
                                    heading="Quick Surveys" img={Projecticon}
                                    btnText={width <= 350 ? "Create" : "Create Quick Survey"}
                                    btnClick={() => history("/researcher/create-minisurvey", { editMode: false })}
                                />
                            </div>
                            <Card className="main-card mb-3">
                                <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                    <div className="text-white menu-header-title text-capitalize">Survey List</div>

                                    <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                        <ul className="navbar-nav mr-auto">
                                        </ul>
                                        <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                            <div className="input-group input-group-sm w-75">
                                                <div className="input-group-prepend border-0">
                                                    <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                </div>
                                                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                    onChange={(e) => setSearchString(e.target.value)} />
                                            </div>

                                        </div>
                                    </div>
                                </nav>

                                {/* Loader */}
                                <LoadingOverlay tag="div" active={isLoading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "#fff",
                                            opacity: 0.5,
                                        }),
                                    }}
                                    spinner={
                                        <Loader color="#6c757d" active type="ball-pulse" />
                                    }>
                                    <CardBody className="p-0">

                                        {/* Listing */}
                                        <div className="table_border">
                                            <Listing columnName={surveyColumn}
                                                data={(searchString.length > 0 || searchedSurveyResult.length > 0) ? searchedSurveyResult : surveyData && surveyData.length >0 ? surveyData:<></>}
                                                currentPage={activityState.currentPage ? activityState.currentPage : 1}
                                                totalPages={activityState.totalPages ? activityState.totalPages : 1}
                                                getListingData={(currentPage, dataLimit) => getSurveyListing(currentPage, dataLimit)}
                                                startLoading={() => setLoading(true)}
                                            />
                                        </div>

                                    </CardBody>
                                </LoadingOverlay>
                            </Card>
                        </>

                    </TransitionGroup>
                </>
            )}
        />
    )
}

export default MiniSurveys
