import React, { useState, useEffect, useContext } from 'react';
import styles3 from '../../assets/css/newStyling.module.css';
import styles from '../../assets/css/rstyling.module.css';
import landing_page_logo from '../../assets/images/landing_page_logo.png'
import login_bg_new from "../../assets/images/login_bg2.png";
import ResizeDetector from "react-resize-detector";
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from 'reactstrap';
import { signupOTP, getLoggedInUser } from '../../actions/auth_actions'
import { userDetailsContext } from '../../UserDetailsProvider';
import SecureLS from 'secure-ls';

export default function OtpForm2() {



   
    const dispatch = useDispatch()
    let { search } = useLocation();
    const history = useNavigate();
    const ls = new SecureLS()
    const query = new URLSearchParams(window.decodeURIComponent(search));
    const {userDetails, setUserDetails} = useContext(userDetailsContext);

    

    const [userData, setUserData] = useState({
        
        otp: '',
    })

 
 
 
    const OTPsubmit = (values) => {
        //console.log('otp ->', values)
        
        
                dispatch(signupOTP(values))
        
                    .then(response => {
        
                        if (response.payload.message == 'OTP code is verified') {
                            {
                                Swal({
                                    title: "OTP",
                                    text: " OTP code is verified",
                                    icon: 'success',
                                })
        
                                ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role })
                                
                                ls.set('accessUser', { user: response.payload.content.user })
        
                                axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                            }
                            dispatch(getLoggedInUser())
                            .then(response1 => {
                              
                                if(response.payload.content.role === 2) {
                                    history('/researcher')
                                }
                               else if(response.payload.content.role === 1) {
                                    history('/admin')
                                }
                                else if(response.payload.content.role === 3) {
                                    history('/set_up_profile')
                                }
                            })
                            // if(response.payload.content.google_id || response.payload.content.facebook_id){
                           
                            // }
                            // else{history(`/login`)}
        
        
                        }
                        else {
                            Swal({
                                title: "OTP",
                                text: " OTP code is expired. Try again",
                                icon: 'error'
                            })
                            history('/otp-again')
        
                        }
                        if (response.payload.message == 'Wrong OTP') {
                            Swal({
                                title: "Wrong OTP",
                                text: "This OTP code is wrong.Try again",
                                icon: 'error',
                            })
                            history('/otp-again')
                        }
        
                    }
        
                    )
                    .catch(err => {
        
        
        
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Something went wrong!",
                            icon: 'error'
                        });
                    }
        
                    )
        
        
        
        
        
            }



    return (
        <div className="container-fluid p-2 px-4">
            <div className='row justify-content-center'>
                <div className={`row p-2 ${styles3.login_container}`}>

                    <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                        <div className={`${styles3.login_img}`}
                            style={{
                                backgroundImage: "url(" + login_bg_new + ")",
                            }} />


                    </div>

                    <div className="col-sm-7 col-12 bg-white align-self-center">
                        <div className='row justify-content-center'>
                            <div className={`${styles3.login_section}`}>
                                {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                <div className=" container" >



                                    <div className="container text-center">
                                        <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                        <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop: '50px' }}>OTP Verification.</p>
                                        <p style={{ color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal' }}>We have sent you an OTP code on your email.</p>

                                        <Formik
                                                initialValues={userData}
                                                enableReinitialize={true}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setSubmitting(true)
                                                    OTPsubmit(values)

                                                }}


                                                const validationSchema={Yup.object().shape({

                                                    otp: Yup.string().required("OTP code is required"),

                                                })}


                                            >
                                           {props => {
                                                    const {
                                                        values,
                                                        touched,
                                                        errors,
                                                        isSubmitting,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                        setFieldValue
                                                    } = props;

                                                return (
                                                    <div>


                                                        <form autoComplete="off" onSubmit={handleSubmit}>
                                                            <div>

                                                            <Input type="text"
                                                                                    className={errors.otp && touched.otp ? styles.is_invalid : 'valid'}
                                                                                    name="otp"
                                                                                    placeholder="Enter 6 digits OTP code"
                                                                                    onChange={(e) => setFieldValue('otp', e.target.value.trim())}
                                                                                    onBlur={handleBlur}
                                                                                    value={values.otp}
                                                                                />
                                                                 {errors.otp && touched.otp && (
                                                                                    <small className='text-danger'>
                                                                                        {errors.otp}
                                                                                    </small>
                                                                                )}
                                                            </div>





                                                            <button className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} type="submit">
                                                                Verify OTP
                                                            </button>
                                                        </form>

                                                    </div>
                                                )

                                            }}
                                        </Formik>









                                    </div>
                                </div>
                            </div>
                        </div>










                    </div>
                </div>
            </div>
        </div>
    )
}