import React, { useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { CardHeader, Card, CardBody } from "reactstrap";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import Avatar from '../../../../../assets/images/profile_placeholder.png'
import LikeIcon from '../../../../../assets/images/like_icon.png'
import CommentIcon from '../../../../../assets/images/comment_icon.png'
import PostIcon from '../../../../../assets/images/post_icon.png'
import PageHeader from '../../../../../assets/components/pageheader/PageHeader';
import AttachementIcon from '../../../../../assets/images/attachement_icon.png'
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import { getPostReports } from '../../../../../actions/activities_reply_actions';


const ForumReports = () => {

    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [report, setReport] = useState([])

    const getReport = () => {
        dispatch(getPostReports({ activityId: params.activityid }))
            .then((response) => {

                setReport(response.payload.content.reports)
                setLoading(false)
            })
            .catch(err => {
               // console.log(err)
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        getReport()
    }, [])

    return (

        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                <div className="project_header"><PageHeader
                    heading="Reports" img={AttachementIcon}
                /></div>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                {/* Card */}
                <div style={{ userSelect: 'none' }}>
                    <Card className="main-card mb-3">
                        <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem', backgroundColor: '#006FA7', color: 'white' }}>
                            Forum
                        </CardHeader>

                        <CardBody>
                            <VerticalTimeline layout="1-column"
                                className="vertical-time-simple vertical-without-time">
                                {/* Responses Card */}
                                <div className="my-3 " >
                                    {
                                        report.length > 0 ? report.map((response, i) => {
                                            return <VerticalTimelineElement className="vertical-timeline-item" key={i}>
                                                <div className="my-3" >
                                                    <div className="d-flex mb-2">

                                                        <div className="d-flex mr-auto">
                                                            <img src={response.author && response.author.profileImage ? response.author.profileImage : Avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                                            <h4 className="timeline-title mb-0 align-self-center" style={{ fontWeight: '500' }}>{response.author.userName}</h4>
                                                        </div>
                                                        <div className="text-right">
                                                            <small className="text-muted" style={{ fontWeight: '500' }}>{response.date}</small>
                                                        </div>
                                                    </div>
                                                    <div className="my-3 ml-4 d-flex">
                                                        <div className="card mb-3 widget-chart widget-chart2 text-left shadow-none mr-2" style={{ padding: '0.5rem 1rem', border: '2px solid #eee' }}>
                                                            <div className="widget-chart-content">

                                                                <div className="widget-chart-flex">
                                                                    <div className="widget-numbers text-warning my-auto">
                                                                        <img src={PostIcon} alt="" width="40" />
                                                                    </div>
                                                                    <div className="widget-description ml-auto text-info px-2">
                                                                        <p className="text-center m-0 p-0" style={{ fontSize: '1.5rem', color: '#ccc' }}>{response.posts}</p>
                                                                        <span className="m-0 p-0 text-center" style={{ fontSize: '1rem', color: '#ccc' }} >Posts</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3 widget-chart widget-chart2 text-left shadow-none mr-2" style={{ padding: '0.5rem 1rem', border: '2px solid #eee' }}>
                                                            <div className="widget-chart-content">

                                                                <div className="widget-chart-flex">
                                                                    <div className="widget-numbers text-warning my-auto">
                                                                        <img src={CommentIcon} alt="" width="40" />
                                                                    </div>
                                                                    <div className="widget-description ml-auto text-info px-2">
                                                                        <p className="text-center m-0 p-0" style={{ fontSize: '1.5rem', color: '#ccc' }}>{response.comments}</p>
                                                                        <span className="m-0 p-0 text-center" style={{ fontSize: '1rem', color: '#ccc' }} >Comments</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </VerticalTimelineElement>

                                        }) :
                                            <div className={"pb-2 col-12"}>
                                                <div className="row px-3">
                                                    <div className="col -12 ]mt-3 align-self-center text-center">
                                                        <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Report Available</span>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                            </VerticalTimeline>
                        </CardBody>
                    </Card>
                </div>
            </TransitionGroup >
        </LoadingOverlay>

    )



}

export default ForumReports

