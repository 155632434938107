import React, { useEffect, useState, useContext } from 'react'
import logo from '../../assets/images/logo.png'
import styles from '../../assets/css/rstyling.module.css'
import { IoIosEye } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io';
import landing_page_logo from '../../assets/images/landing_page_logo.png';
import login_bg_new from "../../assets/images/login_bg2.png";
import { IoIosEyeOff } from 'react-icons/io'
import login_bg from "../../assets/images/login_bg.jpg";
import { useDispatch, useSelector } from 'react-redux'
import SecureLS from 'secure-ls';
import styles3 from '../../assets/css/newStyling.module.css';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Minimal_Login } from '../../actions/auth_actions';
import axios from 'axios'
import behbud_logo from '../../assets/images/behbud_logo.png'
import { Input, Button } from 'reactstrap'


const Minimal_login = (props) => {
    const { ActivityId } = useParams();
    const [userData, setData] = useState({
        phone: '',

    })
    const [showPassword, setPassword] = useState(false)
    const [pageToBeRedirected, setRedirectionPage] = useState('/login')

    const history = useNavigate()
    const dispatch = useDispatch()
    const naivgate = useNavigate()
    const [toggle, setToggle] = useState('Login')
    const params = useParams()
    const activityId = params.ActivityId
    const state = useState()
    const ls = new SecureLS()
    //const {userDetails, setUserDetails} = useContext(userDetailsContext);
    const clearCookies = () => {
        if (document.cookie) {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        }
    };

    const clearCaches = async () => {
        // Log caches before clearing
        if ('caches' in window) {
            const cacheNamesBefore = await caches.keys();
            //console.log('Caches before clearing:', cacheNamesBefore);
        }

        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();

        // Clear service worker caches
        if ('caches' in window) {
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map((name) => caches.delete(name)));

            // Log caches after clearing
            const cacheNamesAfter = await caches.keys();
            //console.log('Caches after clearing:', cacheNamesAfter);
        }
    };

    useEffect(() => {
        //setRedirectionPage(props.location.pathname) // if comes here after clicking a link

        clearCaches();
        history(`/minimal_login/${ActivityId}`)

    }, []);


    const onSubmit = (values) => {


        dispatch(Minimal_Login(values))
            .then(response => {
                if (response.payload.message === 'User not found') {
                    Swal({
                        title: "Error",
                        text: "User not found!",
                        icon: 'error'
                    });
                }
                else if (response.payload.message === 'Minimal Login Successful') {
                  
                    ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role, userPhone: response.payload.content.userPhone, userFullName: response.payload.content.userFullName, userTotalVisits: response.payload.content.totalReplies === response.payload.content.activitiesDone ? response.payload.content.activitiesDone : 'N/A' });


                    ls.set('refreshToken', response.payload.content.refreshToken);
                    ls.set('userStatus', response.payload.content.status)
                    let userId = response.payload.content.userId

                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;

                    if (ls.get('accessToken').role === 3) {

                        let replies_till_yet = response.payload.content.totalReplies
                        let activities_till_yet = response.payload.content.activitiesDone
                        // re route to patients surveys using static surveys ids depending upon the activities done till yet
                        // activitiesDone === 0 ? survey 1 id
                        // activitiesDone === 1 ? survey 2 id
                        // activitiesDone === 2 ? survey 3 id
                        if (activities_till_yet === 0 && replies_till_yet === 0) {
                            naivgate(`/sharable-quick-survey/671615207cb78802148dfb58/${userId}`)
                        }
                        else if (activities_till_yet === 1 && replies_till_yet === 1) {
                            naivgate(`/sharable-quick-survey/671615207cb78802148dfb59/${userId}`)
                        }
                        else if (activities_till_yet === 2 && replies_till_yet === 2) {
                            naivgate(`/limit-exceeded`)
                        }

                    }


                }
            })
            .catch(err => {
                Swal({
                    title: "Error",
                    text: "Incorrect Phone Number/Cnic. Please try again!",
                    icon: 'error'
                });
            })


    }







    return (

        <>
            <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>
                        <Formik
                            initialValues={userData}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true)
                                onSubmit(values)

                            }}


                            const validationSchema={Yup.object().shape({
                                phone: Yup.number().required("Phone Number/Cnic is required for login"),

                            })}


                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                } = props;
                                return (

                                    <div className="col-sm-7 col-12 bg-white align-self-center">
                                        <div className='row justify-content-center'>
                                            <div className={`${styles3.login_section}`}>

                                                <div className=" container" >



                                                    <div className="container text-center">
                                                        <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={behbud_logo} alt="logo" width="200px" />

                                                        <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem' }}>Welcome Back.</p>
                                                        <p style={{ color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal' }}>Please enter patient phone number/Cnic to login and see the survey.</p>
                                                        <form onSubmit={handleSubmit}>
                                                            {/* <div className="my-4">
                                                                <FormGroup className="">
                                                                   
                                                                    <Input type="text"
                                                                        className={errors.email && touched.email ? styles.is_invalid : 'valid'}
                                                                        style={{ paddingTop: '28px', paddingBottom: '28px' }}
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        onChange={(e) => setFieldValue('email', e.target.value.trim())}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}


                                                                    />

                                                                    {errors.email && touched.email && (
                                                                        <small className='text-danger' style={{
                                                                            position: 'relative',
                                                                            top: '-25px',
                                                                            right: '-131px'
                                                                        }}>
                                                                            {errors.email}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div> */}

                                                            <div className="form-group">




                                                                <div className="">
                                                                    <div className="">
                                                                        {/* <PhoneInput
                                                                            inputStyle={{ width: '100%', height: '38px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                            country={'pk'}
                                                                            value={values.phone}
                                                                            onChange={(phone, country) => {

                                                                                setFieldValue('phone', phone)
                                                                            }}
                                                                            enableSearch={true}
                                                                            inputProps={{
                                                                                autoComplete: 'off',
                                                                              }}

                                                                        /> */}
                                                                        <Input type="text"
                                                                            className={errors.address && touched.address ? styles.is_invalid : 'valid'}
                                                                            name="phone"
                                                                            placeholder="Phone/CNIC"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.phone}
                                                                        />

                                                                        {errors.phone && touched.phone && (
                                                                            <small className='text-danger'>
                                                                                {errors.phone}
                                                                            </small>
                                                                        )}
                                                                    </div>


                                                                </div>

                                                            </div>



                                                            <button className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} type="submit" onClick={() => onSubmit}>
                                                                Login with Phone Number/Cnic
                                                            </button>
                                                        </form>
                                                        <div className='row'>
                                                            <div className='col'><hr></hr></div>
                                                            <div className='col-2'><div className='py-2' style={{ color: 'rgb(173, 167, 167)', fontWeight: 'bold' }}>Or</div></div>
                                                            <div className='col'><hr></hr></div>
                                                        </div>


                                                        <p className="mt-4">New Patient? <span className={styles.text_blue}><a href={`/minimal_setup_profile/${ActivityId}`} style={{ color: '#006FA7' }}>
                                                            Click here
                                                        </a></span> </p>












                                                    </div>

                                                </div>
                                            </div>
                                        </div>










                                    </div>

                                )
                            }}

                        </Formik>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Minimal_login;
