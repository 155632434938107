import React, { useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from "reactstrap";
import cx from "classnames";
import styles from '../../../../assets/css/rstyling.module.css'
import { Typeahead } from "react-bootstrap-typeahead";



// ************************** This screen is removed from the UI ******************************

const SheduleInterview = (props) => {
    const [state, setState] = useState({
        checked: false,
        isToggleOn: true,
    })
    const handleClick = () => {
        setState(function (prevState) {
            return { isToggleOn: !prevState.isToggleOn };
        });
    }
    const [isModalOpen, setModal] = useState(false);
    const [selectedCWE, setSelectedCWE] = React.useState([]);
    const CWE = ['abc@gmail.com','ggh@gmail.com','xcv@gmail.com']

    const toggle = () => {
        props.setModal(!props.isModalOpen)
    }
    //console.log(props)

    return (
     
        <div className="d-inline-block mb-2 mr-2 modal_form">
            <Modal isOpen={props.isModalOpen} toggle={() => toggle()} size={'lg'} className="modal-dialog-centered shadow-none">
                <ModalHeader toggle={() => toggle()} className="bg-white">Shedule an Interview</ModalHeader>
                <ModalBody>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <FormGroup>
                                    <Label for="brandName">Purpose of Discussion</Label>
                                    <Input type="text" name="brandName" id="brandName" placeholder="Brand Name here" />
                                </FormGroup>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormGroup>
                                    <Label for="date">Date</Label>
                                    <Input type="date" name="date" id="date" />
                                </FormGroup>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormGroup>
                                    <Label for="time">Time</Label>
                                    <Input type="time" name="time" id="time" placeholder="Select Time" />
                                </FormGroup>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormGroup>
                                    <Label for="duration">Duration (in minutes)</Label>
                                    <Input type="date" name="duration" id="duration" />
                                </FormGroup>
                            </div>
                            <div className="col-md-6 col-sm-12">
                            <Label>Session</Label><br/>
                                <FormGroup check inline className="align-self-center">
                                    <div check>
                                        <Input type="checkbox" /><span className="mr-2">Online</span>
                                        <Input type="checkbox" /><span className="mr-2">Offline</span>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input type="password" name="password" id="password" />
                                </FormGroup>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <FormGroup className="light_typeahead custom_drop">
                                    <Label for="password">Time</Label>
                                    <Typeahead
                                    id="CWE"
                                    labelKey="CWE"
                                    multiple
                                    onChange={setSelectedCWE}
                                    options={CWE}
                                    placeholder="CWE Income Range"
                                    selected={selectedCWE}
                                />
                                </FormGroup>
                            </div>
                            <div className="col-md-6 col-12 align-self-center mt-3 d-flex">
                                <div className="switch has-switch has-switch-sm mb-2 mr-2" data-on-label="ON"
                                    data-off-label="OFF" onClick={handleClick}>
                                    <div className={cx("switch-animate", {
                                        "switch-on": state.isToggleOn,
                                        "switch-off": !state.isToggleOn,
                                    })}>
                                        <input type="checkbox" />
                                        <span className="switch-left">ON</span>
                                        <label>&nbsp;</label>
                                        <span className="switch-right">OFF</span>
                                    </div>
                                </div>
                                <div>Save Recording</div>
                            </div>
                            <div className="col-md-6 col-sm-12 align-self-center pt-3 text-right">
                                <Button className={`${styles.bg_blue} text-white mr-2`}>Add</Button>
                                <Button className={`btn-light text-muted`} style={{ border: '1px solid #6c757d ' }}>Reset</Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>

    )
}

export default SheduleInterview
