import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransitionGroup } from 'react-transition-group';
import { getSpecificOption, cityOptions } from "../../../../actions/settings_actions";
import Swal from 'sweetalert'
import styles from '../../../../assets/css/styling.module.css';
import { Card, CardBody, FormGroup, Input, Label, } from 'reactstrap';
const Demographics = (props) => {

    const { profileDetails, setProfileDetails } = props
 
    const labelStyle = {
        color: '#000',
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '0.3px'
    }


    const [err, setError] = useState({
        city: '',
        country: '',
        gender: '',
        birthday: '',
        maritalStatus: '',
        education: '',
        employment: '',
        seniority: '',
        occupation: '',
        income: ''
    })

    const removeErr = () => {
        setError({
            city: '',
            country: '',
            gender: '',
            birthday: '',
            maritalStatus: '',
            education: '',
            employment: '',
            seniority: '',
            occupation: '',
            income: '',
            area: ''
        })
    }


    const checkValidation = () => {
        removeErr()
        let notCompleted = false;
        if (profileDetails.city === '') {
            setError((prevState) => ({ ...prevState, city: 'City is required.' }))
            notCompleted = true
        }
        if (profileDetails.country === '') {
            setError((prevState) => ({ ...prevState, country: 'Country is required.' }))
            notCompleted = true
        }
        if (profileDetails.gender === '') {
            setError((prevState) => ({ ...prevState, gender: 'Gender is required.' }))
            notCompleted = true
        }
        if (profileDetails.birthday === '') {
            setError((prevState) => ({ ...prevState, birthday: 'Date of Birth is required.' }))
            notCompleted = true
        }
        if (profileDetails.maritalStatus === '') {
            setError((prevState) => ({ ...prevState, maritalStatus: 'Marital Status is required.' }))
            notCompleted = true
        }
        if (profileDetails.education === '') {
            setError((prevState) => ({ ...prevState, education: 'Education is required.' }))
            notCompleted = true
        }
        if (profileDetails.employment === '') {
            setError((prevState) => ({ ...prevState, employment: 'Employment is required.' }))
            notCompleted = true
        }
        if (profileDetails.seniority === '') {
            setError((prevState) => ({ ...prevState, seniority: 'Seniority is required.' }))
            notCompleted = true
        }
        if (profileDetails.occupation === '') {
            setError((prevState) => ({ ...prevState, occupation: 'Occupation is required.' }))
            notCompleted = true
        }
        if (profileDetails.income === '') {
            setError((prevState) => ({ ...prevState, income: 'Income Range is required.' }))
            notCompleted = true
        }
        if (profileDetails.address === '') {
            setError((prevState) => ({ ...prevState, area: 'Area is required.' }))
            notCompleted = true
        }


        return !notCompleted
    }

    const onSaveDemographics = () => {
        if (checkValidation()) {
            props.editUser()
        }
    }

    const [isAllowEdit, setAllowEdit] = useState(false);


    const dispatch = useDispatch()
    const systemOptionsState = useSelector(state => state.SystemSettings)
    const [options, setOptions] = useState([])
    const [cities, setCities] = useState([])
    const [incomes, setIncomes] = useState([])
    const [educations, setEducations] = useState([])

    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Education", "Employment", "Marital Status", "Relationship", "Seniority", "Countries", "Income", "Cities"] }))
            .then(response => {
                //console.log(response)
                // setOptions(response)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                });
            })
    }
    useEffect(() => {
        if (systemOptionsState.specificOptions.length === 0) {
            getSystemOptions()
        }
    }, [])

    useEffect(() => {
        setOptions(systemOptionsState.specificOptions)
    }, [systemOptionsState])

    const [ageErr, setAgeErr] = useState('')

    // check for min age 13
    const onSelectDOB = (dateString) => {
        setAgeErr()
        if (dateString != "") {
            const today = new Date();
            const birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (age < 13) {
                setAgeErr("Min age required 13")

            } else {
                setProfileDetails({
                    ...profileDetails,
                    birthday: birthDate.getFullYear() + "-" + ("0" + (birthDate.getMonth() + 1)).slice(-2) + "-" + birthDate.getDate(),
                    age: age
                })

            }
        }
    }
    const [birthday, setBirthday] = useState()
    useEffect(() => {
        const birthDate = new Date(profileDetails.birthday)
        setBirthday(birthDate.getFullYear() + "-" + ("0" + (birthDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (birthDate.getDate())).slice(-2))
    }, [profileDetails.birthday])


    const onSelectCountry = (value) => {



        dispatch(cityOptions(value))
            .then(response => {

                setCities(response.payload.content.cities)
                setIncomes(response.payload.content.incomes)
                setEducations(response.payload.content.educationData)
            })
            .catch(err => {
                console.log('err')
            })
    }


useEffect(() =>{
    
    onSelectCountry(profileDetails.country)
},[profileDetails.country])


    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <div className="mb-3">

                {/* Edit */}
                <div className="clearfix px-3">
                    <div className="text-right">
                        <button className="btn btn-link" style={{ color: "#1c6f9f", fontSize: '1.05rem', fontWeight: 600, letterSpacing: "0.5px" }} onClick={() =>  setAllowEdit(true)}>Edit</button>
                    </div>
                </div>

                <div className={styles.card_border}>
                    <div className={styles.card_border_body + " px-3 pt-3 pb-1"}>

                        <div className="m-3">

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">

                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6">

                                            {/* Country */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Country</label>
                                                <Input type="select" name="country" id="country" 
                                                value={profileDetails.country} disabled={!isAllowEdit} className={`form-control ${!profileDetails.country? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                                  onInput={(e) => onSelectCountry(e.target.value)}  onChange={(e) => setProfileDetails({ ...profileDetails, country: e.target.value })} >
                                                    
                                                    <option value="">Select</option>
                                                    {
                                                        options.countries && options.countries.map((country, index) => {
                                                            return <option key={index} value={country.name}>{country.name}</option>
                                                        })
                                                    }
                                                </Input>
                                                <small className="text-danger">
                                                    {err.country}
                                                </small>
                                            </div>
                                           

                                        </div>
                                        {/* city */}
                                        <div className="col-sm-12 col-lg-6">
                                        <div className="form-group">
                                                <label style={labelStyle}>City</label>
                                              

                                                <Input type="select" name="city" id="city" value={profileDetails.city} className={`form-control ${!profileDetails.city? `${styles.custom_thick_border}` : 'form-control valid'}`} disabled={!isAllowEdit}  
                                                    onChange={(e) => setProfileDetails({ ...profileDetails, city: e.target.value })} >
                                                    <option value="">{profileDetails.city ? profileDetails.city :'Select'}</option>
                                                    {/* {
                                                        options.cities && options.cities.map((city, index) => {
                                                            return <option key={index} value={city.name}>{city.name}</option>
                                                        })
                                                    } */}
                                                       
                                                    {cities && cities.map(city => {
                                                        return <option value={city.name}>{city.name}</option>
                                                    })}
                                                </Input>
                                                <small className="text-danger">
                                                    {err.city}
                                                </small>

                                            </div>
                                            
                                            

                                        </div>

                                    </div>

                                </div>
                                {/* Area */}
                                <div className="col-sm-12 col-lg-6">

                                    <div className="row py-2">
                                        <div className="col-sm-12 col-lg-6">

                                            {/* Area */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Area</label>
                                                <input type="text" className={`form-control ${!profileDetails.address? `${styles.custom_thick_border}` : 'form-control valid'}`} disabled={!isAllowEdit} value={profileDetails.address}
                                                    onChange={(e) => setProfileDetails({ ...profileDetails, address: e.target.value })} />
                                                <small className="text-danger">
                                                    {err.area}
                                                </small>
                                            </div>


                                        </div>
                                        <div className="col-sm-12 col-lg-6">

                                            {/* Gender */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Gender</label>
                                                {
                                                    !isAllowEdit ?
                                                        <Input type="text" name="gender" id="gender" disabled={true} value={profileDetails.gender} /> :
                                                        <>
                                                            <Input type="select" name="gender" id="gender" value={profileDetails.gender} className={`form-control ${!profileDetails.gender? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                                                onChange={(e) => setProfileDetails({ ...profileDetails, gender: e.target.value })}>
                                                                <option value="">Select</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </Input>
                                                            <small className="text-danger">
                                                                {err.gender}
                                                            </small>
                                                        </>


                                                }
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">

                                    <div className="row">

                                        <div className="col-sm-12 col-lg-6">

                                            {/* Age */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Age</label>
                                                <input type="text" className={`form-control ${!profileDetails.age? `${styles.custom_thick_border}` : 'form-control valid'}`} disabled={true} value={profileDetails.age}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-sm-12 col-lg-6">
                                            {/* Date of Birth */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Date of Birth</label>
                                                {
                                                    !isAllowEdit ?
                                                        <Input type="date" name="dateOfBirth" id="dateOfBirth" readOnly={true} value={birthday}
                                                        /> :
                                                        <>
                                                            <Input type="date" name="dateOfBirth" id="dateOfBirth" value={birthday}
                                                                onChange={(e) => onSelectDOB(e.target.value)}
                                                            />
                                                            <small className="text-danger">
                                                                {err.birthday}
                                                            </small>
                                                        </>


                                                }
                                                {
                                                    <small className="text-danger">{ageErr}</small>
                                                }

                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6">

                                            {/* Martial Status */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Marital Status</label>
                                                {
                                                    !isAllowEdit ?
                                                        <Input type="text" name="maritalStatus" id="maritalStatus" disabled={!isAllowEdit} value={profileDetails.maritalStatus} className={`form-control ${!profileDetails.employment? `${styles.custom_thick_border}` : 'form-control valid'}`} /> :
                                                        <>
                                                            <Input type="select" name="maritalStatus" id="maritalStatus" value={profileDetails.maritalStatus} className={`form-control ${!profileDetails.maritalStatus? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                                                onChange={(e) => setProfileDetails({ ...profileDetails, maritalStatus: e.target.value })} >
                                                                <option value="">{profileDetails.maritalStatus ? profileDetails.maritalStatus :'Select'}</option>
                                                                {
                                                                    options.maritalStatus && options.maritalStatus.map((maritalStatus, index) => {
                                                                        return <option key={index} value={maritalStatus.name}>{maritalStatus.name}</option>
                                                                    })
                                                                }
                                                            </Input>
                                                            <small className="text-danger">
                                                                {err.maritalStatus}
                                                            </small>
                                                        </>
                                                }
                                            </div>

                                        </div>
                                      
                                        {/* Education */}
                                        <div className="col-sm-12 col-lg-6">
                                        <div className="form-group">
                                            <label style={labelStyle}>Education</label>
                                            {
                                                !isAllowEdit ?
                                                    <Input type="text" name="education" id="education"  disabled={!isAllowEdit} value={profileDetails.education} /> :
                                                    <><Input type="select" name="education" id="education"  className={`form-control ${!profileDetails.education? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                                        onChange={(e) => setProfileDetails({ ...profileDetails, education: e.target.value })}>
                                                        <option value="">{profileDetails.education ? profileDetails.education :'Select'}</option>
                                                        {
                                                                               
                                                                                educations && educations.map(city => {
                                                                                    return <option value={city.name}>{city.name}</option>
                                                                                })
                                                                            }
                                                    </Input>
                                                        <small className="text-danger">
                                                            {err.education}
                                                        </small>
                                                    </>
                                            }
                                        </div>
                                        </div>
                                    </div>



                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">

                                    {/* Selection the option */}
                                    <div className="form-group">
                                        <label style={labelStyle}>Selection the option that best describes your status</label>
                                        {
                                            !isAllowEdit ?
                                                <Input type="text" name="employment" id="employment" disabled={!isAllowEdit} value={profileDetails.employment} className={`form-control ${!profileDetails.employment? `${styles.custom_thick_border}` : 'form-control valid'}`} /> :
                                                <>
                                                <Input type="select" name="employment" id="employment" value={profileDetails.employment} className={`form-control ${!profileDetails.employment? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                                    onChange={(e) => setProfileDetails({ ...profileDetails, employment: e.target.value })}>
                                                    <option value="">Select</option>
                                                    {
                                                        options.employment && options.employment.map((employment, index) => {
                                                            return <option key={index} value={employment.name}>{employment.name}</option>
                                                        })
                                                    }
                                                </Input>
                                                    <small className="text-danger">
                                                        {err.employment}
                                                    </small>
                                                </>
                                        }
                                    </div>


                                </div>
                                <div className="col-sm-12 col-lg-6">

                                    {/* Seniority */}
                                    <div className="form-group">
                                        <label style={labelStyle}>Seniority</label>
                                       { !isAllowEdit ? <Input type="text" name="employment" id="employment" disabled={!isAllowEdit} value={profileDetails.employment} className={`form-control ${!profileDetails.employment? `${styles.custom_thick_border}` : 'form-control valid'}`} /> :<>
                                        <Input type="select" name="seniority" id="seniority" value={profileDetails.seniority} disabled={!isAllowEdit} className={`form-control ${!profileDetails.seniority? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                            onChange={(e) => setProfileDetails({ ...profileDetails, seniority: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                options.seniority && options.seniority.map((seniority, index) => {
                                                    return <option key={index} value={seniority.name}>{seniority.name}</option>
                                                })
                                            }
                                        </Input>
                                        <small className="text-danger">
                                            {err.seniority}
                                        </small>
                                        </>
                                        }
                                    </div>

                                </div>
                                <div className="col-12">

                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6">

                                            {/* Occupation */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Occupation</label>
                                                <Input type="text" name="occupation" id="occupation" disabled={!isAllowEdit} value={profileDetails.occupation} className={`form-control ${!profileDetails.occupation? `${styles.custom_thick_border}` : 'form-control valid'}`}
                                                    onChange={(e) => setProfileDetails({ ...profileDetails, occupation: e.target.value })} />
                                                <small className="text-danger">
                                                    {err.occupation}
                                                </small>
                                            </div>

                                        </div>
                                        <div className="col-sm-12 col-lg-6">

                                            {/* Monthly Income */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Monthly Income</label>
                                                <Input type="select" className={`form-control ${!profileDetails.income? `${styles.custom_thick_border}` : 'form-control valid'}`} disabled={!isAllowEdit} value={profileDetails.income}
                                                    onChange={(e) => setProfileDetails({ ...profileDetails, income: e.target.value })} >
                                                    <option value="">{profileDetails.income ? profileDetails.income :'Select'}</option>
                                                    {/* {
                                                        options.incomeRange && options.incomeRange.map((income, index) => {
                                                            return <option key={index} value={income.name}>{income.name}</option>
                                                        })
                                                    } */}
                                                     {
                                                                               
                                                                                incomes && incomes.map(income => {
                                                                                    return <option value={income.name}>{income.name}</option>
                                                                                })
                                                                            }
                                                </Input>
                                                <small className="text-danger">
                                                    {err.income}
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            {isAllowEdit && (
                                <div className="row mt-2">
                                    <div className="col-12 text-right">

                                        {/* Save Changes button */} 
                                         <button className="btn btn-hover-shine text-light py-2" style={{ backgroundColor: "#0071a2", borderRadius: "8px" }} onClick={() => onSaveDemographics()}>Save Changes</button>

                                    </div>
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </TransitionGroup>
    );

}

export default Demographics